import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import DetailsAreaContainer from "./DetailsAreaContainer";
import moment from "moment";
import { getSubscriptionData, getSubscriptionPlan } from "redux/subscription/selectors";
import Switch from "components/Switch";
import { toggleRenewable } from "redux/subscription/actions";
import { getLoggedInUserUID } from "redux/auth/selectors";

export default () => {
	const uid = useSelector(getLoggedInUserUID);
	const currentSubscription = useSelector(getSubscriptionData);
	const currentPlan = useSelector(getSubscriptionPlan);
	const daysLeft = moment().diff(currentSubscription?.finish_date, "days");
	const [renewable, setRenewable] = useState(currentSubscription.renewable);
	const dispatch = useDispatch();

	const onToggleRenewable = (state) => {
		setRenewable(state);
		dispatch(toggleRenewable(uid, state));
	};

	return (
		<DetailsAreaContainer
			title={`${i18next.t("Pages.System Settings")} - ${i18next.t("Pages.Account Subscription")}`}>
			<h6>
				{i18next.t("Subscription.Status Page.Days left start")} {daysLeft * -1 + 1}
				{i18next.t("Subscription.Status Page.Days left end")}
			</h6>
			{!currentPlan.google_only && currentPlan.renewable && currentSubscription.renewable && (
				<h6>
					{i18next.t("Subscription.Status Page.Details renew subscription", {
						price: currentSubscription.renewal_price || currentPlan.renewal_price,
					})}
				</h6>
			)}
			{!currentPlan.google_only && !currentPlan.renewable && !currentPlan?.renewable && (
				<h6>
					{i18next.t("Subscription.Status Page.Details not renew subscription", {
						sum: currentPlan.sub_long,
					})}
				</h6>
			)}
			{!currentPlan.google_only && currentPlan.renewable && !currentSubscription.createdByAdmin && (
				<Switch
					title={i18next.t("Subscription.Status Page.isRenewable")}
					value={renewable}
					onChange={(e) => onToggleRenewable(e.target.checked)}
				/>
			)}
		</DetailsAreaContainer>
	);
};
