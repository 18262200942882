import { LinearProgress, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";

export default function GradientPercentageBar({ targetValue = 0 }) {
	const [value, setValue] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setValue((prev) => {
				if (prev >= targetValue) {
					clearInterval(interval);
					return targetValue;
				}
				return Math.min(prev + 1, targetValue);
			});
		}, 50);
		return () => clearInterval(interval);
	}, [targetValue]);

	return (
		<Box className="gradientBox">
			<LinearProgress className="linearProgress" variant="determinate" value={value} />
			{!!value && (
				<Typography
					className="percentVal"
					style={{
						right: `calc(${value}% - 30px)`,
						marginRight: `${Math.max(0, Math.min(26, 26 - value * 1.6))}px`,
					}}
					variant="body2">{`${value}%`}</Typography>
			)}
		</Box>
	);
}
