import React, { useState, useEffect } from "react";
import { SwipeableDrawer, Box, Button, TextField, Typography } from "@mui/material";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import "assets/scss/drawer/emailDrawer.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { updateProfile } from "redux/profile/actions";

export default function EmailDrawer() {
	const dispatch = useDispatch();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [email, setEmail] = useState("");
	const profile = useSelector(getUserProfile);

	const toggleDrawer = (open) => {
		setDrawerOpen(open);
	};

	const handleOnSubmit = () => {
		toggleDrawer(false);
		dispatch(
			updateProfile("email", typeof email === "string" ? email.trim() : email, profile.email)
		);
	};

	useEffect(() => {
		if (!drawerOpen) setDrawerOpen(true);
	}, []);

	return (
		<SwipeableDrawer
			anchor="bottom"
			className="emailDrawer"
			open={drawerOpen}
			onClose={() => toggleDrawer(false)}
			onOpen={() => toggleDrawer(true)}>
			<SimpleBar style={{ maxHeight: "90vh", minHeight: "80vh" }}>
				<Box className="emailContent">
					<Box className="imgBox"></Box>
					<Typography variant="h6" className="emailTitle">
						אנחנו רוצים לשלוח לך התראות על פניות והתאמות עבורך <br />
						(ולפעמים גם הטבות ומבצעים)
					</Typography>

					<Typography variant="h5" className="emailPrompt">
						מה כתובת המייל שלך?
					</Typography>

					<TextField
						variant="outlined"
						placeholder="להקליד כאן..."
						className="emailInput"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Button disabled={!email} className="confirmButton" onClick={handleOnSubmit}>
						אישור
					</Button>
				</Box>
			</SimpleBar>
		</SwipeableDrawer>
	);
}
