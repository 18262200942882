import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import SaveDataButton from "components/account/SaveDataButton";
import useNavigator from "hooks/useNavigator";
import usePopups from "hooks/usePopups";

export default ({
	children,
	className = "",
	title,
	subTitle,
	data,
	addSaveDataButton,
	errors,
	clearDataAfterSave,
	onSaveMore,
	isVideoDating = false,
}) => {
	const [showClass, setShowClass] = useState("");
	const navigator = useNavigator();
	const { state, pathname } = useLocation();
	const { showSubscriptionPopup } = usePopups();

	useEffect(() => {
		setShowClass("showDetails");
	}, []);

	const closePage = () => {
		if (pathname) navigator.navigateToAccountPage();
		else navigator.navigateToPreviousPage();
		setTimeout(() => {
			state?.isFromSubscriptionModal && showSubscriptionPopup();
		}, 100);
	};

	return (
		<div className={`detailsArea ${className} ${showClass}`}>
			<div className="paperDetails">
				<div
					className="wrapMainTitle"
					style={{
						justifyContent: isVideoDating && "start",
						alignItems: isVideoDating && "start",
						width: isVideoDating && "100%",
					}}>
					<Link
						to="/account"
						onClick={closePage}
						className="user-chat-remove text-muted font-size-16 p-2">
						<i className="ri-arrow-left-s-line"></i>
					</Link>
					{title && (
						<h5 className="mainTitle" onClick={closePage}>
							{title}
						</h5>
					)}
					{subTitle}
				</div>
				<div
					className={`wrapAllForms ${isVideoDating ? "isVideoDating" : ""}`}
					style={{ overflow: isVideoDating && "visible" }}>
					{children}
				</div>
				{addSaveDataButton && (
					<SaveDataButton
						data={data}
						clearDataAfterSave={clearDataAfterSave}
						errors={errors}
						onSaveMore={onSaveMore}
					/>
				)}
			</div>
		</div>
	);
};
