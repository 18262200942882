import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import CommonRoute from "./CommonRoute";
import MainLayout from "layouts/mainLayout";
import LoginLayout from "layouts/loginLayout";
import EmptyLayout from "layouts/emptyLayout";
import CleanLayout from "layouts/cleanLayout";
import MessagesReceivedPage from "pages/MessagesReceivedPage";
import MessagesSentPage from "pages/MessagesSentPage";
import MessagesFilterPage from "pages/MessagesFilterPage";
import LikedMePage from "pages/LikedMePage";
import LikesPage from "pages/LikesPage";
import ViewedMePage from "pages/ViewedMePage";
import ViewsPage from "pages/ViewsPage";
import SearchPage from "pages/SearchPage";
import MatchesPage from "pages/MatchesPage";
import TermsPage from "pages/TermsPage";
import ContactPage from "pages/ContactPage";
import LoginEnterPhonePage from "pages/LoginEnterPhonePage";
import LoginPhoneVerificationPage from "pages/LoginPhoneVerificationPage";
import LoginQuestionsPage from "pages/LoginQuestionsPage";
import LogoutPage from "pages/LogoutPage";
import ProfileBlockedPage from "pages/ProfileBlockedPage";
import AccountPage from "pages/AccountPage";
import HomePage from "pages/HomePage";
import MaintenancePage from "pages/MaintenancePage";
import ForceAppDownload from "pages/ForceAppDownload";
import Unsubscribe from "components/Unsubscribe";
import ImpersonateUser from "components/tester/ImpersonateUser";
import UserPage from "pages/UserPage";
import ReplyContectUs from "pages/ReplyContectUs";
import BlogCategoryPage from "pages/BlogCategoryPage";
import BlogPage from "pages/BlogPage";
import BlogArticle from "components/blog/BlogArticle";
import SpeedDatingPage from "pages/SpeedDatingPage";
import RegisterSpeedDatingPage from "pages/RegisterSpeedDatingPage";
import SpeedDateRegistrationVerificationPage from "pages/SpeedDateRegistrationVerificationPage";
import PullDataFromD4dPage from "pages/PullDataFromD4dPage";
import SoonPage from "pages/SoonPage";
import NotFound from "pages/NotFound";
import ExplanationSpeedDate from "pages/ExplanationSpeedDate";
import PaySpeedDatingPage from "pages/PaySpeedDatingPage";
import SuccessfullyPaySpeedDating from "pages/SuccessfullyPaySpeedDating";
import MatchMakerPage from "pages/MatchMakerPage";
import AlternativeLoginPage from "pages/AlternativeLoginPage";
import PurchasesPopups from "pages/PurchasesPopups";
import LandingGiftCardPage from "pages/LandingGiftCardPage";
import PurchaseGiftCardPage from "pages/PurchaseGiftCardPage";
import ReceivedGiftCardPage from "pages/ReceivedGiftCardPage";
import ThanksGiftCardPage from "pages/ThanksGiftCardPage";
import OneQuestionPage from "components/questions/OneQuestionPage";
import OpenAppStore from "pages/OpenAppStore";
import SearchFriends from "pages/SearchFriends";

export default ({ children }) => {
	return (
		<Router>
			<Switch>
				<CommonRoute component={SearchFriends} layout={CleanLayout} path="/searchFriends" />
				<CommonRoute component={OpenAppStore} layout={CleanLayout} path="/openAppStore" />
				<CommonRoute component={PurchasesPopups} layout={CleanLayout} path="/purchases/:type" />
				<Route component={MaintenancePage} path="/maintenance" />
				<Route component={ForceAppDownload} path="/forcedownload" />
				<CommonRoute component={HomePage} exact layout={CleanLayout} path="/" />
				<Redirect exact from="/login" to="/login/phone" />
				<CommonRoute
					component={LoginEnterPhonePage}
					exact
					layout={LoginLayout}
					path="/login/phone"
				/>
				<CommonRoute component={ReplyContectUs} layout={CleanLayout} path="/replyContectUs/:key" />
				<CommonRoute component={ImpersonateUser} layout={CleanLayout} path="/token/:id" />
				<CommonRoute
					component={LandingGiftCardPage}
					layout={CleanLayout}
					path="/landingGiftCard/"
				/>
				<CommonRoute
					component={PurchaseGiftCardPage}
					layout={CleanLayout}
					exact
					path="/purchaseGiftCard/:keyGiftCard"
				/>
				<CommonRoute
					component={PurchaseGiftCardPage}
					layout={CleanLayout}
					path="/purchaseGiftCard"
				/>
				<CommonRoute
					component={ReceivedGiftCardPage}
					layout={CleanLayout}
					path="/received_giftcard/:keyGiftCard"
				/>
				<CommonRoute
					component={ThanksGiftCardPage}
					layout={CleanLayout}
					path="/thanksGiftCard/:keyGiftCard"
				/>
				<CommonRoute
					component={SpeedDatingPage}
					layout={CleanLayout}
					path="/speedDating/:urlName"
				/>
				<CommonRoute component={SpeedDatingPage} layout={CleanLayout} path="/speedDating" />
				<CommonRoute
					component={ExplanationSpeedDate}
					layout={CleanLayout}
					path="/explanationSpeedDate/:urlName"
				/>
				<CommonRoute
					component={RegisterSpeedDatingPage}
					layout={CleanLayout}
					path="/registerSpeedDating/:urlName"
				/>
				<CommonRoute
					component={RegisterSpeedDatingPage}
					layout={CleanLayout}
					path="/registerSpeedDating/"
				/>
				<CommonRoute
					component={SuccessfullyPaySpeedDating}
					layout={CleanLayout}
					path="/successfullyPaySpeedDating/"
				/>
				<CommonRoute component={PaySpeedDatingPage} layout={CleanLayout} path="/paySpeedDating" />
				<CommonRoute
					component={SpeedDateRegistrationVerificationPage}
					layout={CleanLayout}
					path="/verificationSpeedDating/:id"
				/>
				<CommonRoute
					component={AlternativeLoginPage}
					layout={CleanLayout}
					path="/alternativeLogin"
				/>

				<CommonRoute component={UserPage} exact layout={EmptyLayout} path="/user/:id" />

				<CommonRoute
					component={LoginPhoneVerificationPage}
					exact
					layout={LoginLayout}
					path="/login/phoneverify"
				/>
				<CommonRoute
					component={LoginQuestionsPage}
					exact
					layout={LoginLayout}
					path="/login/questions"
				/>

				<CommonRoute
					component={PullDataFromD4dPage}
					exact
					layout={LoginLayout}
					path="/pullDataFromD4d"
				/>
				<CommonRoute component={LogoutPage} exact layout={EmptyLayout} path="/logout" />
				<CommonRoute component={TermsPage} exact layout={EmptyLayout} path="/terms" />
				<CommonRoute component={SoonPage} exact layout={EmptyLayout} path="/soon" />
				<CommonRoute component={ContactPage} exact layout={EmptyLayout} path="/contact" />
				<CommonRoute auth component={SearchPage} exact layout={MainLayout} path="/search" />
				<CommonRoute auth component={MatchesPage} exact layout={MainLayout} path="/matches" />
				<CommonRoute auth component={LikedMePage} exact layout={MainLayout} path="/likedme" />
				<CommonRoute auth component={LikesPage} exact layout={MainLayout} path="/likes" />
				<CommonRoute auth component={ViewedMePage} exact layout={MainLayout} path="/viewedme" />
				<CommonRoute auth component={ViewsPage} exact layout={MainLayout} path="/views" />
				<CommonRoute
					auth
					component={MessagesReceivedPage}
					exact
					layout={MainLayout}
					path="/messages"
				/>
				<CommonRoute
					auth
					component={MessagesReceivedPage}
					exact
					layout={MainLayout}
					path="/messages/received"
				/>
				<CommonRoute
					auth
					component={MessagesSentPage}
					exact
					layout={MainLayout}
					path="/messages/sent"
				/>
				<CommonRoute
					auth
					component={MessagesFilterPage}
					exact
					layout={MainLayout}
					path="/messages/filtered"
				/>
				<CommonRoute auth component={AccountPage} layout={MainLayout} path="/account" />
				<CommonRoute
					auth
					component={ProfileBlockedPage}
					exact
					layout={EmptyLayout}
					path="/ProfileBlocked"
				/>
				<CommonRoute component={Unsubscribe} exact layout={EmptyLayout} path="/unsubscribe" />
				<CommonRoute component={OneQuestionPage} exact layout={EmptyLayout} path="/matchQuestion" />
				<CommonRoute
					component={BlogCategoryPage}
					exact
					layout={MainLayout}
					path="/blog/category/:urlName"
				/>

				<CommonRoute component={BlogArticle} layout={MainLayout} path="/blog/:urlName" />
				<CommonRoute component={BlogPage} exact layout={MainLayout} path="/blog" />
				<CommonRoute component={MatchMakerPage} exact layout={MainLayout} path="/matchMaker" />

				<CommonRoute component={NotFound} exact layout={MainLayout} path="/*" />
			</Switch>
			{children}
		</Router>
	);
};
