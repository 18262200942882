import "assets/scss/custom/login.scss";
import newLogo from "assets/images/newLogin/newLogo.png";
import logoDesktop from "assets/images/newLogin/newLogoDesktop.png";
import mobileImage from "assets/images/newLogin/mobileImage.png";
import vector17 from "assets/images/newLogin/Vector17.svg";
import vector18 from "assets/images/newLogin/Vector18.svg";
import returnIcon from "assets/images/newLogin/return.svg";
import heart from "assets/images/newLogin/heart.svg";
import appleStore from "assets/images/newLogin/appleStore.svg";
import googlePlay from "assets/images/newLogin/googlePlay.svg";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import { getIsDesktopWeb, getIsMobileWeb } from "redux/init/selectors";
import { useSelector } from "react-redux";
import anonymousCall from "services/anonymousCall";
import isMobile from "helpers/isMobile";
import { getPlatform } from "redux/init/selectors";
import LocalStorageService from "services/localStorage";
import { isIOS, isAndroid } from "react-device-detect";

export default ({ onSubmit, onMobileSubmit, hasError, errMsg, showLoader, className = "" }) => {
	const blogWebsiteUrl = process.env.REACT_APP_BLOG_WEBSITE_URL;
	const navigator = useNavigator();
	const [isMobileDevice] = useState(isMobile());
	const androidUrl = "https://play.google.com/store/apps/details?id=com.shlish.friends";
	const iosUrl =
		"https://apps.apple.com/us/app/%D7%A9%D7%9C%D7%99%D7%A9-%D7%92%D7%9F-%D7%A2%D7%93%D7%9F-%D7%97%D7%91%D7%A8%D7%99%D7%9D/id6470799347";

	const isMobileWeb = useSelector(getIsMobileWeb);
	const isWebPlatform = useSelector(getIsDesktopWeb);

	const platform = useSelector(getPlatform);

	const urlParams = new URLSearchParams(window.location.search);
	const affId = urlParams.get("f");
	const d4d = urlParams.get("sourceD4d");
	const [collaboration, setCollaboration] = useState(null);
	const [showOldSite, setShowOldSite] = useState(false);
	const [store, setStore] = useState(null);

	useEffect(() => {
		if (!affId) return;
		anonymousCall.getAffName(affId).then((res) => {
			if (res?.success) {
				if (res?.affiliate) setCollaboration(res.affiliate);
			}
		});
	}, [affId]);

	useEffect(() => {
		if (!d4d) return;

		if (platform === "web" || platform === "mobileWeb") {
			setShowOldSite(true);
			LocalStorageService.set("comeFromOldSite", Date.now(), 10);
		}
	}, [d4d, platform]);

	useEffect(() => {
		if (platform) {
			if (platform === "web" || platform === "mobileWeb") {
				if (isIOS) {
					setStore({ url: iosUrl, type: "ios" });
				} else if (isAndroid) {
					setStore({ url: androidUrl, type: "android" });
				}
			}
		}
	}, [platform]);

	useEffect(() => {
		console.log("isMobileDevice", isMobileDevice);
		const html = document.querySelector("html");
		html.style.fontSize = "62.5%";
		return () => {
			html.style.fontSize = "";
		};
	}, [isMobileDevice]);

	const onLoginClick = (e) => {
		e.preventDefault();
		navigator.navigateToLoginEnterPhonePage();
	};
	const onNavClick = (e, path = "/") => {
		e.preventDefault();
		navigator.navigateToPath(path);
	};

	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};
	return (
		<>
			<div className={`mobile-container mobile-tablet-only ${className}`}>
				<img className="mobile-image" src={mobileImage} />
				<div className="gradient-overlay">
					{/* <div className="new-app">
						השתדרגנו עם <b> אפליקציה חדשה!</b>
					</div> */}
					<img className="logo" style={{ marginTop: !!store && "160px" }} src={newLogo} />
					{!!collaboration?.name && (
						<div className="collaborationWrapper" style={{ marginTop: "30px" }}>
							<div className="newRegister">{`*הטבה מיוחדת לנרשמים חדשים`}</div>
							<div className="shareWith">{`בשיתוף "${collaboration?.name}"`}</div>
						</div>
					)}
					<button
						onClick={onMobileSubmit}
						className="button-register"
						style={{ marginTop: !store && "8vh" }}>
						התחברות
					</button>
					{!!store && (
						<a href={store.url} target="_blank" className="download-app">
							<div className="line1">להורדת האפליקצייה</div>
							{store.type === "android" && (
								<div className="line2">
									<span>Google Play</span>
									<img src={googlePlay} />
								</div>
							)}

							{store.type === "ios" && (
								<div className="line2">
									<span>App Store</span>
									<img src={appleStore} />
								</div>
							)}
						</a>
					)}
					<div onClick={openInNewTab} className="terms">
						בהתחברותך לאפליקציה הנך מאשר/ת
						<b> שקראת </b>
						והנך מסכימ/ה <br />
						למדיניות האפליקציה, למדיניות הפרטיות שלה, ולקבלת חומר פרסומי{" "}
					</div>
					{!!showOldSite && (
						<div className="sticky-bottom">
							<img src={returnIcon} />
							<a href="https://www.date4dos.co.il/?login_act=phone" target="_blank">
								להתחברות לפלטפורמה הישנה
							</a>
						</div>
					)}
				</div>
			</div>

			<div className={`image-container-desktop desktop-only ${className}`}>
				<img className="logo" src={logoDesktop} />
				{!!collaboration?.name && (
					<div className="collaborationWrapper" style={{ marginTop: "10px" }}>
						<div className="newRegister">{`*הטבה מיוחדת לנרשמים חדשים`}</div>
						<div className="shareWith">{`בשיתוף "${collaboration?.name}"`}</div>
					</div>
				)}
				<div className="boxes">
					<div className="new-site-message">
						<div className="top">התחדשנו</div>
						<div className="bottom">בענק!</div>
						<div className=""></div>
					</div>
					<div className="blue-box">
						<ul class="custom-list">
							<li className="slide-in-left" style={{ "--delay": "0.6s" }}>
								ממשק חדש
							</li>
							<li className="slide-in-left" style={{ "--delay": "0.8s" }}>
								חיפוש לפי תגיות וטקסט חופשי
							</li>
							<li className="slide-in-left" style={{ "--delay": "1.2s" }}>
								צ׳אט מהיר עם הודעות קוליות ווי כחול
							</li>
							<li className="slide-in-left" style={{ "--delay": "1.4s" }}>
								חברים משותפים ביניכם, כדי שתרגישו בטוחים יותר
							</li>
							<li className="slide-in-left" style={{ "--delay": "1.6s" }}>
								להיפתח ולהכיר וידאו דייט בהתאמה אישית!
							</li>
						</ul>
					</div>
					<div className="white-box">
						<span>
							<b className="flash">20,000+</b>
						</span>
						<span>חתונות</span>
					</div>
				</div>
				<div className="login">
					<div className="title">
						<b>שנתחיל?</b> <br />
						הכנס/י את מספר הטלפון שלך
					</div>
					<PhoneQuestion
						className="phoneLP"
						onChange={() => {}}
						onSubmit={onSubmit}
						hasError={hasError}
						errMsg={errMsg}
						showLoader={showLoader}
					/>
					<div className="terms">
						<span onClick={openInNewTab}>
							בהתחברותך לאפליקציה הנך מאשר/ת שקראת והנך מסכימ/ה למדיניות הפרטיות, למדיניות האפליקציה
							ולקבלת חומר פרסומי.
						</span>
						<br></br>
						<a href="/contact">
							<b>צריכ/ה עזרה? לחצ/י כאן או חייגי/י 08-6519660</b>
						</a>
					</div>
				</div>
				<div className="top-menu">
					<a href="#" onClick={(e) => onNavClick(e, "/blog")} className="top-menu-item">
						מגזין
					</a>
					<a
						href="#"
						className="top-menu-item"
						onClick={(e) => onNavClick(e, "/blog/category/הכירו-באתר")}>
						הכירו בשליש
					</a>
					<a href="#" onClick={onLoginClick} className="top-menu-item">
						<b>הרשמה </b>
					</a>
					<a href="#" onClick={onLoginClick} className="top-menu-item">
						<b>כניסה לרשומים</b>
					</a>
				</div>

				{showOldSite && (
					<div className="old-site">
						<img className="bg-old-site" src={vector17} />
						<img className="bg-old-site" src={vector18} />

						<div className="content">
							<div className="line1">
								<div>
									<span>
										שים <img src={heart} className="flash2" /> !
									</span>
								</div>
							</div>
							<div className="line2">מכאן ניתן להתחבר לפלטפורמה הישנה.</div>
							<div className="line3">
								<a href="https://www.date4dos.co.il/?login_act=phone" target="_blank">
									<img src={returnIcon} />
									להתחברות לפלטפורמה הישנה
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
