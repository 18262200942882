import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import moment from "moment";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";

export default function DropDownVideoList({ speedData, allUserEvents, setSpeedData }) {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	return (
		<Box sx={{ position: "relative", width: "100%", minHeight: "0px", top: "-57px" }}>
			<Dropdown isOpen={isOpen} toggle={toggle} style={{ width: "100%" }}>
				<DropdownToggle
					className="btn nav-btn"
					color="none"
					type="button"
					style={{ position: "absolute", left: "0" }}>
					<MenuIcon style={{ color: "#0bc1c7" }} />
				</DropdownToggle>
				<DropdownMenu>
					{allUserEvents?.length ? (
						allUserEvents.map((speedy) => (
							<DropdownItem
								key={speedy.key}
								onClick={() => setSpeedData(speedy)}
								active={speedData?.key === speedy.key}>
								{moment(speedy?.eventDate).format("האירוע שהיה ב D/M/YY")}
							</DropdownItem>
						))
					) : (
						<DropdownItem disabled>אין אירועים זמינים</DropdownItem>
					)}
				</DropdownMenu>
			</Dropdown>
		</Box>
	);
}
