import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import Question from "./Question";
import DatePicker from "../DatePicker";

export default ({ title, subTitle, onSubmit, onBackButtonClick, showBackButton, initValue }) => {
	const minDate = moment().subtract(18, "year").format("YYYY-MM-DD");
	const maxDate = moment().subtract(99, "year").format("YYYY-MM-DD");
	const currentDateWithAnotherYear = "1990" + moment().format("YYYY-MM-DD").slice(4);
	const [initDate] = useState(initValue || currentDateWithAnotherYear);
	const [value, setValue] = useState(initValue || minDate);
	const [isValid, setIsValid] = useState();
	const [showError, setShowError] = useState(false);

	const handleOnChange = (val) => {
		setValue(val);
		setShowError(false);
	};

	const handleOnSubmit = () => {
		if (value === currentDateWithAnotherYear) return setShowError(true);
		onSubmit(value);
	};

	const handleOnValidityChange = (_isValid) => {
		setIsValid(_isValid);
	};

	return (
		<Question
			isValid={isValid}
			title={title}
			subTitle={subTitle}
			onSubmit={handleOnSubmit}
			showBackButton={showBackButton}
			onBackButtonClick={onBackButtonClick}>
			<DatePicker
				onChange={handleOnChange}
				initDate={initDate}
				minDate={minDate}
				maxDate={maxDate}
				onValidityChange={handleOnValidityChange}
			/>
			{showError && <h3 style={{ color: "red" }}>{`יש להזין תאריך לידה`}</h3>}
		</Question>
	);
};
