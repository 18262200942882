import i18next from "i18next";
import { Button } from "reactstrap";
import logoB from "assets/images/icons/logoGrad.svg";
import mobileImage from "assets/images/icons/logoGrad.svg";
import logoD4d from "assets/images/icons/logoD4d22.png";
import hands from "assets/images/bgHome.webp";
import imageTwo from "assets/images/homePage.png";
import vHomePage from "assets/images/vHomePage.png";
import theMost from "assets/images/theMost.svg";
import TermsLink from "components/login/TermsLink";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import { getIsDesktopWeb, getIsMobileWeb } from "redux/init/selectors";
import { useSelector } from "react-redux";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import anonymousCall from "services/anonymousCall";
const blogWebsiteUrl = process.env.REACT_APP_BLOG_WEBSITE_URL;

export default ({
	onSubmit,
	onMobileSubmit,
	hasError,
	errMsg,
	showLoader,
	className = "",
	scrollToTarget = () => {},
}) => {
	const navigator = useNavigator();
	const isMobileWeb = useSelector(getIsMobileWeb);
	const isWebPlatform = useSelector(getIsDesktopWeb);

	const urlParams = new URLSearchParams(window.location.search);
	const affId = urlParams.get("f");
	const [collaboration, setCollaboration] = useState({ name: "דנה שידוכים" });

	useEffect(() => {
		if (!affId) return;
		anonymousCall.getAffName(affId).then((res) => {
			if (res?.success) {
				if (res?.affiliate) setCollaboration(res.affiliate);
			}
		});
	}, [affId]);

	useEffect(() => {
		const html = document.querySelector("html");
		html.style.fontSize = "62.5%";
		return () => {
			html.style.fontSize = "";
		};
	}, []);

	const onLoginClick = (e) => {
		e.preventDefault();
		navigator.navigateToLoginEnterPhonePage();
	};
	const onNavClick = (e, path = "/") => {
		e.preventDefault();
		navigator.navigateToPath(path);
	};

	const logo = process.env.REACT_APP_ENV === "shlish" ? logoD4d : logoB;

	return (
		<div className={`loginPagesWrapper homeConnect ${className}`}>
			<div className="headerHome">
				{/* <a href="#" onClick={(e) => onNavClick(e, "/blog")}>
					{i18next.t("HP.Tabs.About")}
				</a> */}
				<a href="#" onClick={(e) => onNavClick(e, "/blog")}>
					{i18next.t("HP.Tabs.Newsletter")}
				</a>
				<a href="#" onClick={(e) => onNavClick(e, "/blog/category/הכירו-באתר")}>
					{i18next.t("HP.Tabs.Stories")}
				</a>
				<a href="#" onClick={onLoginClick}>
					{i18next.t("HP.Tabs.Login")}
				</a>
			</div>
			<div
				className="siteLogo"
				style={{ minWidth: !!isWebPlatform && !!collaboration?.name && "360px" }}>
				<img src={logo} />
				{!!isWebPlatform && !!collaboration?.name && (
					<div className="collaborationWrapper" style={{ marginTop: "10px" }}>
						<div className="newRegister">{`*הטבה מיוחדת לנרשמים חדשים`}</div>
						<div className="shareWith">{`בשיתוף "${collaboration?.name}"`}</div>
					</div>
				)}
			</div>
			<div className="homeContent">
				<div className="wrapContentLP">
					<div className="textForDesktop" style={{ position: "relative" }}>
						{/* <h2>{i18next.t("HP.Dating Site")}</h2> */}
						<div className="newTextDeskShlishFriends">
							<p>{`התחדשנו! `}</p>
							<h1 style={{ marginRight: "2rem" }}>{`שליש גן עדן Smart`}</h1>
						</div>
						<p className="marriedMost">
							{i18next.t("HP.marriedMost")}
							<span className="specialColor">{` ${i18next.t("HP.obligation")}`}</span>
						</p>
						<h3>{i18next.t("HP.Enter your phone here")} </h3>
						<h6>{i18next.t("HP.Old or new member")}</h6>
						<PhoneQuestion
							className="phoneLP"
							onChange={() => {}}
							onSubmit={onSubmit}
							hasError={hasError}
							errMsg={errMsg}
							showLoader={showLoader}
						/>
						{/* {isWebPlatform && (
							<div
								onClick={scrollToTarget}
								style={{
									position: "absolute",
									left: "0",
									bottom: "50px",
									display: "flex",
									gap: "5px",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
								}}>
								<ArrowCircleDownIcon style={{ color: "#fff", fontSize: "30px" }} />
								<span
									style={{
										color: "#fff",
										textAlign: "center",
										fontSize: "2rem",
									}}>{`לחיפוש משתמשים`}</span>
							</div>
						)} */}
					</div>
				</div>
				<div className="wrapstats">
					<div className="imageTwo">
						<img src={imageTwo} />
					</div>
					<div className="pinkyWhite">
						<div className="pinky stats" style={{ minWidth: "300px" }}>
							<div style={{ display: "flex", gap: "1rem" }}>
								<img src={vHomePage} width={"27px"} height={"27px"} />
								<h6 style={{ textAlign: "start" }}>{"ממשק חדש"}</h6>
							</div>
							<div style={{ display: "flex", gap: "1rem" }}>
								<img src={vHomePage} width={"27px"} height={"27px"} />
								<h6 style={{ textAlign: "start" }}>{"חיפוש לפי תגיות וטקסט חופשי"}</h6>
							</div>
							<div style={{ display: "flex", gap: "1rem" }}>
								<img src={vHomePage} width={"27px"} height={"27px"} />
								<h6 style={{ textAlign: "start" }}>{"צ'אט מהיר, עם הודעות קוליות  וV כחול "}</h6>
							</div>
							<div style={{ display: "flex", gap: "1rem" }}>
								<img src={vHomePage} width={"27px"} height={"27px"} />
								<h6 style={{ textAlign: "start" }}>
									{"וכמובן חברים משותפים בניכם, כדי שתרגישו בטוחים יותר להיפתח ולהכיר"}
								</h6>
							</div>
							{/* <h6>{i18next.t("HP.NewDesign")}</h6> */}
						</div>
						<div className="whitey stats">
							<span>15,000+</span>
							<h6>{i18next.t("HP.Weddings")}</h6>
						</div>
					</div>
				</div>
			</div>

			<div className="title">
				{isMobileWeb ? (
					<div>
						{/* <h3 style={{ fontSize: "3rem", fontWeight: "bold" }}>{i18next.t("HP.d4dNew1")}</h3> */}
						<h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}> שליש גן עדן Smart</h1>
						<h3 style={{ fontSize: "2.5rem" }}>{i18next.t("HP.d4dNew2")}</h3>
					</div>
				) : (
					<h3>
						{process.env.REACT_APP_ENV === "shlish"
							? i18next.t("HP.CongratulationsD4d!")
							: i18next.t("HP.Congratulations!")}
					</h3>
				)}
			</div>
			{!isWebPlatform && !!collaboration?.name && (
				<div className="collaborationWrapper">
					<div className="newRegister">{`*הטבה מיוחדת לנרשמים חדשים`}</div>
					<div className="shareWith">{`בשיתוף "${collaboration?.name}"`}</div>
				</div>
			)}
			<div className="wrapImgBtn">
				<div className="wrapGradient">
					<div className="imgBtn">
						<div className="circleImage">
							<img src={hands} />
						</div>
						<Button onClick={onMobileSubmit}>{i18next.t("Login.Connect")}</Button>
					</div>
					<TermsLink />
				</div>
			</div>
		</div>
	);
};
