import i18next from "i18next";
import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import useNavigator from "hooks/useNavigator";
import {
	isUserLoggedIn,
	getIsAuthChecked,
	getLoginWithPhoneStatus,
	getLoginWithPhoneErrorMessage,
} from "redux/auth/selectors";
import HomePage from "components/HomePage";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import { getPhoneWithPrefix } from "helpers/mobileNumber";
import { loginWithPhone } from "redux/auth/actions";
import Loader from "components/Loader";
import { getPlatform } from "redux/init/selectors";
import "assets/scss/searchHomePage/searchHomePage.scss";
import SearchPage from "pages/SearchPage";
import usePopups from "hooks/usePopups";
import searchTypes from "consts/typesSearch";
import BaseHelmet from "components/seo/BaseHelmet";
import NewHomePage from "components/NewHomePage";

export default () => {
	const dispatch = useDispatch();
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const navigator = useNavigator();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const loginWithPhoneErrorMessage = useSelector(getLoginWithPhoneErrorMessage);
	const platform = useSelector(getPlatform);
	const { showLoginPopup } = usePopups();
	const targetRef = useRef(null);

	const scrollToTarget = () => {
		if (!targetRef.current) return;
		targetRef.current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if (isLoggedIn && isAuthChecked) {
			navigator.navigateToLoginQuestionsPage();
		}
	}, [isLoggedIn, isAuthChecked]);

	const handleSubmit = (value) => {
		// const phoneWithPrefix = getPhoneWithPrefix(value);
		dispatch(loginWithPhone(value));
	};

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION:
				navigator.navigateToLoginPhoneVerificationPage();
				break;
			case LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED:
				setHasError(true);
				setErrMsg(
					i18next.t(
						`Phone Error.${
							loginWithPhoneErrorMessage.indexOf("blocked") !== -1 ? "Many Requests" : ""
						}`
					)
				);
				break;
			default:
				break;
		}
	}, [loginStatus]);

	const handleMobileSubmit = () => {
		navigator.navigateToLoginEnterPhonePage();
	};

	return isAuthChecked ? (
		<>
			<BaseHelmet
				description={
					"שליש גן עדן היכרויות לדתיים ושומרי מסורת - מחתנים הכי הרבה זוגות בישראל. היכרות באמצעות חברים משותפים, וידאו דייטינג ועוד. היכנסו והכירו את אהבת חייכם."
				}
			/>
			<NewHomePage
				className="homePageStart"
				onSubmit={handleSubmit}
				onMobileSubmit={handleMobileSubmit}
				hasError={hasError}
				errMsg={errMsg}
				showLoader={loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING}
				scrollToTarget={scrollToTarget}
			/>
			{false && (
				<div className="wrap-search-home-page" ref={targetRef}>
					<SearchPage type={searchTypes.SearchGuest} />
					<div className="wrap-layer-page" onClick={() => showLoginPopup()}></div>
				</div>
			)}
		</>
	) : (
		<Loader />
	);
};
