import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import {
	getSubscriptionData,
	getSubscriptionPlansExists,
	getUserSubscriptionType,
} from "redux/subscription/selectors";
import { getSubscriptionsIsOn } from "redux/system/selectors";
import Subscription from "consts/subscription";
import subscriptionStatus from "consts/subscriptionStatus";

export default () => {
	const [result, setResult] = useState(null);
	const subscriptionIsOn = useSelector(getSubscriptionsIsOn);
	const subscriptionType = useSelector(getUserSubscriptionType);
	const plansExists = useSelector(getSubscriptionPlansExists);
	const subscription = useSelector(getSubscriptionData);

	useEffect(() => {
		if (!_.isNil(subscriptionIsOn) && !_.isNil(subscriptionType) && plansExists) {
			setResult(
				subscriptionIsOn &&
					plansExists &&
					subscriptionType !== Subscription.PAID &&
					subscriptionType !== Subscription.FREE
			);
		}
	}, [subscriptionIsOn, subscriptionType, plansExists, subscription]);

	return result;
};
