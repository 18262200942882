import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Progress } from "reactstrap";
import _ from "lodash";
import { useSelector } from "react-redux";
import Wizard from "components/login/Steps/Wizard";
import { getUserProfile } from "redux/profile/selectors";
import { getLoginSteps, getIsAuthChecked, isUserLoggedIn } from "redux/auth/selectors";
import useNavigator from "hooks/useNavigator";
import Loader from "components/Loader";
import { updateCompleteRegistration } from "redux/profile/actions";
import AppPlatforms from "consts/AppPlatforms";
import AppService from "services/app";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

//import LoginSteps from "consts/loginSteps";

export default () => {
	const dispatch = useDispatch();
	const navigator = useNavigator();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const profile = useSelector(getUserProfile);
	const loginSteps = useSelector(getLoginSteps);
	const location = useLocation();
	//const [loginSteps] = useState(Object.keys(LoginSteps));

	const isAuthChecked = useSelector(getIsAuthChecked);
	const handleOnStepsFinish = () => {
		dispatch(updateCompleteRegistration());
		if (false && process.env.REACT_APP_ENV === "shlish2" && !profile.isTester)
			navigator.navigateToSoon();
		else {
			if (location.state?.url) return navigator.navigateToPath(location.state.url);
			navigator.navigateToMatchesPage();
		}
	};

	useEffect(() => {
		if (loginSteps?.length == 1 && loginSteps[0] === "Email") {
			handleOnStepsFinish();
		}

		if (!_.isEmpty(profile) && loginSteps.length === 0) {
			handleOnStepsFinish();
		}
	}, [profile?.uid, loginSteps]);

	useEffect(() => {
		if (
			process.env.REACT_APP_ENV === "shlish" &&
			!_.isEmpty(profile) &&
			profile.isD4dUser &&
			_.isNil(profile?.allowPullData)
		) {
			navigator.navigateToPullDataFromD4d();
		}
	}, [profile?.uid, profile?.isD4dUser]);

	/* useEffect(() => {
		//if (profile?.uid && !profile?.isTester) navigator.navigateToSoon();

		AppService.getPlatform().then((platform) => {
			if (platform !== AppPlatforms.IOS) {
				if (false && profile?.uid && !profile?.isTester) navigator.navigateToSoon();
			}
		});
	}, [profile?.uid]); */

	return isLoggedIn && loginSteps.length > 0 ? (
		<div className="loginPagesWrapper">
			<Progress value="25" className="loginSteps" style={{ display: "none" }} />
			<Wizard
				profile={profile}
				onStepsFinish={handleOnStepsFinish}
				stepNames={loginSteps}
				className="loginPopup loginStepsClass"
			/>
		</div>
	) : (
		<div className="loginPagesWrapper">
			<Loader />
		</div>
	);
};
