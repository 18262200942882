import { Box, CircularProgress, Button } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPlatform } from "redux/init/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { isIOS, isAndroid } from "react-device-detect";
import AppServices from "services/app";
import useNavigator from "hooks/useNavigator";

export default function OpenAppStore() {
	const platform = useSelector(getPlatform);
	const { navigateToHomePage } = useNavigator();

	const getAppStoreUrl = () => {
		const isShlishEnv = process.env.REACT_APP_ENV === "shlish";

		if (isIOS) {
			return isShlishEnv
				? "https://apps.apple.com/us/app/%D7%A9%D7%9C%D7%99%D7%A9-%D7%92%D7%9F-%D7%A2%D7%93%D7%9F-%D7%97%D7%91%D7%A8%D7%99%D7%9D/id6470799347"
				: "https://apps.apple.com/us/app/jsmart-%D7%94%D7%99%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-%D7%93%D7%99%D7%99%D7%98%D7%99%D7%A0%D7%92-%D7%A6%D7%90%D7%98/id1554731425";
		}
		if (isAndroid) {
			return isShlishEnv
				? "https://play.google.com/store/apps/details?id=com.shlish.friends"
				: "https://play.google.com/store/apps/details?id=com.meetsmart.jsmart&hl=iw&gl=US";
		}
		return null;
	};

	const redirectToHomePageTimeout = () => {
		setTimeout(() => {
			navigateToHomePage();
		}, 2000);
	};

	useEffect(() => {
		if (!platform || platform == "unknown") return;

		const handleRedirect = async () => {
			if (["web", "mobileWeb"].includes(platform)) {
				const url = getAppStoreUrl();
				if (url) {
					await AppServices.openInbrowser(url);
				} else navigateToHomePage();
			}
			redirectToHomePageTimeout();
		};

		handleRedirect();
	}, [platform]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: "30px",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}>
			<CircularProgress sx={{ color: "#2d5086" }} />
		</Box>
	);
}
