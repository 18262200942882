import "assets/scss/questions/questionsBox.scss";
import { Box, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import FilterButtons from "components/FilterButtons";
import { Button, ButtonGroup } from "reactstrap";
import questionsMatchService from "services/questionsMatchService";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import editImg from "assets/images/questions/edit.png";
import { Popover, PopoverBody, Nav, NavLink } from "reactstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";

export default function AccountQuestionsBox() {
	const location = useLocation();
	const { uid } = useSelector(getUserProfile);
	const [page, setPage] = useState("myAnswers");
	const [questions, setQuestions] = useState([]);
	const [displayQuestions, setDisplayQuestions] = useState([]);
	const [skipped, setSkipped] = useState({});
	const [answered, setAnswered] = useState({});
	const [openCheckBox, setOpenCheckBox] = useState(false);
	const [openPopover, setOpenPopover] = useState(false);
	const [removeList, setRemoveList] = useState([]);

	const pages = {
		myAnswers: "myAnswers",
		mySkips: "mySkips",
	};

	const togglePopover = () => {
		setOpenPopover(!openPopover);
	};
	const onDeleteQuestions = async () => {
		setOpenPopover(false);
		setOpenCheckBox(false);

		if (!removeList?.length) return;

		await Promise.all(
			removeList.map(async (questionKey) => {
				const data = {
					questionKey,
					answerText: null,
					answerKey: null,
					isSkip: true,
					isDelete: true,
				};
				return await questionsMatchService.getUserAnswerMatchQuestion(data);
			})
		);
	};

	useEffect(() => {
		if (!uid) return;

		const subscription = questionsMatchService.listenToUserQuestions$(uid).subscribe((res) => {
			if (res) {
				const skippedObj = {};
				const answeredObj = {};
				Object.values(res || {}).map((item) => {
					if (item?.isSkip) skippedObj[item.questionKey] = true;
					else answeredObj[item.questionKey] = item?.answerKey;
				});

				setSkipped(skippedObj);
				setAnswered(answeredObj);
			}
		});

		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	useEffect(async () => {
		if (questions?.length) return;
		await questionsMatchService.getQuestions().then((res) => {
			if (res.data?.success) {
				const resQuestions = (res.data?.questions || []).sort((a, b) => a?.order - b?.order);
				setQuestions(resQuestions);
			}
		});
	}, []);
	useEffect(() => {
		if (location?.state?.toSkip) setPage(pages.mySkips);
	}, []);

	useEffect(() => {
		setOpenCheckBox(false);
		setOpenPopover(false);
		switch (page) {
			case pages.myAnswers:
				return setDisplayQuestions(questions.filter((qu) => !!answered[qu?.key]));
			case pages.mySkips:
				return setDisplayQuestions(questions.filter((qu) => !!skipped[qu?.key]));
		}
	}, [page, questions]);

	return (
		<Box className="profileAccountQuestionWrapper">
			<Box className="wrapHeaderBtns">
				{page === "myAnswers" && (
					<>
						<Box className="moreIcon" id={"edit_key"} onClick={() => togglePopover()}>
							<MoreVertIcon style={{ color: "#0bc1c7" }} />
						</Box>
						<Popover
							isOpen={openPopover}
							placement="bottom"
							target={`edit_key`}
							trigger="legacy"
							toggle={() => togglePopover()}>
							<PopoverBody>
								<div>
									<Nav vertical>
										{!openCheckBox ? (
											<NavLink onClick={() => setOpenCheckBox(true)} href="#" className="edit">
												{"ערוך"}
											</NavLink>
										) : (
											// 	<NavLink onClick={() => setOpenCheckBox(false)} href="#" className="edit">
											// 	{"סגור עריכה"}
											// </NavLink>

											<NavLink onClick={() => onDeleteQuestions()} href="#" className="trash">
												{"מחיקת התשובות המסומנות"}
											</NavLink>
										)}
									</Nav>
								</div>
							</PopoverBody>
						</Popover>
					</>
				)}

				<ButtonGroup className="accountQuestions" dir="rtl">
					<Button
						color="light"
						{...(page === pages.myAnswers
							? {
									active: true,
									color: "primary",
							  }
							: {
									onClick: () => setPage(pages.myAnswers),
							  })}>
						{"שאלות שעניתי עליהן"}
					</Button>

					<Button
						color="light"
						{...(page === pages.mySkips
							? {
									active: true,
									color: "primary",
							  }
							: {
									onClick: () => setPage(pages.mySkips),
							  })}>
						{"שאלות שדילגתי עליהן"}
					</Button>
				</ButtonGroup>
			</Box>

			<Box className="wrapQuestionsAccount">
				<DisplayQuesBtns
					displayQuestions={displayQuestions}
					answered={answered}
					page={page}
					isOpen={openCheckBox}
					setRemoveList={setRemoveList}
				/>
			</Box>
		</Box>
	);
}

function DisplayQuesBtns({
	displayQuestions = [],
	answered = {},
	page = "myAnswers",
	isOpen = false,
	setRemoveList,
}) {
	const [questions, setQuestions] = useState([]);
	const [userAnswer, setUserAnswer] = useState({});
	const [openCheckBox, setOpenCheckBox] = useState(isOpen);
	const [removeKeys, setRemoveKeys] = useState([]);

	const onClick = async (questionKey, answerKey, answerText, isSkip = false) => {
		if (userAnswer[questionKey] === answerKey) return;
		setUserAnswer({ ...userAnswer, [questionKey]: answerKey });
		const data = {
			questionKey,
			answerText,
			answerKey,
			isSkip,
		};
		const res = await questionsMatchService.getUserAnswerMatchQuestion(data);
	};

	useEffect(() => {
		setQuestions(displayQuestions);
	}, [displayQuestions]);

	useEffect(() => {
		setUserAnswer(answered);
	}, [answered]);
	useEffect(() => {
		setOpenCheckBox(isOpen);
		if (!isOpen) setRemoveKeys([]);
	}, [isOpen]);

	useEffect(() => {
		setRemoveList(removeKeys);
	}, [removeKeys]);

	return (
		<>
			{!!questions?.length &&
				questions.map((quest, key) => (
					<Box key={key} className="wrapInnerQuestionsAccount">
						<div className="wrapQuestEdit">
							{openCheckBox && page === "myAnswers" && (
								<Checkbox
									className="checkBoxRemove"
									checked={removeKeys.includes(quest?.key)}
									onChange={(e) => {
										setRemoveKeys((prev) =>
											e.target.checked
												? [...prev, quest?.key]
												: prev.filter((key) => key !== quest?.key)
										);
									}}
								/>
							)}
							<label className="form-label">{quest?.question}</label>
						</div>
						<div className="wrapBtns">
							{!!Object.values(quest?.answers)?.length &&
								Object.values(quest?.answers).map((item) => (
									<button
										size="sm"
										className={`regBtn ${item?.key === userAnswer[quest?.key] ? "selected" : ""}`}
										color="primary"
										key={item?.key}
										onClick={() => onClick(quest?.key, item?.key, item?.text)}
										value={item?.key}>
										{item?.text}
									</button>
								))}
						</div>
					</Box>
				))}
		</>
	);
}
