import "assets/scss/popups/aboutMeAi.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box } from "@mui/material";
import usePopups from "hooks/usePopups";
import { useEffect, useState } from "react";
import aiService from "services/ai.service";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import TypingEffect from "helpers/TypingEffect";
import { getPopups } from "redux/popups/selectors";

export default function MatchBetweenUsersAiModal() {
	const { closePopup } = usePopups();
	const { uidPartner } = useSelector(getPopups);
	const [loading, setLoading] = useState(true);
	const [aiData, setAiData] = useState({});

	useEffect(async () => {
		const res = await aiService.getMatchBetweenUsersAi(uidPartner);
		if (res.data?.success) setAiData(res.data.data);
		setLoading(false);
	}, []);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog aboutMeAiPopup matchAiPopup">
			<div className="closeBtnAi">
				<Button color="none" type="button" onClick={closePopup}>
					<CloseIcon style={{ color: "#2d5086" }} />
				</Button>
			</div>

			<ModalHeader className="headerContent">{"שליש-Smart Ai"}</ModalHeader>
			<ModalBody className="aiContent">
				{loading ? (
					<AutoAwesomeIcon className="starsLoading" />
				) : (
					<Box className="innerAiContent">
						<TypingEffect text={aiData} speed={15} />
					</Box>
				)}
			</ModalBody>
		</Modal>
	);
}
