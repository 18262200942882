import { useSelector } from "react-redux";
import SendTemplateMessageButton from "components/search/card/SendTemplateMessageButton";
import MessageButton from "components/search/card/MessageButton";
import LikeButton from "components/search/card/LikeButton";
import { getChat } from "redux/chatReceived/selectors";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import useNavigator from "hooks/useNavigator";

export default ({ user }) => {
	const chatID = useSelector(getChat(user.uid));
	const { showLoginPopup } = usePopups();
	const { navigateToPath } = useNavigator();
	const currentUser = useSelector(getUserProfile) || {};

	const navigateToUserAfterLogin = () => navigateToPath(`/user/${user?.uid}`);

	const handleClick = (e) => {
		if (!currentUser?.uid || !currentUser?.name) {
			e.preventDefault();
			return showLoginPopup(navigateToUserAfterLogin);
		}
	};

	return (
		<div className="buttonsActions">
			<div role="group" className="btn-group" onClick={handleClick}>
				<SendTemplateMessageButton user={user} isFromCardUserSidebar={true} />
				<MessageButton chatID={chatID} user={user} />
				<LikeButton user={user} />
			</div>
		</div>
	);
};
