import { Box } from "@mui/material";
import newImg from "assets/images/giftCard/new.png";
import heartGiftCard from "assets/images/giftCard/heartGc.svg";
import getGift from "assets/images/giftCard/getGift.svg";
import letter from "assets/images/giftCard/letter.svg";
import phone from "assets/images/giftCard/phone.svg";
import watch from "assets/images/giftCard/watch.svg";

export default function ContentLandingGiftCard() {
	return (
		<Box className="contentLandingGiftCard">
			<img src={newImg} className="newImg" />
			<div className="purchaseGiftCard">
				<b>{`רוכשים שובר מתנה לאפליקציית ההכרויות של שליש גן עדן Smart`}</b>
				{"מעניקים אהבה לאדם שחשוב לנו, ובעזרת ה' מרוויחים חלק בשידוך ובבניית בית נוסף בעם ישראל."}
			</div>
			<img src={heartGiftCard} className="newImg" />
			<h3 className="howWork">{"איך זה עובד?"}</h3>
			<ImageTexts />
		</Box>
	);
}

function ImageTexts() {
	const data = [
		{ image: phone, text: "מכניסים את מספר הטלפון של האדם שאתם רוצים לפרגן לו." },
		{ image: letter, text: "כותבים ברכה אישית קצרה או בוחרים מתוך מאגר ברכות קיים" },
		{ image: watch, text: "מגדירים תאריך ושעה לשליחת ההודעה על המתנה" },
		{
			image: getGift,
			text: "ברגע שהוא או היא ייכנסו לאפליקציה עם מספר הטלפון שבחרתם, הם יקבלו את האפשרות להפעיל את המתנה.",
		},
	];
	return (
		<>
			{data.map((item, index) => (
				<Box key={index} className="imageTextRow">
					<img src={item?.image} className="imgRow" />
					<span className="textRow">{item.text}</span>
				</Box>
			))}
		</>
	);
}
