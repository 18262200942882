import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { isEmpty } from "lodash";

import Notification from "./Notification";
import { openNotification, closeNotification } from "redux/notification/actions";
import { getUserProfile } from "redux/profile/selectors";
import { updateReadMassleter } from "redux/chatReceived/actions";
import { activeUserId, cancelActiveUser } from "redux/chat/actions";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import viewedMe from "services/viewedMe";
import likedMe from "services/likedMe";
import chatMessages from "services/chatMessages";
import publicProfile from "services/publicProfile";

export default function NotificationWrapper() {
	const dispatch = useDispatch();
	const usersSendMeNotifications = useRef({
		viewedMe: [],
		likedMe: [],
	});
	const loggedInUserProfile = useSelector(getUserProfile);
	const { showUserProfileSideBar } = usePopups();

	useEffect(() => {
		if (!loggedInUserProfile?.uid) return;

		const subscriptions = [
			{
				service: viewedMe.listenViewedMeAdded,
				key: "viewedMe",
				path: "/viewedme",
				messageKey: "notifications.newView",
			},
			{
				service: likedMe.listenLikedMeAddedFromNow,
				key: "likedMe",
				path: "/likedme",
				messageKey: "notifications.newLike",
			},
		];

		const subscriptionHandlers = subscriptions.map(({ service, key, path, messageKey }) =>
			service(loggedInUserProfile.uid).subscribe(async (data) => {
				if (isEmpty(data)) return;

				for (const [userId, value] of Object.entries(data)) {
					if (
						usersSendMeNotifications.current[key].includes(userId) ||
						value?.unView === false ||
						value?.deleted
					)
						continue;

					usersSendMeNotifications.current[key].push(userId);
					const userProfile = await publicProfile.fetchProfile(userId);
					if (isEmpty(userProfile)) continue;

					dispatch(
						openNotification(
							<NotificationMessage
								path={path}
								message={i18next.t(messageKey)}
								user={userProfile}
								onClose={() => dispatch(closeNotification())}
							/>
						)
					);
				}
			})
		);

		return () => subscriptionHandlers.forEach((sub) => sub.unsubscribe());
	}, [loggedInUserProfile, dispatch]);

	const handleUserClick = (uid, isMassletter, chatID, user) => {
		dispatch(closeNotification());
		if (isMassletter) dispatch(updateReadMassleter(uid));
		dispatch(activeUserId(uid, chatID));
		if (!isMobile()) showUserProfileSideBar(user);
	};

	useEffect(() => {
		if (!loggedInUserProfile?.uid) return;

		const subscription = chatMessages
			.listenNewMessagesReceived(loggedInUserProfile.uid)
			.subscribe(async (data) => {
				if (
					isEmpty(data) ||
					!data.id ||
					window.location.pathname.includes("/messages") ||
					data?.deleted
				)
					return;

				dispatch(cancelActiveUser());

				const userSendMeMsg = await publicProfile.fetchProfile(data.id);
				if (isEmpty(userSendMeMsg)) return;

				dispatch(
					openNotification(
						<NotificationMessage
							path="/messages"
							message={i18next.t("notifications.newMsg")}
							user={userSendMeMsg}
							onClick={() => handleUserClick(userSendMeMsg.uid, null, data.chatID, userSendMeMsg)}
						/>
					)
				);
			});

		return () => subscription.unsubscribe();
	}, [loggedInUserProfile, dispatch]);

	return <Notification />;
}

const NotificationMessage = ({ path, message, user, onClose }) => (
	<Link to={path} className="wrapNotificationMsg" onClick={onClose}>
		{message} {user?.name}
		{!!user.pictures?.[user?.mainPictureName]?.url && (
			<img src={user.pictures[user.mainPictureName].url} alt="img" />
		)}
	</Link>
);
