import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPopups } from "redux/popups/selectors";
import dynamicPopupsService from "services/dynamicPopups.service";
import useNavigator from "hooks/useNavigator";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const { closePopup } = usePopups();
	const { navigateToPath } = useNavigator();
	const { data } = useSelector(getPopups);
	const { name } = useSelector(getUserProfile);

	const openLink = (link) => {
		if (link) {
			if (link?.includes(process.env.REACT_APP_DOMAIN)) {
				const path = link.replace(process.env.REACT_APP_DOMAIN, "");
				return navigateToPath(path);
			} else {
				return window.open(link, "_blank");
			}
		}
	};

	const handleClick = () => {
		if (data?.url) openLink(data.url);
		return closePopup();
	};

	useEffect(() => {
		if (data?.key) dynamicPopupsService.setUserShowPopup(data.key).then((res) => console.log(res));
	}, []);
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={`simpleDialog dynamicModal${data?.classDialog ? ` ${data.classDialog}` : ""}`}>
			{data?.withExit && (
				<div className="user-chat-nav ">
					<Button
						color="none"
						type="button"
						onClick={closePopup}
						className="nav-btn"
						id="user-profile-hide">
						<i className="ri-close-line"></i>
					</Button>
				</div>
			)}
			<ModalHeader>
				<div
					dangerouslySetInnerHTML={{
						__html: (data?.headerText || "").replaceAll("{userName}", name),
					}}
					style={{ whiteSpace: "pre-wrap" }}
				/>
			</ModalHeader>

			<ModalBody>
				<div
					dangerouslySetInnerHTML={{
						__html: (data?.bodyText || "").replaceAll("{userName}", name),
					}}
					style={{ whiteSpace: "pre-wrap", lineHeight: "1.3" }}
				/>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					size="sm"
					color="primary"
					onClick={handleClick}
					className="btn-rounded littleBtn">
					{data?.footerBtnText || "הבנתי"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
