import "assets/scss/popups/buyWithToken.scss";
import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import { getPopups } from "redux/popups/selectors";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import tokenService from "services/token.service";
import whiteLogo from "assets/images/newLogo.svg";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useNavigator from "hooks/useNavigator";
import speedDatingService from "services/speedDatingService";
import purchaseTypes from "consts/purchaseTypes";
import purchaseService from "services/purchase.service";

export default function BuyWithTokenModal() {
	const {
		closePopup,
		showSubscriptionPopup,
		showSubscriptionSuccessfullyPopup,
		showPromotionsSalePopup,
		showFittedMessagePopup,
	} = usePopups();
	const { navigateToSuccessfullyPayVideoPage, navigateToPayVideoPage } = useNavigator();
	const { uid } = useSelector(getUserProfile);

	const { data } = useSelector(getPopups);
	const { selectedPlan, tokenData, speedData, withoutReturn, isPromotion } = data;
	const [numOfPayments, setNumOfPayments] = useState(selectedPlan?.min_num_of_payments || 1);
	const [isLoading, setIsLoading] = useState(false);
	const [iframeUrl, setIframeUrl] = useState(""); //for speed date

	const MAX_NUM_OF_PAYMENTS = speedData?.key ? 1 : selectedPlan?.max_num_of_payments ?? 3;
	const MIN_NUM_OF_PAYMENTS = selectedPlan?.min_num_of_payments || 1;
	const countPayments = _.range(MIN_NUM_OF_PAYMENTS, Number(MAX_NUM_OF_PAYMENTS) + 1);

	const getHeaderText = () => {
		let text = "";
		if (!!isPromotion) {
			text = `אישור רכישה והפעלת קידום אישי`;
		} else if (selectedPlan?.renewable && !selectedPlan?.isSale) {
			text = `אישור רכישת מנוי חודשי מתחדש`;
		} else if (speedData?.key) {
			text = `אישור רכישה - וידאו דייט`;
		} else {
			return (
				<div>
					<div>{"אישור רכישת מנוי"}</div>
					<div>
						<span>{"ל- "}</span>
						<span className="coloredNum">{selectedPlan?.sub_long}</span>
						<span>{` ${i18next.t(`Subscription.Times.${selectedPlan?.sub_time}`)} `}</span>
					</div>
				</div>
			);
		}
		return <span>{text}</span>;
	};

	const getBodyText = () => (
		<div className="textToken">
			<div>{`התשלום בסך ${selectedPlan?.price ?? speedData?.price} ש"ח`}</div>
			<div>{`יבוצע באמצעות כרטיס אשראי`}</div>
			<div>{`שמסתיים בספרות:`}</div>
			<div>{`**${tokenData?.credit_4_digits ?? "0000"}`}</div>
		</div>
	);

	const getFooterText = () => {
		let text = "";

		if (selectedPlan?.key)
			text = `בלחיצה על "אישור רכישה", הנך מאשר/ת שכרטיס אשראי זה בבעלותך, וברצונך לבצע את הרכישה. מיד לאחר האישור ייכנס המנוי לתוקף, תוכל/י לקרוא את כל ההודעות שנשלחו אליך עד היום.`;
		if (speedData?.key || isPromotion)
			text = `בלחיצה על "אישור רכישה", הנך מאשר/ת שכרטיס אשראי זה בבעלותך, וברצונך לבצע את הרכישה.`;

		return <div className="termsPurch">{text}</div>;
	};

	const handleClose = () => {
		if (speedData?.key || selectedPlan?.key == 307 || withoutReturn) return closePopup();
		showSubscriptionPopup();
	};

	const handleBuyWithToken = async () => {
		if (!numOfPayments) return;

		if (isPromotion) {
			setIsLoading(true);

			return await tokenService
				.payPromotionWithCardcomToken(numOfPayments, selectedPlan.key)
				.then((res) => {
					console.log(res);
					closePopup();
					let textFitted = "";
					if (res.data?.success) {
						textFitted = (
							<div>
								<h2 style={{ fontWeight: "bold" }}>{`הרכישה בוצעה בהצלחה`}</h2>
								<div>{`איזה כיף! ברגע זה ממש אנחנו מתחילים לקדם אותך 😍`}</div>
								<div style={{ marginTop: "10px" }}>{`בהצלחה במציאת החצי השני!`}</div>
							</div>
						);
					} else {
						textFitted = (
							<div>
								<h2 style={{ fontWeight: "bold" }}>{`הרכישה נכשלה`}</h2>
							</div>
						);
					}
					showFittedMessagePopup(textFitted, false);
					setIsLoading(false);
				})
				.catch((e) => console.log(e));
		} else if (selectedPlan?.key) {
			setIsLoading(true);

			return await tokenService
				.payByUserWithCardcomToken(numOfPayments, selectedPlan.key)
				.then((res) => {
					console.log(res);
					closePopup();
					if (res.data?.success) {
						return showSubscriptionSuccessfullyPopup();
					}
					setIsLoading(false);
				})
				.catch((e) => console.log(e));
		} else if (speedData?.key) {
			setIsLoading(true);
			return await tokenService
				.payVideoDateWithToken(speedData.key)
				.then((res) => {
					console.log(res);
					if (res.data?.success) {
						navigateToSuccessfullyPayVideoPage();
						return closePopup();
					}
					setIsLoading(false);
				})
				.catch((e) => console.log(e));
		}
	};

	const handleBuyWithCreditCard = async () => {
		if (speedData?.key) {
			const iframeUrlLink =
				iframeUrl ||
				(await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key)).data?.url ||
				"";

			navigateToPayVideoPage({
				urlName: speedData.urlName,
				iframeUrlLink,
			});
			closePopup();
		} else if (isPromotion) {
			showPromotionsSalePopup(selectedPlan, true);
		} else showSubscriptionPopup(undefined, undefined, undefined, data);
	};

	useEffect(async () => {
		if (!speedData?.key || iframeUrl) return;

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) setIframeUrl(res.data?.url);
	}, [speedData?.key]);

	useEffect(async () => {
		if (!uid) return;

		let typeStartPurchase = "";
		if (!!isPromotion) {
			typeStartPurchase = purchaseTypes.promotion;
		} else if (!!selectedPlan?.key) {
			typeStartPurchase = purchaseTypes.subscription;
		} else if (!!speedData?.key) {
			typeStartPurchase = purchaseTypes.speedDate;
		}
		if (typeStartPurchase) purchaseService.updatePurchaseIsStart(typeStartPurchase);
	}, [uid]);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog buyWithToken">
			<div className="user-chat-nav">
				<Button
					color="none"
					type="button"
					onClick={handleClose}
					className="nav-btn"
					id="user-profile-hide">
					<ArrowBackIosNewIcon style={{ color: "#fff", transform: "rotate(180deg)" }} />
				</Button>
			</div>
			<Box className="colorHeader">
				<img src={whiteLogo} alt="" height={"50px"} />
			</Box>
			<ModalHeader>{getHeaderText()}</ModalHeader>
			<ModalBody>
				{getBodyText()}

				<FormControl className="formSelect">
					<InputLabel id="numOfPaymentsLabel">{`תשלומים`}</InputLabel>
					<Select
						labelId="numOfPaymentsLabel"
						id="numOfPayments"
						value={numOfPayments}
						label="numOfPayments"
						onChange={(event) => {
							console.log(event.target.value);
							setNumOfPayments(event.target.value);
						}}>
						{countPayments.map((number) => (
							<MenuItem key={number} value={number}>
								{number}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{getFooterText()}
			</ModalBody>
			<ModalFooter style={{ display: "flex", flexDirection: "column" }}>
				{!!isLoading ? (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<CircularProgress size={40} className="text-secondary" />
					</div>
				) : (
					<>
						<Button
							outline
							size="sm"
							color="primary"
							onClick={handleBuyWithToken}
							className="btn-rounded approvePurchesBtn"
							style={{
								width: isPromotion ? "100%" : "",
								minWidth: isPromotion ? "fit-content" : "",
								maxWidth: isPromotion ? "500px" : "",
							}}>
							{isPromotion ? "אישור רכישה והפעלת קידום אישי" : "אישור רכישה"}
						</Button>
						<Button
							outline
							size="sm"
							color="secondary"
							onClick={handleBuyWithCreditCard}
							className="btn-rounded purchesWithCredit"
							style={{ border: "none" }}>
							{`רכישה באמצעות כרטיס אשראי אחר`}
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}
