import { Box } from "@mui/material";
import questionsLogoRed from "assets/images/questionsLogoRed.png";
import DetailsAreaContainer from "./DetailsAreaContainer";
import AccountQuestionsBox from "components/questions/AccountQuestionsBox";

export default function AccountQuestions() {
	return (
		<DetailsAreaContainer title={"שאלון התאמה"}>
			<AccountQuestionsBox />
		</DetailsAreaContainer>
	);
}
