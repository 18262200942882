import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect, useHistory } from "react-router-dom";

// import { getBlockedUsers } from "redux/blocks/selectors";
import { getUserProfile } from "redux/profile/selectors";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import Sidebar from "views/Sidebar";
import Content from "views/Content";
import AccountSidebar from "components/account/Sidebar";
import Loader from "components/Loader";
import AccountRoute from "config/routes/AccountRoute";
import { useEffect } from "react";
import { getPercentUser } from "redux/questions/selectors";
import { getPercent } from "redux/questions/actions";

export default () => {
	const isUserLoggedIn = !isEmpty(useSelector(getUserProfile));
	const history = useHistory();
	const currentPath = history.location.pathname;
	const isTagsFetched = useSelector((state) => state.system.isTagsFetched);
	const subscriptionType = useSelector(getUserSubscriptionType);
	const percent = useSelector(getPercentUser);
	const dispatch = useDispatch();
	// const blockedUsers = useSelector(getBlockedUsers);

	// if (currentPath === "/account/blocked" && isUserLoggedIn && blockedUsers.length === 0)
	// 	return <Redirect to="/account" />;

	if (
		currentPath === "/account/subscription" &&
		isUserLoggedIn &&
		subscriptionType !== Subscription.PAID &&
		subscriptionType !== Subscription.FREE
	)
		return <Redirect to="/account" />;

	const showPage =
		currentPath === "/account/tags" ? isUserLoggedIn && isTagsFetched : isUserLoggedIn;

	useEffect(() => {
		if (!percent) {
			dispatch(getPercent());
		}
	}, []);

	return showPage ? (
		<div className="accountPage">
			<Sidebar>
				<AccountSidebar />
			</Sidebar>
			<Content>
				<AccountRoute />
			</Content>
		</div>
	) : (
		<Loader />
	);
};
