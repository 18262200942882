import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

import AccountEditProfile from "components/account/AccountEditProfile";
import AccountTags from "components/account/AccountTags";
import AccountPictures from "components/account/AccountPictures";
import AccountSettings from "components/account/AccountSettings";
import AccountSubscriptionSettings from "components/account/AccountSubscriptionSettings";
import AdditionalSettings from "components/account/AdditionalSettings";
import AccountBlockedProfiles from "components/account/AccountBlockedProfiles";
import AccountContact from "components/account/AccountContact";
import AccountTerms from "components/account/AccountTerms";
import AccountVideoDate from "components/account/AccountVideoDate";
import AccountTestUsers from "components/account/AccountTestUsers";
import AccountQuestions from "components/account/AccountQuestions";

export default () => {
	return (
		<>
			<Route path="/account/editProfile" component={AccountEditProfile} />
			<Route path="/account/tags" component={AccountTags} />
			<Route path="/account/pictures" component={AccountPictures} />
			<Route path="/account/settings" component={AccountSettings} />
			<Route path="/account/subscription" component={AccountSubscriptionSettings} />
			<Route path="/account/more-settings" component={AdditionalSettings} />
			<Route path="/account/blocked" component={AccountBlockedProfiles} />
			<Route path="/account/contact" component={AccountContact} />
			<Route path="/account/terms" component={AccountTerms} />
			<Route path="/account/videoDate/" exact component={AccountVideoDate} />
			<Route path="/account/videoDate/:url" component={AccountVideoDate} />
			<Route path="/account/testUsers" component={AccountTestUsers} />
			<Route path="/account/questions/" exact component={AccountQuestions} />
		</>
	);
};
