import { SwipeableDrawer, Box, Typography, Button, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPercent } from "redux/questions/actions";
import questionsMatchService from "services/questionsMatchService";

export default function QuestionDrawer({
	isOpen,
	toggleDrawer,
	question,
	answerKey,
	myPictureUri = "",
	userProfile,
}) {
	const dispatch = useDispatch();
	const [questions, setQuestions] = useState([]);
	const [myAnswers, setMyAnswers] = useState({});
	const [currentQuest, setCurrentQuest] = useState(question || null);
	const [index, setIndex] = useState(0);

	const handleClick = async (
		questionKey,
		newAnswerKey = null,
		answerText = null,
		isSkip = false
	) => {
		if (newAnswerKey === answerKey) return goToNextQuestion();
		if (isSkip && myAnswers[questionKey]) return goToNextQuestion(true);

		const data = {
			questionKey,
			answerText,
			answerKey: newAnswerKey,
			isSkip,
		};
		const res = await questionsMatchService.getUserAnswerMatchQuestion(data);
		if (res.data?.success) {
			goToNextQuestion();
		}
	};

	const goToNextQuestion = (isSkip = false) => {
		setIndex(index + 1);
		if (!isSkip) dispatch(getPercent());

		if (index + 1 >= questions?.length) {
			toggleDrawer(null, false);
		}
	};
	const goToPrevQuestion = () => {
		if (index <= 0) return;
		setIndex(index - 1);
	};

	const getAnsActive = (questionKey, itemKey) => {
		return !!(myAnswers[questionKey] && myAnswers[questionKey]?.answerKey === itemKey);
	};

	useEffect(async () => {
		if (!userProfile?.uid) return;

		const subscription = questionsMatchService
			.listenToUserQuestions$(userProfile.uid)
			.subscribe((res) => {
				if (res) setMyAnswers(res);
			});

		return () => {
			subscription?.unsubscribe();
		};
	}, [userProfile?.uid]);

	useEffect(() => {}, []);

	useEffect(async () => {
		if (!isOpen) setIndex(0);
		if (!isOpen && !questions?.length) return;
		await questionsMatchService.getQuestions().then((res) => {
			if (res.data?.success) {
				let resQuestions = (res.data?.questions || [])
					.filter((item) => item?.key !== question?.key && !myAnswers[item?.key])
					.sort((a, b) => a?.order - b?.order);

				if (question) resQuestions = [question, ...resQuestions];

				setQuestions(resQuestions);
			}
		});
	}, [isOpen]);

	useEffect(() => {
		if (!question) return;
		setCurrentQuest(question);
	}, [question]);

	useEffect(() => {
		if (!questions[index]) return;
		setCurrentQuest(questions[index]);
	}, [index]);

	return (
		<SwipeableDrawer
			anchor="bottom"
			open={isOpen}
			onClose={(e) => toggleDrawer(e, false)}
			onOpen={(e) => toggleDrawer(e, true)}
			PaperProps={{
				sx: { minHeight: "fit-content", borderTopLeftRadius: 16, borderTopRightRadius: 16 },
			}}>
			<Box
				className="wrapQuestionDrawer"
				sx={{
					p: 3,
					display: "flex",
					flexDirection: "column",
					gap: 2,
					height: "100%",
					overflowY: "auto",
				}}>
				<h5 className="questionText">{currentQuest?.question}</h5>
				<Box className="wrapAnsBox">
					<Box className="wrapImgAns">
						{myPictureUri && <img src={myPictureUri} alt="colleague" className="avatar" />}

						{!!Object.values(currentQuest?.answers || {})?.length && (
							<Box className="contentAns">
								{Object.values(currentQuest.answers).map((item, key) => {
									return (
										<Button
											key={key}
											className={`innerContentAns ${
												getAnsActive(currentQuest?.key, item?.key) ? "ansActive" : ""
											}`}
											onClick={() => handleClick(currentQuest?.key, item?.key, item?.text)}>
											{item?.text}
										</Button>
									);
								})}
							</Box>
						)}
					</Box>
					<Box className="skipBackBox">
						<span className="back">
							{index > 0 && <span onClick={goToPrevQuestion}>{"לשאלה הקודמת"}</span>}
						</span>
						<span className="skip" onClick={() => handleClick(currentQuest?.key, null, null, true)}>
							{"דילוג"}
						</span>
					</Box>
				</Box>
			</Box>
		</SwipeableDrawer>
	);
}
