import i18next from "i18next";
import validate from "validate.js";
import { useState, useEffect, useRef } from "react";

import TextInput from "components/TextInput";
import ProfileFields from "consts/profileFields";
import FillProfileDetailsValidations from "consts/fillProfileDetailsValidations";
import Warnings from "../Warnings";
import DoneIcon from "@mui/icons-material/Done";
import emailService from "services/email.service";
import { CircularProgress } from "@mui/material";
import VerificationInput from "react-verification-input";
import { Form, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { updateProfile } from "redux/profile/actions";
import usePopups from "hooks/usePopups";
import { Button } from "reactstrap";

export default ({ profile, handleOnSubmit = () => {}, isEmailStep = false }) => {
	const dispatch = useDispatch();
	const { showUpdatesSavedSuccessfullyPopup } = usePopups();

	//email
	const [errorMessage, setErrorMessage] = useState();
	const [inputEmail, setInputEmail] = useState(profile?.email || "");
	const [isEmailSend, setIsEmailSend] = useState(false);

	//code
	const [inputCode, setInputCode] = useState("");
	const [wrongCode, setWrongCode] = useState(false);
	const [isValidCode, setIsvalidCode] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(false);

	const [showDidntSendCode, setShowDidntSendCode] = useState(false);
	const [showSentBtn, setShowSentBtn] = useState(false);
	const [loader, setLoader] = useState(false);
	const [didntGetCode, setDidntGetCode] = useState(false);

	const _onChange = (key, value) => {
		const emailValidationErrors = validate.single(value, FillProfileDetailsValidations.email);

		setErrorMessage(emailValidationErrors && !!value ? emailValidationErrors[0] : null);
		if (value !== inputEmail || !value) {
			setInputEmail(value);
		}
	};

	const sendFormWithoutVerify = () => {
		dispatch(
			updateProfile(
				"email",
				typeof inputEmail === "string" ? inputEmail.trim() : inputEmail,
				profile.email
			)
		);
	};
	const sendForm = () => {
		if (!isEmailSend) {
			if (!!inputEmail && !errorMessage) {
				setLoader(true);
				emailService
					.sendVerificationEmailCode(inputEmail)
					.then((data) => {
						setLoader(false);
						setDisabledBtn(true);
						if (data?.data?.success) {
							if (!profile?.email) {
							}
							setShowDidntSendCode(true);
							return setIsEmailSend(true);
						}
					})
					.catch((e) => {
						console.log(e);
						return setLoader(false);
					});
			}
		} else {
			if (!!inputCode && !!isValidCode) {
				setLoader(true);
				emailService
					.verifyEmailCode(inputCode)
					.then((data) => {
						if (data?.data?.success) {
							setIsEmailSend(false);
							setIsvalidCode(false);
							setShowSentBtn(false);

							if (!!isEmailStep) {
								handleOnSubmit();
							} else {
								showUpdatesSavedSuccessfullyPopup();
							}
						} else if (data?.data?.msg === "Incorrect Code") setWrongCode(true);
						setLoader(false);
					})
					.catch((e) => {
						console.log(e);
						setWrongCode(true);
						return setLoader(false);
					});
			}
		}
	};

	const handleResendCode = (e) => {
		e.preventDefault();
		setShowDidntSendCode(false);
		if (!!inputEmail && !errorMessage) {
			setIsEmailSend(false);
			setLoader(true);
			emailService
				.sendVerificationEmailCode(inputEmail)
				.then((data) => {
					setLoader(false);
					setDisabledBtn(true);
					if (data?.data?.success) {
						setShowDidntSendCode(true);
						return setIsEmailSend(true);
					}
				})
				.catch((e) => {
					console.log(e);
					return setLoader(false);
				});
		}
	};

	useEffect(() => {
		console.log(errorMessage);

		if (
			// !!inputEmail &&
			!errorMessage &&
			(inputEmail !== profile?.email || (inputEmail == profile?.email && !profile?.isVerifiedEmail))
		) {
			setShowSentBtn(true);
		} else {
			setShowSentBtn(false);
		}
	}, [inputEmail]);

	useEffect(() => {
		setWrongCode(false);
		if (!!isEmailSend) {
			if (!inputCode?.length || inputCode.length < 6) {
				setDisabledBtn(true);
			} else {
				setDisabledBtn(false);
			}
		}
	}, [inputCode]);

	return (
		<div
			className={!isEmailSend ? "wrapInputValidation emailDidntSent" : "wrapInputValidation"}
			style={{}}>
			<TextInput
				field={ProfileFields.Email}
				onChange={_onChange}
				initValue={inputEmail}
				// disabled={!!isEmailSend}
				title={isEmailStep ? "" : i18next.t("Account Settings.Email")}
			/>
			{/* {profile.email && !profile?.isVerifiedEmail && (
				<span
					style={{
						color: "red",
						position: "absolute",
						left: "5px",
						lineHeight: "1",
						fontSize: "12px",
						top: "50px",
						textAlign: "end",
						margin: "0",
					}}>
					{i18next.t("email.hasNotBeenVerified")}
				</span>
			)} */}
			<Warnings
				error={
					(!!errorMessage && !!inputEmail && i18next.t(`Validations.${errorMessage}`)) ||
					(!!wrongCode && i18next.t(`Validations.wrongCode`))
				}
				errorClass="email"
			/>
			{/* {!!isEmailStep && !!showSentBtn && (
				<span
					style={{
						color: "#8b8b8d",
						fontSize: "1.2rem",
						margin: "10px 0",
					}}>
					{i18next.t("email.acceptTerms")}
				</span>
			)} */}
			{/* {!!isEmailSend && (
				<FormValidation
					onChange={(val) => setInputCode(val)}
					onValidityChange={(val) => setIsvalidCode(val)}
					onEnterPressed={sendForm}
				/>
			)} */}
			{/* {!isEmailStep &&
				!!inputEmail &&
				inputEmail === profile?.email &&
				!!profile?.isVerifiedEmail && (
					<span
						className="doneIconEmail"
						style={{ position: "absolute", left: "0.5rem", top: "26px", width: "min-content" }}>
						{<DoneIcon sx={{ color: "green" }} />}
					</span>
				)} */}
			{(isEmailStep || profile?.email !== inputEmail) && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
						alignSelf: isEmailStep ? "end" : "center",
						flexDirection: "column",
					}}>
					{showSentBtn && !wrongCode && (
						<>
							{!!loader ? (
								<CircularProgress
									size={30}
									className="text-primary"
									style={{ alignSelf: "center" }}
								/>
							) : (
								<div
									className="center"
									style={{
										position: "relative",
										display: "flex",
										alignSelf: isEmailStep ? "end" : "center",
									}}>
									{isEmailStep && profile?.email && (
										<button
											onClick={handleOnSubmit}
											style={{
												position: "relative",
												width: "max-content",
												fontSize: "1.5rem",
												color: "#8b8b8d !important",
												border: "0",
												background: "#ffffff00",
												marginBlockStart: "0.5rem",
												position: "absolute",
												left: "0px",
												bottom: "-30px",
											}}>
											{i18next.t("email.skip")}
										</button>
									)}
									<Button
										onClick={sendFormWithoutVerify}
										disabled={disabledBtn}
										style={{
											borderRadius: "5rem",
											padding: isEmailStep ? "0.3rem 5rem" : "0.3rem 3rem",
											position: "relative",
											marginBlockStart: "0.5rem",
											fontWeight: isEmailStep ? 500 : 400,
											lineHeight: isEmailStep ? 1.7 : 1.5,
											width: "max-content",
											background: "#5863be !important",
											borderColor: "#5863be !important",
											// alignSelf: isEmailSend ? "center" : "end",
											fontSize: isEmailStep ? "2rem" : "19px",
										}}>
										{`${!!profile?.gender ? "שלחי" : "שלח"}`}
										{/* 	{isEmailSend
						? i18next.t("email.verifiedCode")
						: i18next.t("email.sendCodeVerifiedEmail")} */}
									</Button>
								</div>
							)}
						</>
					)}
					{/* {!!isEmailSend && showDidntSendCode && (
					<DidntGetCode
						didntGetCode={didntGetCode}
						handleResendCode={handleResendCode}
						setDidntGetCode={setDidntGetCode}
						isEmailStep={isEmailStep}
					/>
				)} */}
				</div>
			)}
		</div>
	);
};

const FormValidation = ({ onChange, onValidityChange, onEnterPressed }) => {
	const [isValid, setIsValid] = useState(false);
	const [value, setValue] = useState("");
	const myRef = useRef(null);

	const handleOnSubmit = (e) => {
		e.preventDefault();
		onEnterPressed();
	};

	const handleChange = (val) => {
		setValue(val);
		onChange(val);
		const validLength = val.length === 6;

		if (isValid !== validLength) {
			setIsValid(validLength);
		}
	};

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	const scrollRefIntoView = () => {
		if (myRef.current) {
			myRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		scrollRefIntoView();
	}, [myRef]);

	return (
		<Form onSubmit={handleOnSubmit} inline>
			<FormGroup>
				<span>{i18next.t("Account Settings.CodeEmail")}</span>
				<VerificationInput
					ref={myRef}
					value={value}
					length={6}
					validChars="\d"
					autoFocus={true}
					onFocus={scrollRefIntoView}
					placeholder=""
					classNames={{
						container: "container",
						character: "character",
						characterInactive: "character--inactive",
						characterSelected: "character--selected",
					}}
					onChange={handleChange}
				/>
			</FormGroup>
		</Form>
	);
};

const DidntGetCode = ({ didntGetCode, handleResendCode, setDidntGetCode, isEmailStep = false }) => {
	const [ticks, setTicks] = useState(60);

	useEffect(() => {
		if (ticks > 0) {
			setTimeout(() => {
				setTicks((value) => value - 1);
			}, 1000);
		} else {
			setDidntGetCode(true);
		}
	}, [ticks]);

	return (
		<div>
			{didntGetCode ? (
				<a
					onClick={handleResendCode}
					style={{
						marginTop: "10px",
						color: "#5863be",
						fontWeight: 600,
						borderBottom: "1px solid #5863be",
						width: "max-content",
						cursor: "pointer",
						fontSize: isEmailStep ? "1.2rem" : "12px",
					}}>
					{i18next.t("email.sendMeCode")}
				</a>
			) : (
				<span
					style={{
						marginTop: "10px",
						color: "#797879",
						fontWeight: 600,
						width: "max-content",
						fontSize: isEmailStep ? "1.2rem" : "12px",
					}}>
					{ticks} {i18next.t("email.toGetCode")}
				</span>
			)}
		</div>
	);
};
