import { Box } from "@mui/material";
import target from "assets/images/questions/target.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default function StartEndPage({ handleClick, isEnd = false, userAnswers = {} }) {
	const { gender } = useSelector(getUserProfile);
	const [countAns, setCountAns] = useState(0);

	const getText = () => {
		if (!isEnd) return `יש לנו כמה שאלות עבורך שיעזרו להתאמה המושלמת`;

		if (countAns < 20)
			return `חבל, לא ענית על מספיק שאלות. שאלון ההתאמה מגדיל את הסיכויים למצוא התאמה מדוייקת יותר.`;
		return `כל הכבוד! הגדלת את הסיכויים למצוא התאמה מדויקת יותר עבורך!`;
	};

	useEffect(() => {
		setCountAns(Object.values(userAnswers).filter((item) => !!item?.answerKey)?.length);
	}, [userAnswers]);

	return (
		<Box className="startEndPage">
			<Box className="contentStart">
				<p className="textStart">{getText()}</p>
				<img src={target} alt="target" />
			</Box>
			<Box className="boxBtn">
				<button className="start-button" onClick={() => handleClick(countAns < 20)}>
					{isEnd ? (countAns < 20 ? "לצפייה בשאלות שדילגתי עליהן" : "לצפייה בתשובות שלי") : "התחלה"}
				</button>
			</Box>
		</Box>
	);
}
