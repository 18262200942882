import DetailsAreaContainer from "./DetailsAreaContainer";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { useEffect } from "react";
import useNavigator from "hooks/useNavigator";
import { Button } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getSubscriptionPlans } from "redux/subscription/selectors";
import speedDatingService from "services/speedDatingService";

export default function AccountTestUsers() {
	const { navigateToAccountPage } = useNavigator();
	const { showSubscriptionPopup } = usePopups();
	const dbPlans = useSelector(getSubscriptionPlans);
	const { showJaaSMeetingPopup, showTestPopup } = usePopups();

	const user = useSelector(getUserProfile);

	const handleClick = async () => {
		const data = dbPlans.find((plan) => plan?.isTest);
		console.log(data);
		if (!data) return;

		return showSubscriptionPopup(undefined, undefined, undefined, { selectedPlan: { ...data } });
	};

	const openTestJaaSMeeting = () => {
		speedDatingService.getTestRoom("-OCD035dslhjn7IuXFMw").then((data) => {
			console.log(data);
			if (data.data?.success) {
				showJaaSMeetingPopup({ ...data.data, name: "tester" });
			}
		});
	};

	useEffect(() => {
		if (!user?.isTester) return navigateToAccountPage();
	}, []);

	return (
		<DetailsAreaContainer title={"טסטים"}>
			<Button type="button" onClick={handleClick}>
				{"פתח תשלום ביט"}
			</Button>

			<Button
				style={{ margin: "10px 0", padding: 0 }}
				color="none"
				type="button"
				onClick={openTestJaaSMeeting}>
				<span>{"openTestJaaSMeeting"}</span>
			</Button>

			<Button
				style={{ margin: "10px 0", padding: 0 }}
				color="none"
				type="button"
				onClick={showTestPopup}>
				<span>{"showTestPopup"}</span>
			</Button>
		</DetailsAreaContainer>
	);
}
