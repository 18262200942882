import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPercentUser } from "redux/questions/selectors";
import GradientPercentageBar from "./GradientPercentageBar";
import arrowBlueQues from "assets/images/questions/arrowBlueQues.png";
import { getPercent } from "redux/questions/actions";
import { getUserProfile } from "redux/profile/selectors";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import { Button } from "reactstrap";
import questionsMatchService from "services/questionsMatchService";
import useNavigator from "hooks/useNavigator";
import ClearIcon from "@mui/icons-material/Clear";

export default function UserQuickQuestionPage({
	userAnswersList = {},
	questions = [],
	closePopup,
}) {
	const dispatch = useDispatch();
	const loggedInUserProfile = useSelector(getUserProfile);
	const percentQuestionsUser = useSelector(getPercentUser);
	const [index, setIndex] = useState(0);
	const [percent, setPercent] = useState(percentQuestionsUser ?? 0);
	const [currentQuest, setCurrentQuest] = useState(null);
	const [userAnswers, setUserAnswers] = useState(userAnswersList ?? {});
	const [isDisabled, setIsDisabled] = useState(false);
	const { navigateToAccountQuestions } = useNavigator();
	const [userAnswersLocal, setUserAnswersLocal] = useState({});

	const mainPictureUri =
		loggedInUserProfile.pictures?.[loggedInUserProfile.mainPictureName]?.url || "";

	const goToNextQuestion = () => {
		setIndex(index + 1);
		dispatch(getPercent());
	};
	const goToPrevQuestion = () => {
		if (index <= 0) return;
		setIndex(index - 1);
	};

	const handleClick = async (questionKey, answerKey = null, answerText = null, isSkip = false) => {
		if (isDisabled) return;
		setIsDisabled(true);
		if (isSkip && userAnswers[questionKey]) return goToNextQuestion();
		if (answerKey) setUserAnswersLocal({ ...userAnswersLocal, [questionKey]: answerKey });

		const data = {
			questionKey,
			answerText,
			answerKey,
			isSkip,
		};
		const res = await questionsMatchService.getUserAnswerMatchQuestion(data);
		if (res.data?.success) {
			goToNextQuestion();
		}
	};
	const ansActive = (questionKey, answerKey) => {
		return (
			!!(userAnswers[questionKey] && userAnswers[questionKey]?.answerKey === answerKey) ||
			(userAnswersLocal[questionKey] && userAnswersLocal[questionKey] === answerKey)
		);
	};

	useEffect(() => {
		if (percent !== percentQuestionsUser) setPercent(percentQuestionsUser);
	}, [percentQuestionsUser]);

	useEffect(() => {
		if (index >= questions?.length) return;
		if (!!questions?.length) setCurrentQuest(questions[index]);
	}, [index, questions]);

	useEffect(() => {
		setUserAnswers(userAnswersList);
	}, [userAnswersList]);

	useEffect(() => {
		setIsDisabled(false);
	}, [currentQuest?.key]);
	return (
		<Box className="userQuickQuestion">
			<Box className="wrapUserQuickQuestion">
				<Box className="arrowBlueQues" onClick={closePopup}>
					<ClearIcon alt="arrowBlueQues" style={{ color: "#2d5086" }} />
				</Box>

				{currentQuest && (
					<>
						<GradientPercentageBar targetValue={percentQuestionsUser} />
						<Box className="questionBox">
							<h3>{currentQuest?.question}</h3>
						</Box>

						<Box key={`${currentQuest?.key}-answers`} className="answerBox">
							<img
								className="userProfile"
								src={
									mainPictureUri
										? mainPictureUri
										: loggedInUserProfile?.gender
										? placeholderWomanRound
										: placeholderManRound
								}
							/>
							<Box className="contentAns">
								{!!Object.values(currentQuest?.answers || {})?.length &&
									Object.values(currentQuest.answers).map((item, key) => {
										return (
											<Button
												disabled={isDisabled}
												key={key}
												className={`innerContentAns ${
													ansActive(currentQuest?.key, item?.key) ? "ansActive" : ""
												}`}
												onClick={() => handleClick(currentQuest?.key, item?.key, item?.text)}>
												{item?.text}
											</Button>
										);
									})}
							</Box>
							<Box className="skipBackBox">
								<span className="back">
									{index > 0 && <span onClick={goToPrevQuestion}>{"לשאלה הקודמת"}</span>}
								</span>
								<span
									className="skip"
									onClick={() => handleClick(currentQuest?.key, null, null, true)}>
									{"דילוג"}
								</span>
							</Box>
						</Box>
					</>
				)}
			</Box>
			{!!percent && currentQuest && (
				<Box className="goToAllAnswers">
					<span
						onClick={() => {
							closePopup();
							navigateToAccountQuestions();
						}}>
						{"לצפייה בשאלות שעניתי עליהן"}
					</span>
				</Box>
			)}
		</Box>
	);
}
