import "firebase/database";
import httpsCallable from "./httpsCallable";
import firebase from "firebase/app";
import { Observable } from "rxjs";
class QuestionsService {
	BASE_REF = "users_questions";
	QUESTIONS = "questions";

	async getMyUserQuestions(uid) {
		if (uid) return (await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val();
	}

	listenToUserQuestions$(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	async getQuestions() {
		const result = await httpsCallable.call("getQuestions");
		return result;
	}
	async getUserAnswerMatchQuestion(data) {
		if (!data?.questionKey) return;
		const result = await httpsCallable.call("getUserAnswerMatchQuestion", data);
		return result;
	}
}

export default new QuestionsService();
