import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

import UserChatItem from "./UserChatItem";
import EmptyMessage from "../EmptyMessage";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import { getCanSendMessage, getIsUserSubscribed, getMessages } from "redux/chatMessages/selectors";
import {
	fetchChatMessages,
	leaveChatMessagesScreen,
	sendChatMessage,
} from "redux/chatMessages/actions";
import { hasTyping } from "redux/chatMeta/selectors";
import Typing from "../Typing";
import Footer from "./Footer";
import { getUserProfile } from "redux/profile/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { getIsManualyPromotedUser } from "redux/promotions/selectors";
import ChatMetaService from "services/chatMeta";
import { cancelActiveUser } from "redux/chat/actions";
import { cancelDeleteChat } from "redux/chatReceived/actions";
import usePopups from "hooks/usePopups";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";
import chatRequestsService from "services/chatRequestsService";
import { Button } from "reactstrap";
import { CircularProgress } from "@mui/material";
import { getLoggedInUserUID } from "redux/auth/selectors";
import BuySubscription from "components/account/BuySubscription";

export default ({ isFromCardUserModal = false }) => {
	const dispatch = useDispatch();
	const loggedInUserUID = useSelector(getLoggedInUserUID);

	const [simplebarContent, setSimplebarContent] = useState();
	const userQuotaStatus = useSelector(getUserQuotaStatus);
	const loggedInUserProfile = useSelector(getUserProfile);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const chatID = useSelector(getChat(active_userId));
	const isTyping = useSelector(hasTyping(chatID));
	const chatMessages = useSelector(getMessages(chatID, active_userId));
	const canSendMessage = useSelector(getCanSendMessage);
	const isUserSubscribed = useSelector(getIsUserSubscribed);
	const isNewChat = useSelector(getIsNewChat(active_userId));
	const isManualyPromoted = useSelector(getIsManualyPromotedUser(active_userId));
	const [emptyMessage, setEmptyMessage] = useState("");
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const {
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showSubscriberLimitMessagesPopup,
		showNonSubscriberLimitMessagesPopup,
		showQuotaPopup,
		showFittedMessagePopup,
	} = usePopups();
	const subscriptionType = useSelector(getUserSubscriptionType);
	const history = useHistory();
	const currentPath = history.location.pathname;
	const isLoggedInUserSubscribed =
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE;
	const isCollageSubscribed =
		activeUserProfile.subscription &&
		(activeUserProfile.subscription === Subscription.PAID ||
			activeUserProfile.subscription === Subscription.FREE) &&
		!activeUserProfile.subscriptionFreezed;
	const canRead = useSelector((state) => !!state.chat?.canRead) || false;
	const [showRequest, setShowRequest] = useState(false);
	const [showDeniedRequest, setShowDeniedRequest] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lastMessage, setLastMessage] = useState(null);

	const handleBtnClicked = async (approve) => {
		setLoading(true);
		await chatRequestsService.approveDenyChatRequest(chatID, approve);
		setLoading(false);
		setShowRequest(false);
	};

	useEffect(() => {
		setSimplebarContent(
			document.querySelector(
				isFromCardUserModal
					? ".modal-body .user-chat .simplebar-content-wrapper"
					: ".user-chat .simplebar-content-wrapper"
			)
		);
		return () => {
			currentPath.indexOf("messages") === -1 && dispatch(cancelActiveUser());
		};
	}, []);

	useEffect(() => {
		if (!active_userId) return;
		setShowDeniedRequest(false);
		setShowRequest(false);
		dispatch(fetchChatMessages(active_userId, chatID));
		simplebarContent && simplebarContent.classList.add("fade-out");
		setEmptyMessage("");
		setTimeout(() => {
			simplebarContent && simplebarContent.classList.remove("fade-out");
			setEmptyMessage(isManualyPromoted ? i18next.t("Empty Message.Manualy Promoted") : "");
		}, 500);

		return () => {
			//TODO - Check if it is possible no chatId and delete 'chatID &&' if need
			//fail after leaving user without chatId
			chatID && ChatMetaService.updateTyping(active_userId, chatID, null);
			dispatch(leaveChatMessagesScreen());
		};
	}, [active_userId, simplebarContent]);

	useEffect(() => {
		simplebarContent && (simplebarContent.scrollTop = simplebarContent.scrollHeight);
	}, [chatMessages]);

	useEffect(() => {
		if (chatMessages && chatMessages.length) {
			const lastMessageObject = chatMessages
				?.slice()
				.reverse()
				.find(
					(chatMessage) =>
						!chatMessage?.isPromotion ||
						(chatMessage?.isPromotion &&
							chatMessage?.uid !== loggedInUserProfile?.uid &&
							chatMessages.length === 1)
				);

			if (
				!!lastMessageObject &&
				!lastMessageObject?.isPromotion &&
				!lastMessageObject?.isTemplate
			) {
				console.log(lastMessageObject);
				const isSender = lastMessageObject?.uid === loggedInUserUID;
				const data = { ...lastMessageObject, isSender };

				if (lastMessage?.uid !== lastMessageObject?.uid) {
					console.log(data);

					setLastMessage(() => {
						return data;
					});
				}
			} else {
				setLastMessage(null);
			}
		}
	}, [chatMessages]);

	const _onSend = async (data, isAudio = false) => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();
		if (!isNewChat && !showDeniedRequest && !showRequest) {
			const res = await chatRequestsService.getCanSendMsg(chatID);
			if (!res?.data || !res?.data?.success || !res?.data?.canSend) {
				if (res?.data?.openPopup)
					return showFittedMessagePopup(
						"היי, לא ניתן לשלוח הודעה נוספת לפני שהצד השני מאשר את השיחה.",
						false
					);
				return;
			}
		}
		if (showDeniedRequest || showRequest) {
			setShowDeniedRequest(false);
			await handleBtnClicked(true);
		}

		cancelDeleteChat(active_userId);
		if (canSendMessage) {
			if (isAudio) {
				dispatch(sendChatMessage(chatID, "", active_userId, isNewChat, false, false, "", data));
			} else dispatch(sendChatMessage(chatID, data, active_userId, isNewChat));
			//!isLoggedInUserSubscribed && isCollageSubscribed && showNonSubscriberToSubscriberPopup();
			//!isLoggedInUserSubscribed && !isCollageSubscribed && showNonSubscriberToNonSubscriberPopup();
		} else {
			isUserSubscribed && showSubscriberLimitMessagesPopup();
			!isUserSubscribed && showNonSubscriberLimitMessagesPopup();
		}
	};

	const isShowIcon = (items, chatMessage, index) => {
		if (!canRead && chatMessage.privateBlur) {
			return index === items.findIndex((obj) => obj.privateBlur);
		}
		if (!canRead && chatMessage.generalBlur) {
			return index === items.findIndex((obj) => obj.generalBlur);
		}
		if (chatMessage.isMassletter) {
			return index === items.findIndex((obj) => obj.isMassletter);
		}
		return false;
	};

	useEffect(async () => {
		const res = await chatRequestsService.showChatRequest(chatID);
		if (res?.data?.showRequest) return setShowRequest(true);
		if (res?.data?.isDenied) return setShowDeniedRequest(true);
		setShowDeniedRequest(false);
		return setShowRequest(false);
	}, [chatID]);

	return (
		<>
			<SimpleBar
				autoHide="false"
				style={{ maxHeight: "100%" }}
				className="chat-conversation p-3 p-lg-4"
				id="messages">
				<ul translate="no" className="list-unstyled mb-0">
					{showRequest && (
						<li
							style={{
								background: "#f0eff5",
								padding: "50px",
								display: "flex",
								flexDirection: "column",
								gap: "30px",
								margin: "30px 0",
							}}>
							<div
								className="ctext-wrap-content"
								style={{ textAlign: "center", fontWeight: "bold" }}>
								{`האם לאשר את השיחה עם ${activeUserProfile?.name}?`}
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									gap: "30px",
								}}>
								{!!loading ? (
									<CircularProgress size={40} className="text-primary" />
								) : (
									<>
										<Button
											color="primary"
											onClick={() => {
												handleBtnClicked(true);
											}}>
											<span>{loggedInUserProfile?.gender ? "מאשרת" : "מאשר"}</span>
										</Button>
										<Button
											color="primary"
											onClick={() => {
												handleBtnClicked(false);
											}}>
											<span>{loggedInUserProfile?.gender ? "לא מאשרת" : "לא מאשר"}</span>
										</Button>
									</>
								)}
							</div>
						</li>
					)}
					{chatMessages?.length > 0 ? (
						chatMessages.map((chatMessage, index) => {
							const showIcon = isShowIcon(chatMessages, chatMessage, index);
							return (
								(!chatMessage.isPromotion ||
									(chatMessage.isPromotion &&
										chatMessage.uid !== loggedInUserProfile.uid &&
										chatMessages.length === 1)) && (
									<UserChatItem
										key={chatMessage.id}
										index={index}
										currentMessage={chatMessage}
										chatMessages={chatMessages}
										showIcon={showIcon}
									/>
								)
							);
						})
					) : (
						<EmptyMessage message={emptyMessage} />
					)}

					{!!lastMessage &&
						!!lastMessage?.isSender &&
						!isLoggedInUserSubscribed &&
						!isCollageSubscribed && (
							<>
								<div className="message-unsubscribe-sender">
									{`גם ${loggedInUserProfile?.gender ? "את" : "אתה"} וגם ${activeUserProfile?.name}
								לא מנויי`}
									<b> שליש+, </b>
									{`ולכן  ${activeUserProfile?.name}  
							${i18next.t("Chat.can_" + activeUserProfile?.gender)} לראות ששלחת לו הודעה, אך לא ${i18next.t(
										"Chat.can_" + activeUserProfile?.gender
									)} לקרוא אותה.
							 `}
								</div>
								<div className="clear"></div>
							</>
						)}

					{!!lastMessage &&
						!lastMessage?.isSender &&
						!canRead &&
						!isLoggedInUserSubscribed &&
						!isCollageSubscribed && (
							<>
								<div className="message-unsubscribe-receiver">
									{`${activeUserProfile?.name}  ${i18next.t(
										"Chat.sent_" + activeUserProfile?.gender
									)} לך הודעה אישית.`}{" "}
									<br />
									{i18next.t("Chat.cant_read_" + loggedInUserProfile?.gender)} לקרוא אותה בגלל שאף
									אחד מכם עדיין לא מנוי
									<b> שליש+</b>
								</div>
								<div className="clear"></div>
							</>
						)}
					{isTyping && (
						<li>
							<div className="conversation-list">
								<div className="ctext-wrap-content">
									<Typing />
								</div>
							</div>
						</li>
					)}

					{showDeniedRequest && (
						<li
							style={{
								background: "#f0eff5",
								margin: "30px 0 0",
								padding: "10px",
							}}>
							<div className="ctext-wrap-content">
								{`בחרת שלא לאשר את השיחה עם ${activeUserProfile?.name}, ולכן ${
									activeUserProfile?.gender ? "היא לא תוכל" : "הוא לא יוכל"
								} לשלוח לך הודעות נוספות. `}
							</div>
							<div className="ctext-wrap-content">
								{`בכדי לאפשר ל${activeUserProfile?.name} להמשיך ולשוחח איתך, עליך לשלוח ${
									activeUserProfile?.gender ? "לה" : "לו"
								} הודעה חזרה כאן למטה.`}
							</div>
						</li>
					)}

					<div className="clear"></div>
					{!isUserSubscribed && (
						<BuySubscription
							text={`רוצה שכולם יוכלו לקרוא את ${
								activeUserProfile?.gender ? "הודעתייך" : "הודעתיך"
							}?\n
						רוצה לקבל יותר פניות?`}
							className="message"
							userProfile={loggedInUserProfile}
						/>
					)}
				</ul>
			</SimpleBar>
			<Footer
				key={active_userId}
				chatID={chatID}
				userUid={active_userId}
				gender={loggedInUserProfile?.gender}
				onSend={_onSend}
				user={activeUserProfile}
			/>
		</>
	);
};
