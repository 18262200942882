import "firebase/database";
import httpsCallable from "./httpsCallable";
import firebase from "firebase/app";
import { Observable } from "rxjs";

class SearchFriendsService {
	async search(data) {
		const result = await httpsCallable.call("searchFriends", data);
		return result;
	}
}

export default new SearchFriendsService();
