import { useState, useEffect } from "react";
import { Form, FormGroup, FormFeedback, Input } from "reactstrap";
import VerificationInput from "react-verification-input";

export default ({ onChange, onValidityChange, hasError, setHasError, errMsg, onEnterPressed }) => {
	const [isValid, setIsValid] = useState(false);
	const [value, setValue] = useState("");

	const handleOnSubmit = (e) => {
		e.preventDefault();
		onEnterPressed();
	};

	const handleChange = (val) => {
		setValue(val);
		onChange(val);
		setHasError(false);
		const validLength = val.length === 6;

		if (isValid !== validLength) {
			setIsValid(validLength);
		}
	};

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	return (
		<Form onSubmit={handleOnSubmit} inline>
			<FormGroup>
				<VerificationInput
					value={value}
					length={6}
					validChars="\d"
					autoFocus={true}
					removeDefaultStyles={false}
					classNames={{
						container: "container",
						character: "character",
						characterInactive: "character--inactive",
						characterSelected: "character--selected",
					}}
					onChange={handleChange}
					inputProps={{
						pattern: "[0-9]*",
						inputMode: "numeric",
					}}
				/>
				{hasError && (
					<>
						<Input invalid={true} style={{ display: "none" }} />
						<FormFeedback>{errMsg}</FormFeedback>
					</>
				)}
			</FormGroup>
		</Form>
	);
};
