// import "assets/scss/popups/testModal.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect, useState } from "react";
import hugeImg from "assets/images/popups/test/huge.png";
import featurePreview from "assets/images/popups/test/feature-preview.png";

export default function TestModal() {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog">
			<div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div>

			<ModalBody className="">
				<div class="popup-container">
					<div class="we-upgraded">
						<h2>השתדרגנו</h2>
						<img src={hugeImg} alt="בענק!" />
					</div>
					<p class="old-ver">נראה שאתה גולש בגרסה ישנה :(</p>
					<p class="new-ver"> הגרסה החדשה מחכה לך והיא פשוט מושלמת!</p>
					<p class="percent-move">מעל 80% כבר התקדמו</p>
					<p class="more">
						צ׳אט קולי, חברים משותפים, חיפוש חכם, אישורי קריאה, וידאו דייטינג מידי שבוע ועוד..
					</p>
					<button class="upgrade">שדרג בלחיצה כפתור אחת!</button>
					<img src={featurePreview} alt="תצוגה מקדימה של הגרסה החדשה" />
				</div>
			</ModalBody>
		</Modal>
	);
}
