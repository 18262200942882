import { useDispatch, useSelector } from "react-redux";

import { closePopupUnderPopup, closeSecondPopupUnderPopup, showPopup } from "redux/popups/actions";
import PopupsTypes from "consts/popupsTypes";
import { updateQRCodeHeaderText } from "redux/qrcode/actions";
import useGalleryModal from "./useGalleryModal";
import { getPopups } from "redux/popups/selectors";

const usePopups = () => {
	const dispatch = useDispatch();

	const { currentUserPromotedProfile } = useSelector(getPopups);

	const { setActiveIndex, setActiveIndexMainPicture, setGalleryType } = useGalleryModal();

	const closePopup = () => {
		dispatch(showPopup(PopupsTypes.None));
	};

	const _closePopupUnderPopup = () => dispatch(closePopupUnderPopup());

	const _closeSecondPopupUnderPopup = () => dispatch(closeSecondPopupUnderPopup());

	const showPopupHandler = (popupType, infoObj) => dispatch(showPopup(popupType, infoObj));

	const showUnmatchUserPopup = (user) =>
		showPopupHandler(PopupsTypes.UnmatchUser, { currentUserProfile: user });

	const showBlockUserPopup = (user) =>
		showPopupHandler(
			PopupsTypes.BlockUser,
			currentUserPromotedProfile
				? { currentUserPromotedProfile: user }
				: { currentUserProfile: user }
		);

	const showReportUserPopup = (user) =>
		showPopupHandler(
			PopupsTypes.ReportUser,
			user
				? currentUserPromotedProfile
					? { currentUserPromotedProfile: user }
					: { currentUserProfile: user }
				: {}
		);

	const showReportQuestionPopup = () => showPopupHandler(PopupsTypes.QuestionReport);

	const showUserSuccessfullyBlockedPopup = () =>
		showPopupHandler(PopupsTypes.UserSuccessfullyBlocked);

	const showThanksReportUserPopup = () => showPopupHandler(PopupsTypes.ThanksReportUser);

	const showUpdatesSavedSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.UpdatesSavedSuccessfully);

	const showContactFormSentSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.ContactFormSentSuccessfully);

	const showFreezeAccountPopup = () => showPopupHandler(PopupsTypes.FreezeAccountPopup);

	const showFreezeAccountSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.FreezeAccountSuccessfullyPopup);

	const showDeleteAccountSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.DeleteAccountSuccessfullyPopup);

	const showDeleteAccountPopup = () => showPopupHandler(PopupsTypes.DeleteAccountPopup);

	const showDeleteAccountSelectReasonPopup = () =>
		showPopupHandler(PopupsTypes.DeleteAccountSelectReasonPopup);

	const showDeleteAccountAddMessagePopup = (infoObj) =>
		showPopupHandler(PopupsTypes.DeleteAccountAddMessagePopup, infoObj);

	const showLanguageSwitchPopup = () => showPopupHandler(PopupsTypes.LanguageSwitchPopup);

	const showDeleteImagePopup = (key) =>
		showPopupHandler(PopupsTypes.DeleteImagePopup, { imageKey: key });

	const showCropImagePopup = (infoObj) => showPopupHandler(PopupsTypes.CropImagePopup, infoObj);

	const showUserProfileSideBar = (user) => {
		showPopupHandler(PopupsTypes.UserProfileSideBar, {
			currentUserProfile: user,
			popupUnderPopup: true,
		});
	};
	const showUserProfileSidebarModal = (user) => {
		showPopupHandler(PopupsTypes.UserProfileSidebarModal, {
			currentUserProfile: user,
			popupUnderPopup: true,
		});
	};

	const showUserCardPopup = (user, secondPopupUnderPopup = false) => {
		secondPopupUnderPopup
			? showPopupHandler(PopupsTypes.CardUserModal, {
					currentUserPromotedProfile: user,
					secondPopupUnderPopup: true,
			  })
			: showPopupHandler(PopupsTypes.CardUserModal, {
					currentUserProfile: user,
					popupUnderPopup: true,
			  });
	};

	const showQrcodePopup = (text) => {
		dispatch(updateQRCodeHeaderText(text));
		showPopupHandler(PopupsTypes.QRCode);
	};

	const showGalleryPopup = (galleryType, index) => {
		typeof index === "number" ? setActiveIndex(index) : setActiveIndexMainPicture(galleryType);
		dispatch(setGalleryType(galleryType));
		showPopupHandler(PopupsTypes.Gallery);
	};

	const showSubscriptionPopup = (
		limitSource,
		colleagueName,
		isSale,
		cardcomClearing = {},
		tokenWithoutReturn = false
	) => {
		return showPopupHandler(PopupsTypes.SubscriptionModal, {
			limitSource,
			colleagueName,
			isSale,
			cardcomClearing,
			tokenWithoutReturn,
		});
	};
	const showSaleSubscriptionPopup = (limitSource, colleagueName, isSale, cardcomClearing = {}) => {
		return showPopupHandler(PopupsTypes.SaleSubscriptionModal, {
			limitSource,
			colleagueName,
			isSale,
			cardcomClearing,
		});
	};

	const showSubscriptionDealPopup = (limitSource, colleagueName, isSale, cardcomClearing = {}) => {
		return showPopupHandler(PopupsTypes.SubscriptionDealModal, {
			limitSource,
			colleagueName,
			isSale,
			cardcomClearing,
		});
	};
	const showPromotionsSalePopup = (promotionSalePlan = null, openCardcom = false) => {
		return showPopupHandler(PopupsTypes.PromotionsSaleModal, {
			promotionSalePlan,
			openCardcom,
		});
	};

	const showRateUsPopup = () => showPopupHandler(PopupsTypes.RateUs);

	const showSubscriptionSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.SubscriptionSuccessfullyPopup);

	const showUnblockQuestionPopup = (unblockUserProfile) =>
		showPopupHandler(PopupsTypes.UnblockUserPopup, { unblockUserProfile });

	const showDeleteLikedMeUsersPopup = (infoObj) =>
		showPopupHandler(PopupsTypes.DeleteLikedMeUsersPopup, infoObj);

	const showDeleteChatsPopup = (infoObj) => showPopupHandler(PopupsTypes.DeleteChatsPopup, infoObj);

	const showMassletterPopup = () => {
		showPopupHandler(PopupsTypes.MassletterPopup);
	};

	const showMassletterSentPopup = () => {
		showPopupHandler(PopupsTypes.MassletterSentPopup);
	};

	const showChatRecievedFilterPopup = () => {
		showPopupHandler(PopupsTypes.ChatReceivedFilterPopup);
	};

	const showSendMessagePopup = (user) =>
		showPopupHandler(PopupsTypes.SendMessagePopup, {
			currentUserProfile: user,
		});

	const showSendTemplateMessagePopup = (user, isFromCardUserSidebar = false) =>
		showPopupHandler(PopupsTypes.SendTemplateMessagePopup, {
			currentUserProfile: user,
			isFromCardUserSidebar,
		});

	const showBlurMessagePopup = () => showPopupHandler(PopupsTypes.BlurMessagePopup);

	const showNonSubscriberToSubscriberPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberToSubscriberPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showNonSubscriberToNonSubscriberPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberToNonSubscriberPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showMessageSuccessfullySentPopup = () =>
		showPopupHandler(PopupsTypes.MessageSuccessfullySentPopup);

	const showTagAddedSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.TagAddedSuccessfullyPopup);

	const showNotApprovedMessagePopup = () => showPopupHandler(PopupsTypes.NotApprovedMessagePopup);

	const showContactFromFeedbackButtonPopup = () =>
		showPopupHandler(PopupsTypes.ContactFromFeedbackButtonPopup);

	const showSubscriberLimitMessagesPopup = (user) =>
		showPopupHandler(
			PopupsTypes.SubscriberLimitMessagesPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showNonSubscriberLimitMessagesPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberLimitMessagesPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showNonSubscriberCantSendMessagesPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberCantSendMessagesPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);
	const showCanSendMsgAgePopup = (user, isLike = false) => {
		showPopupHandler(
			PopupsTypes.NonSubscriberCantSendMessageAgePopup,
			user ? { user, isLike } : { isLike }
		);
	};

	const showUnsubscribeSuccessfullySavedPopup = () =>
		showPopupHandler(PopupsTypes.UnsubscribeSuccessfullySavedPopup);

	const showUnsubscribeErrorPopup = () => showPopupHandler(PopupsTypes.UnsubscribeErrorPopup);

	const showUnsubscribeCancelPopup = () => showPopupHandler(PopupsTypes.UnsubscribeCancelPopup);

	const showBigImagePopup = () => showPopupHandler(PopupsTypes.BigImage);

	const showLowQualityImage = () => showPopupHandler(PopupsTypes.LowQualityImage);

	const showMobileStorePopup = (data) =>
		showPopupHandler(PopupsTypes.MobileStoreModal, {
			data,
		});

	const showShareLocationsMobile = () => showPopupHandler(PopupsTypes.ShareLocationsMobile);

	const showVerifiedEmailPopup = () => showPopupHandler(PopupsTypes.VerifiedEmail);
	const showQuotaPopup = () => showPopupHandler(PopupsTypes.QuotaIsOver);
	const showEnteredMatchPopup = (data) => showPopupHandler(PopupsTypes.EnteredMatchPopup, { data });
	const showApproveShowInTelegramPopup = () =>
		showPopupHandler(PopupsTypes.ApproveShowInTelegramPopup);

	const showPhoneNotificationSettingsPopup = () =>
		showPopupHandler(PopupsTypes.PhoneNotificationSettings);

	const showDynamicPopup = (data) => showPopupHandler(PopupsTypes.DynamicModal, { data });
	const showCancelVideoDateRegisterPopup = (data) =>
		showPopupHandler(PopupsTypes.CancelVideoDateRegister, { data });

	const showReportVideoDatePopup = (data) =>
		showPopupHandler(PopupsTypes.ReportVideoDate, { data });

	const showBuyWithTokenPopup = (data) => showPopupHandler(PopupsTypes.BuyWithToken, { data });
	const showLogoutPopup = () => showPopupHandler(PopupsTypes.LogoutPopup);

	const showLoginPopup = (funcAfterLogin) =>
		showPopupHandler(PopupsTypes.LoginPopup, {
			funcAfterLogin: funcAfterLogin ? funcAfterLogin : () => {},
		});

	const showSubscriptionForSpeedDatePopup = () =>
		showPopupHandler(PopupsTypes.SubscriptionForSpeedDatePopup);

	const showFittedMessagePopup = (text, close = true, textBtn = "", textBody = false) =>
		showPopupHandler(PopupsTypes.FittedMessagePopup, {
			text,
			close,
			textBtn,
			textBody,
		});

	const showDeleteCommentPopup = (artKey, commentKey, gender, commentUid) =>
		showPopupHandler(PopupsTypes.DeleteCommentPopup, {
			artKey,
			commentKey,
			gender,
			commentUid,
		});

	const showExplanationEffortPopup = (user) =>
		showPopupHandler(PopupsTypes.ExplanationEffort, {
			user,
		});

	const showEffortSuccessfullyPopup = (title) =>
		showPopupHandler(PopupsTypes.EffortSuccessfully, {
			title,
		});
	const showJaaSMeetingPopup = (data) =>
		showPopupHandler(PopupsTypes.JaaSMeeting, {
			data,
		});

	const showForceUploadImagePopup = (data) =>
		showPopupHandler(PopupsTypes.ForceUploadImage, {
			data,
		});

	const showForceShareFriends = (data) =>
		showPopupHandler(PopupsTypes.ForceShareFriends, {
			data,
		});
	const showBroadcastChanel = () =>
		showPopupHandler(PopupsTypes.BroadcastChanel, {
			secondPopupUnderPopup: true,
		});

	const showReceivedGiftCardPopup = (data) =>
		showPopupHandler(PopupsTypes.ReceivedGiftCardModal, { data });

	const showFeedbackUserPopup = (data) => showPopupHandler(PopupsTypes.FeedbackUserModal, { data });

	const showUserQuestionsPopup = () => showPopupHandler(PopupsTypes.QuestionsUserModal);
	const showTestPopup = () => showPopupHandler(PopupsTypes.TestModal);

	const showAboutMeAiPopup = () => showPopupHandler(PopupsTypes.AboutMeAiModal);

	const showMatchBetweenUsersAiPopup = (uidPartner) =>
		showPopupHandler(PopupsTypes.MatchBetweenUsersAiModal, { uidPartner });

	const showApproveAiPopup = () => showPopupHandler(PopupsTypes.ApproveAiModal);

	return {
		showForceShareFriends,
		showJaaSMeetingPopup,
		showLoginPopup,
		showFittedMessagePopup,
		showUnmatchUserPopup,
		showBlockUserPopup,
		showReportUserPopup,
		showReportQuestionPopup,
		showUserSuccessfullyBlockedPopup,
		showThanksReportUserPopup,
		showUpdatesSavedSuccessfullyPopup,
		showQrcodePopup,
		showGalleryPopup,
		showContactFormSentSuccessfullyPopup,
		showFreezeAccountPopup,
		showFreezeAccountSuccessfullyPopup,
		showDeleteAccountPopup,
		showDeleteAccountSelectReasonPopup,
		showDeleteAccountAddMessagePopup,
		showDeleteAccountSuccessfullyPopup,
		showLanguageSwitchPopup,
		showDeleteImagePopup,
		showCropImagePopup,
		showUserCardPopup,
		_closePopupUnderPopup,
		_closeSecondPopupUnderPopup,
		showUserProfileSideBar,
		showUserProfileSidebarModal,
		showSubscriptionPopup,
		showSubscriptionDealPopup,
		showSubscriptionSuccessfullyPopup,
		showUnblockQuestionPopup,
		showDeleteLikedMeUsersPopup,
		showDeleteChatsPopup,
		showMassletterPopup,
		showMassletterSentPopup,
		showChatRecievedFilterPopup,
		showSendMessagePopup,
		showSendTemplateMessagePopup,
		showBlurMessagePopup,
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showNotApprovedMessagePopup,
		showMessageSuccessfullySentPopup,
		showSubscriberLimitMessagesPopup,
		showNonSubscriberLimitMessagesPopup,
		showNonSubscriberCantSendMessagesPopup,
		showCanSendMsgAgePopup,
		showTagAddedSuccessfullyPopup,
		showContactFromFeedbackButtonPopup,
		showUnsubscribeSuccessfullySavedPopup,
		showUnsubscribeErrorPopup,
		showUnsubscribeCancelPopup,
		showPopupHandler,
		showBigImagePopup,
		showLowQualityImage,
		showMobileStorePopup,
		showVerifiedEmailPopup,
		showExplanationEffortPopup,
		closePopup,
		showEffortSuccessfullyPopup,
		showForceUploadImagePopup,
		showShareLocationsMobile,
		showDeleteCommentPopup,
		showQuotaPopup,
		showEnteredMatchPopup,
		showDynamicPopup,
		showBuyWithTokenPopup,
		showCancelVideoDateRegisterPopup,
		showLogoutPopup,
		showReportVideoDatePopup,
		showBroadcastChanel,
		showPhoneNotificationSettingsPopup,
		showSaleSubscriptionPopup,
		showApproveShowInTelegramPopup,
		showPromotionsSalePopup,
		showRateUsPopup,
		showReceivedGiftCardPopup,
		showSubscriptionForSpeedDatePopup,
		showFeedbackUserPopup,
		showUserQuestionsPopup,
		showTestPopup,
		showAboutMeAiPopup,
		showMatchBetweenUsersAiPopup,
		showApproveAiPopup,
	};
};

export default usePopups;
