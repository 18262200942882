import { useSelector } from "react-redux";
import i18next from "i18next";
import { Link } from "react-router-dom";

import { geIsPicturesPrivate, getUserProfile } from "redux/profile/selectors";
import { getAgeByDob } from "helpers/date";
import ProfileFields from "consts/profileFields";
import currentLanguage from "helpers/currentLanguage";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";

/* */
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import giftImg from "assets/images/giftCard/gift.png";
import youGetGift from "assets/images/giftCard/youGetGift.png";
import { useEffect, useState } from "react";
import userNotificationsService from "services/unreadNotification.service";
import usePopups from "hooks/usePopups";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { getPercentUser } from "redux/questions/selectors";
import editImg from "assets/images/questions/edit.png";
import useNavigator from "hooks/useNavigator";
import getUserProgress from "helpers/getUserProgress";
import userProgressService from "services/userProgressService";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export default () => {
	// const percentQuestionsUser = useSelector(getPercentUser);
	const {
		showReceivedGiftCardPopup,
		showAboutMeAiPopup,
		showFittedMessagePopup,
		showApproveAiPopup,
	} = usePopups();
	const userProfile = useSelector(getUserProfile);
	const gender = userProfile.gender;
	const city = userProfile[ProfileFields.Cites[currentLanguage()]];
	const mainPictureUri = userProfile.pictures?.[userProfile.mainPictureName]?.url || "";

	/* */
	const showJoinNowBtn = useShowJoinNowBtn();
	const isPicturesPrivate = useSelector(geIsPicturesPrivate);
	const [showGiftIcon, setShowGiftIcon] = useState(false);
	const [gift, setGift] = useState({});
	const [percentQuestionsUser, setPercentQuestionsUser] = useState(0);
	const [percent, setPercent] = useState(0);

	const textFittedHeader = (
		<h2 style={{ fontWeight: "bold" }}>{`רגע רגע... ה-AI שלנו עדיין לא מכיר אותך מספיק! 🤖✨`}</h2>
	);
	const textFittedBody = (
		<div>
			<div>{`נראה שעדיין לא השלמת לפחות 80% מהפרופיל שלך. ככל שנדע עליך יותר, נוכל לתת לך ניתוח מדויק יותר (והרבה יותר מגניב!).`}</div>

			<div>{`📝 מלא עוד קצת פרטים ותחזור אלינו – ה-AI כבר מחכה להכיר אותך! 🚀`}</div>
		</div>
	);

	const handleClickAboutMeAi = () => {
		if (!userProfile?.approveAi) return showApproveAiPopup();
		if (percentQuestionsUser >= 80) {
			return showAboutMeAiPopup();
		} else {
			return showFittedMessagePopup(
				textFittedHeader,
				false,
				"למעבר לפרופיל שלך >>",
				textFittedBody
			);
		}
	};

	const handleClickGift = () => {
		if (gift) showReceivedGiftCardPopup(gift);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setPercent((prev) => {
				if (prev >= percentQuestionsUser) {
					clearInterval(interval);
					return percentQuestionsUser;
				}
				return Math.min(prev + 1, percentQuestionsUser);
			});
		}, 20);
		return () => clearInterval(interval);
	}, [percentQuestionsUser]);

	useEffect(async () => {
		if (!userProfile?.uid) return;
		const isUserHaveGift = await userNotificationsService.getIsUserHaveGift(userProfile.uid);
		setShowGiftIcon(!!isUserHaveGift.data?.success);
		if (!!isUserHaveGift.data?.gift) setGift(isUserHaveGift.data?.gift);
	}, [userProfile?.uid]);

	useEffect(async () => {
		if (!userProfile?.uid) return;
		const userProgressNum = getUserProgress(userProfile);
		setPercentQuestionsUser(Math.min(100, userProgressNum));

		if (userProfile?.progressAccount == undefined || userProfile?.progressImages == undefined) {
			await userProgressService.checkUpdateUserProgressValues();
		}
	}, [userProfile?.progressAccount, userProfile?.progressImages, userProfile?.progressQuestions]);

	return (
		<div className="userEdit">
			<div className="personalDetails">
				<div>
					<span color="primary" className="font-size-22 mb-1 text-truncate jsmartProfileName">
						{userProfile.name}
					</span>
					,{" "}
					<span color="primary" className="font-size-22 mb-1 text-truncate jsmartProfileName">
						{getAgeByDob(userProfile.dob)}
					</span>
				</div>
				<div className="detailsProfile">
					<p color="primary">{i18next.t(`Stance_${gender || 0}.${userProfile.stance}`)} </p>
					<p color="primary">{city && " | " + city}</p>
				</div>

				<span className="BtnAiAboutMe" onClick={handleClickAboutMeAi}>
					<Button className="spanAi">{"מה הAi שלנו חושב עליך"}</Button>
					<AutoAwesomeIcon className="stars" />
				</span>

				{showGiftIcon && (
					<div
						onClick={handleClickGift}
						style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<img style={{ width: "15px", height: "15px" }} src={giftImg} />
						<img style={{ height: "24px" }} src={youGetGift} />
					</div>
				)}
			</div>

			{/* <div
				className={`mainUserImage ${!showJoinNowBtn ? "hasSubscription2" : ""} ${
					isPicturesPrivate ? "hasPrivatePictures" : ""
				}`}>
				<Link Link to="/account/pictures">
					<img
						src={
							mainPictureUri ? mainPictureUri : gender ? placeholderWomanRound : placeholderManRound
						}
					/>
				</Link>
			</div> */}
			<div className={`mainUserImage `}>
				<Box className="wrapPercentUser">
					<GradientCircularProgress targetValue={100} isGray={true} userProfile={userProfile} />
					<GradientCircularProgress
						realVal={percentQuestionsUser}
						targetValue={(percent * 90) / 100}
						userProfile={userProfile}
					/>
					<Link Link to="/account/pictures">
						<img
							src={
								mainPictureUri
									? mainPictureUri
									: gender
									? placeholderWomanRound
									: placeholderManRound
							}
						/>
					</Link>
					<Stack className="completionStack">
						<Typography className="completionText" variant="body2">
							{percent}% הושלמו
						</Typography>
					</Stack>
				</Box>
			</div>
		</div>
	);
};

const GradientCircularProgress = ({
	targetValue = 0,
	isGray = false,
	userProfile,
	realVal = 0,
}) => {
	const percentQuestionsUser = useSelector(getPercentUser);
	const {
		navigateToAccountPic,
		navigateToAccountEditProfile,
		navigateToMatchQuestion,
		navigateToAccountQuestions,
	} = useNavigator();
	const [value, setValue] = useState(0);
	const gradientId = isGray ? "gradient-gray" : "gradient";

	const gradientColors = isGray ? ["#c2c2c2", "#c2c2c2"] : ["#2d5086", "#0bc1c7"];

	const handleEditClick = () => {
		const {
			progressAccount = 0,
			progressImages = 0,
			progressQuestions = 0,
			aboutSpouse,
		} = userProfile;

		if (!aboutSpouse) navigateToAccountEditProfile();
		else if (progressImages <= 70) navigateToAccountPic(); // 1 pic
		else if (progressAccount !== 100) navigateToAccountEditProfile();
		else if (percentQuestionsUser !== 100) navigateToMatchQuestion();
		else if (percentQuestionsUser === 100 && progressQuestions !== 100)
			navigateToAccountQuestions();
		else if (progressImages < 100) navigateToAccountPic(); // 1 pic
		else navigateToAccountEditProfile();
	};

	useEffect(() => setValue(targetValue), [targetValue]);

	return (
		<Box className="circulPercent" position="relative" width={110} height={110}>
			<svg width="0" height="0">
				<defs>
					<linearGradient id={gradientId} x1="0%" y1="0%" x2="50%" y2="100%">
						<stop offset="10%" stopColor={gradientColors[0]} />
						<stop offset="100%" stopColor={gradientColors[1]} />
					</linearGradient>
				</defs>
			</svg>

			{!isGray && !(realVal === 100) && (
				<Box onClick={handleEditClick} className="editIcon">
					<img src={editImg} alt="edit" className="edit" />
				</Box>
			)}

			<CircularProgress
				variant="determinate"
				value={value}
				size={95}
				thickness={2}
				sx={{
					transform: "rotate(90deg) !important",
					"& .MuiCircularProgress-circle": {
						stroke: `url(#${gradientId})`,
					},
				}}
			/>
		</Box>
	);
};
