import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useState } from "react";
import moment from "moment";
import speedDatingService from "services/speedDatingService";
import usePopups from "hooks/usePopups";
import speedDateSubscriptionTypes from "consts/speedDateSubscriptionTypes";
import speedDateStatus from "consts/speedDateStatus";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { getHasSubscription } from "redux/subscription/selectors";

export default function VideoRegister({ speedData = {}, userData = {} }) {
	const { showSubscriptionForSpeedDatePopup, showFittedMessagePopup } = usePopups();
	const { gender } = useSelector(getUserProfile);
	const [loading, setLoading] = useState(false);
	const hasSubscription = useSelector(getHasSubscription);

	const registerToVideo = async () => {
		// if (
		// 	!gender &&
		// 	speedData?.hasSubScriptionTime &&
		// 	moment().isBefore(moment(speedData?.endSubUsersRegister)) &&
		// 	!hasSubscription
		// ) {
		// 	showSubscriptionForSpeedDatePopup();
		// 	return setLoading(false);
		// }

		let data;
		// if (userData?.uid && userData?.status === "canceled") {
		// 	data = await speedDatingService.confirmUserSpeedDating(speedData?.key);
		// } else {
		data = await speedDatingService.registerUserToSpeedDating(speedData?.key);
		// }

		if (!data?.data?.success && data?.data.msg === "max users") {
			const text = (
				<div>
					<b>{`האירוע התמלא מהר משחשבנו...😮`}</b>
					<div>{` ככה שלצערנו, הפעם לא נותרו מקומות פנויים 🤷‍♀️`}</div>
					<div>{` אבל אל דאגה... מתוכננים עוד המון אירועים בהמשך!`}</div>
					<div>{` מוזמנים לעקוב ❤️`}</div>
				</div>
			);

			showFittedMessagePopup(text, false);
		}
		if (!data?.data?.success && data?.data.msg === "user blocked") {
			const text = (
				<div>
					<b>{`מאחר ולא הופעת לאירוע וידאו דייט שהתקיים, לא ${
						gender ? "תוכלי" : "תוכל"
					} להירשם שוב.`}</b>
				</div>
			);

			showFittedMessagePopup(text, false);
		}
		if (!data?.data?.success && data?.data.msg === "user not have subscription") {
			showSubscriptionForSpeedDatePopup();
		}

		if (data?.data?.success) {
			if (!!speedData?.price && speedData?.subscriptionType === speedDateSubscriptionTypes.paid) {
				//open pay popup
			}
		}
		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		registerToVideo();
	};

	return (
		<>
			<h3 className="title">{i18next.t("speedDate.howExciting")}</h3>

			<Box className="content">
				<Box className="nearestEvent">
					<span>{i18next.t("speedDate.event")}</span>
					<Box className="calligraffitti-regular">{i18next.t("speedDate.videoDatingEn")}</Box>
					<span>{i18next.t("speedDate.close")}</span>
				</Box>
				<Box className="nearestEvent">{i18next.t("speedDate.heldIn")}</Box>
				<Box className="bold">{moment(speedData?.eventDate).format("D/M/YY בשעה HH:mm")}</Box>
			</Box>
			<Box className="footer">
				{speedData?.status !== speedDateStatus.start ||
				(speedData?.endUsersRegister && moment().isAfter(moment(speedData?.endUsersRegister))) ? (
					<Box>{i18next.t(`speedDate.registrationClosed`)}</Box>
				) : (
					<FooterBtn
						handleClick={handleClick}
						text={i18next.t("speedDate.registerForEvent")}
						loading={loading}
					/>
				)}
			</Box>
		</>
	);
}
