import React from "react";
import { useHistory } from "react-router-dom";

const useNavigator = () => {
	const history = useHistory();

	const redirectToPath = (path, paramsObj) => {
		history.push({
			pathname: path,
			state: paramsObj,
		});
	};

	const redirectWithReplaceTo = (path, paramsObj) => {
		history.replace({
			pathname: path,
			state: paramsObj,
		});
	};

	const redirectWithRefreshTo = (path) => {
		window.location = path;
	};

	const redirectWithReload = (path) => {
		redirectToPath("/empty");
		setTimeout(() => {
			redirectToPath(path);
		}, 1);
	};
	const redirectToPreviousPath = () => {
		history.goBack();
	};

	const navigateToSuccessfullyPayVideoPage = (paramsObj) =>
		redirectToPath(`/successfullyPaySpeedDating`);
	const navigateToPayVideoPage = (paramsObj) => redirectToPath(`/paySpeedDating`, paramsObj);
	const navigateToAlternativeLogin = (paramsObj) => redirectToPath(`/alternativeLogin`, paramsObj);
	const navigateToAccountVideoDate = (paramsObj) => redirectToPath(`/account/videoDate`, paramsObj);
	const navigateToAccountQuestions = (toSkip = false) =>
		redirectToPath(`/account/questions`, { toSkip });
	const navigateToSearchPage = (paramsObj) => redirectToPath("/search", paramsObj);
	const navigateToMatchesPage = (paramsObj) => redirectToPath("/matches", paramsObj);
	const navigateToLoginEnterPhonePage = () => redirectToPath("/login/phone");
	const navigateToLoginPhoneVerificationPage = () => redirectToPath("/login/phoneverify");
	const navigateToLogoutPage = () => redirectToPath("/logout");
	const navigateToLoginQuestionsPage = (paramsObj) => redirectToPath("/login/questions", paramsObj);
	const navigateToPullDataFromD4d = () => redirectToPath("/pullDataFromD4d");
	const navigateToHomePage = () => redirectToPath("/");
	const redirectToHomePage = () => redirectWithRefreshTo("/");
	const redirectToPhoneWithRefresh = () => redirectWithRefreshTo("/login/phone");
	const navigateToMassagesReceived = () => redirectToPath("/messages/received");
	const navigateToMassagesSent = (paramsObj) => redirectToPath("/messages/sent", paramsObj);
	const navigateToMassagesFiltered = () => redirectToPath("/messages/filtered");
	const navigateToPreviousPage = () => redirectToPreviousPath();
	const navigateToMaintenancePage = () => redirectToPath("/maintenance");
	const navigateToForceAppDownloadPage = () => redirectToPath("/forcedownload");
	const navigateToProfileBlockedPage = (isDeleted = false) =>
		redirectWithReplaceTo("/ProfileBlocked", { isDeleted });
	const navigateToMatchQuestion = () => redirectToPath("/matchQuestion");
	const navigateToAccountEditProfile = () => redirectToPath("/account/editProfile");
	const navigateToAccountPic = () => redirectToPath("/account/pictures");
	const navigateToAccountPage = () => redirectToPath("/account");
	const navigateToSoon = () => redirectToPath("/soon");
	const navigateToThanksGiftCard = (obj) =>
		redirectToPath(`/thanksGiftCard/${obj?.keyGiftCard}`, obj);

	const navigateToReceivedGiftCard = (obj) =>
		redirectToPath(`/received_giftcard/${obj?.keyGiftCard}`, obj);

	const navigateToPath = (path) => redirectToPath(path);

	return {
		redirectToPhoneWithRefresh,
		navigateToSoon,
		navigateToPullDataFromD4d,
		navigateToAccountPic,
		redirectWithReload,
		navigateToPath,
		navigateToSearchPage,
		navigateToMatchesPage,
		navigateToLoginEnterPhonePage,
		navigateToLoginPhoneVerificationPage,
		navigateToLogoutPage,
		navigateToLoginQuestionsPage,
		navigateToHomePage,
		redirectToHomePage,
		navigateToMassagesReceived,
		navigateToMassagesSent,
		navigateToMassagesFiltered,
		navigateToPreviousPage,
		navigateToMaintenancePage,
		navigateToForceAppDownloadPage,
		navigateToProfileBlockedPage,
		redirectWithRefreshTo,
		navigateToAccountVideoDate,
		navigateToAccountQuestions,
		navigateToAlternativeLogin,
		navigateToPayVideoPage,
		navigateToSuccessfullyPayVideoPage,
		navigateToAccountPage,
		navigateToThanksGiftCard,
		navigateToReceivedGiftCard,
		navigateToAccountEditProfile,
		navigateToMatchQuestion,
	};
};

export default useNavigator;
