import "assets/scss/custom/pages/accountVideoBox.scss";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getUserProfile } from "redux/profile/selectors";
import speedDatingService from "services/speedDatingService";
import speedDateStatus from "consts/speedDateStatus";
import date from "assets/images/date.png";
import AccountVideoByStatus from "./innerAccountVideo/AccountVideoByStatus";
import ExplainDrawer from "./innerAccountVideo/ExplainDrawer";
import useNavigator from "hooks/useNavigator";
import { CircularProgress } from "@mui/material";
import DropDownVideoList from "./DropDownVideoList";

export default function AccountVideoBox({ sdUrl = "" }) {
	const location = useLocation();
	const currentUser = useSelector(getUserProfile) || {};
	const [speedData, setSpeedData] = useState({});
	const [allUserEvents, setAllUserEvents] = useState([]);
	const [noSpeedData, setNoSpeedDate] = useState(false);
	const [userData, setUserData] = useState({});
	const [loadingUser, setLoadingUser] = useState(true);
	const handleNoSpeedData = () => setNoSpeedDate(true);
	const { navigateToPath } = useNavigator();
	const handleSpeedDb = (sData) => {
		if (!sData) return handleNoSpeedData();
		setSpeedData(sData);
	};

	useEffect(() => {
		if (!currentUser?.uid) return;
		let sData = {};

		if (!location.state?.urlName && !sdUrl) {
			speedDatingService.getNextSpeedDate().then(async (data) => {
				const events = Object.values(data || {});
				let sData = null;

				for (const ev of events) {
					let canRegister = speedDatingService.checkIfUserCanRegisterToEventByFilters(
						ev,
						currentUser
					);

					if (!canRegister) {
						const userInSpeedDate = await speedDatingService.getUserInSpeedDate(
							ev?.key,
							currentUser?.uid
						);
						setUserData(userInSpeedDate);
						setLoadingUser(false);

						if (userInSpeedDate) canRegister = true;
					}

					if (ev?.status !== speedDateStatus.canceled && canRegister) {
						sData = ev;
						break;
					}
				}

				handleSpeedDb(sData);
			});
		} else {
			speedDatingService.getSpeedDateInfoByUrlName(sdUrl || location.state.urlName).then((data) => {
				sData = Object.values(data || {})[0];
				handleSpeedDb(sData);
			});
		}
	}, [currentUser?.uid]);

	useEffect(() => {
		if (location.state?.urlName) {
			navigateToPath("", { state: null });
		}
	}, []);
	useEffect(() => {
		if (!currentUser?.uid) return;
		speedDatingService.getUserEvents(currentUser?.uid).then((data) => {
			const promises = [];
			Object.keys(data || {}).map((key) => {
				promises.push(speedDatingService.getSpeedDateInfo(key));
			});
			Promise.all(promises).then((res) => {
				const filterRes = res.filter((speedy) => !!speedy);
				setAllUserEvents(filterRes);
			});
		});
	}, [currentUser?.uid]);

	useEffect(() => {
		if (!speedData?.key || !currentUser?.uid) return;
		if (!loadingUser) setLoadingUser(true);

		const subscription = speedDatingService
			.listenToUserInSpeedDate$(speedData.key, currentUser.uid)
			.subscribe((userData) => {
				setUserData(userData);
				setLoadingUser(false);
			});
		return () => {
			subscription?.unsubscribe();
		};
	}, [speedData?.key, currentUser?.uid]);

	return (
		<>
			{!!allUserEvents?.length && (
				<DropDownVideoList
					speedData={speedData}
					allUserEvents={allUserEvents}
					setSpeedData={setSpeedData}
				/>
			)}

			<Box className={"accountVideoBox"}>
				{noSpeedData && <AccountVideoByStatus noSpeedData={noSpeedData} />}
				{!noSpeedData && !speedData?.key && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100px",
						}}>
						<CircularProgress style={{ color: "#fff" }} />
					</Box>
				)}

				{speedData?.key && !loadingUser && (
					<AccountVideoByStatus userData={userData} speedData={speedData} />
				)}

				<Box className="imgDate">
					<img src={date} alt="date" />
				</Box>
			</Box>
			<ExplainDrawer speedData={speedData} />
		</>
	);
}
