import React, { useState } from "react";
import {
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Slider,
	Box,
	Typography,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	CircularProgress,
} from "@mui/material";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import searchFriendsService from "services/searchFriendsService";

const UserSearchForm = () => {
	const { navigateToAccountPage } = useNavigator();
	const [loading, setLoading] = useState(false);
	const [optionalFriends, setOptionalFriends] = useState([]);
	const [filters, setFilters] = useState({
		phone: "",
		age: 25,
		gender: 0,
		stance: 5,
		from_age: 18,
		to_age: 40,
		from_height: 150,
		to_height: 200,
		hasChildren: false,
	});

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFilters({ ...filters, [name]: type === "checkbox" ? checked : value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		console.log("Filters submitted:", filters);
		const res = await searchFriendsService.search(filters);
		if (res.data?.success) {
			setOptionalFriends(res.data?.items);
		}
		setLoading(false);
	};

	const handleReturnToSite = () => {
		navigateToAccountPage();
	};

	return (
		<Box
			sx={{
				maxWidth: 400,
				mx: "auto",
				mt: 5,
				p: 3,
				boxShadow: 3,
				borderRadius: 2,
				bgcolor: "white",
			}}>
			<Button
				variant="contained"
				color="info"
				fullWidth
				onClick={handleReturnToSite}
				sx={{ mt: 2, mb: 2 }}>
				בחזרה לאתר
			</Button>
			<Typography variant="h5" gutterBottom>
				חיפוש משתמשים
			</Typography>
			<TextField
				label="פלאפון"
				name="phone"
				value={filters.phone}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>
			<TextField
				label="גיל"
				name="age"
				value={filters.age}
				onChange={handleChange}
				fullWidth
				margin="normal"
			/>

			<FormControl fullWidth margin="normal">
				<InputLabel>מגדר</InputLabel>
				<Select name="gender" value={filters.gender} onChange={handleChange}>
					<MenuItem value={0}>זכר</MenuItem>
					<MenuItem value={1}>נקבה</MenuItem>
				</Select>
			</FormControl>

			<FormControl fullWidth margin="normal">
				<InputLabel>מגזר</InputLabel>
				<Select name="stance" value={filters.stance} onChange={handleChange}>
					{[1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((value) => (
						<MenuItem key={value} value={value}>
							{i18next.t(`Stance_0.${value}`)}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<Typography variant="h6" gutterBottom>
				מה מחפש?
			</Typography>

			<Typography gutterBottom>טווח גילאים</Typography>
			<Slider
				value={[filters.from_age, filters.to_age]}
				onChange={(event, newValue) =>
					setFilters({ ...filters, from_age: newValue[0], to_age: newValue[1] })
				}
				valueLabelDisplay="auto"
				min={18}
				max={100}
			/>

			<Typography gutterBottom>טווח גבהים</Typography>
			<Slider
				value={[filters.from_height, filters.to_height]}
				onChange={(event, newValue) =>
					setFilters({ ...filters, from_height: newValue[0], to_height: newValue[1] })
				}
				valueLabelDisplay="auto"
				min={140}
				max={220}
			/>
			<FormControlLabel
				control={
					<Checkbox name="hasChildren" checked={filters.hasChildren} onChange={handleChange} />
				}
				label="יש ילדים?"
			/>

			{loading ? (
				<CircularProgress sx={{ display: "block", mx: "auto", my: 2 }} />
			) : (
				<Button
					variant="contained"
					color="primary"
					disabled={!filters.phone || !filters.age || !filters.stance}
					fullWidth
					onClick={handleSubmit}
					sx={{ mt: 2 }}>
					שליחה
				</Button>
			)}

			{optionalFriends.length > 0 && (
				<Box sx={{ mt: 2 }}>
					<Typography variant="h6">תוצאות:</Typography>
					{optionalFriends.map((url, index) => (
						<Typography
							key={index}
							component="a"
							href={url}
							target="_blank"
							sx={{ display: "block", color: "blue", textDecoration: "none", mt: 1 }}>
							{url}
						</Typography>
					))}
				</Box>
			)}
		</Box>
	);
};

export default UserSearchForm;
