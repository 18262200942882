import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import i18next from "i18next";

import {
	getChatReceived,
	getChatReceivedUser,
	getHasMoreChats,
	getIsChatReceivedLoaded,
	getIsNewChat,
	getLoadChatsNumber,
} from "redux/chatReceived/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { activeUserId, cancelActiveUser } from "redux/chat/actions";
import ChatItem from "./ChatItem";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import ChatImage from "assets/images/chat_image.svg";
import { getUserProfile } from "redux/profile/selectors";
import {
	addChatReceivedUser,
	updateLoadMore,
	updateReadMassleter,
} from "redux/chatReceived/actions";
import { readChatMessage } from "redux/chatMessages/actions";
import { getLoggedInUserUID } from "redux/auth/selectors";
import { getChatSent, getChatSentUser } from "redux/chatSent/selectors";
import { getPopups } from "redux/popups/selectors";
import localStorage from "services/localStorage";

import chatReceived from "services/chatReceived";
import { useState } from "react";
import { Button } from "reactstrap";
import { Box, CircularProgress } from "@mui/material";
import chatTabs from "consts/chatTabs";
import chatSent from "services/chatSent";
import { addChaSentUser, updateLoadMoreSent } from "redux/chatSent/actions";
import chatReceivedFiltered from "services/chatReceivedFiltered";
import {
	addChatReceivedFilteredUser,
	updateLoadMoreReceivedFiltered,
} from "redux/chatReceivedFiltered/actions";
import { getChatReceivedFiltered } from "redux/chatReceivedFiltered/selectors";
import unreadNotificationService from "services/unreadNotification.service";
import notificationUnreadTypes from "consts/notificationUnreadTypes";

export default ({
	activeTab,
	chatReceivedUsers,
	loadChatsNumber,
	showHasMoreChatsBtn,
	chatsUnread = {},
	...rest
}) => {
	const [countBroadcastUnread, setCountBroadcastUnread] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const chatUsers = useSelector(getChatReceived);
	const chatUsersSent = useSelector(getChatReceivedFiltered);
	const chatUsersReceivedFiltered = useSelector(getChatSent);
	const loginFromManager = localStorage.get("loginFrommanager");
	const dispatch = useDispatch();
	const active_userId = useSelector((state) => state.chat.active_userId);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId));
	const loggedInUserUID = useSelector(getLoggedInUserUID);
	const isNewChat = useSelector(getIsNewChat(active_userId));
	const chatReceivedUser = useSelector(getChatReceivedUser(active_userId));
	const chatSentUser = useSelector(getChatSentUser(active_userId));
	const activeChatUser = chatReceivedUser?.chatID ? chatReceivedUser : chatSentUser;
	const { currentUserProfile } = useSelector(getPopups);
	const user = activeUserProfile?.uid ? activeUserProfile : currentUserProfile;
	const location = useLocation();

	const isChatReceivedLoaded = useSelector(getIsChatReceivedLoaded);
	const noChatReceived = isChatReceivedLoaded && !chatReceivedUsers.length;
	const myCurrentUser = useSelector(getUserProfile);

	const firstUserProfile = useSelector(getUserPublicProfile(chatReceivedUsers[0]?.uid));
	const { showUserProfileSideBar, showBroadcastChanel, closePopup } = usePopups();

	const isActiveUserIdExistsInChatReceived = () => {
		return chatReceivedUsers.find((chat) => chat.uid === active_userId);
	};

	useEffect(() => {
		//If the chat is open - select the first user to be an active user
		if (
			!location.state?.isFromMassletterSendMessage &&
			active_userId &&
			!isActiveUserIdExistsInChatReceived()
		) {
			if (noChatReceived) return dispatch(cancelActiveUser());
			onClickUser({
				uid: chatReceivedUsers[0]?.uid,
				chatID: chatReceivedUsers[0]?.chatID,
				user: firstUserProfile,
			});
		}
	}, [activeTab]);

	useEffect(() => {
		if (!active_userId) return;
		const userChat = document.getElementsByClassName("user-chat");
		userChat && userChat[0]?.classList.add("user-chat-show");
		//!isMobile() && showUserProfileSideBar(user);

		if (!isMobile) {
			closePopup();
			showUserProfileSideBar(user);
		}
		if (!isNewChat && activeChatUser && !activeChatUser.isTemporary && !loginFromManager) {
			dispatch(readChatMessage(loggedInUserUID, user.uid, activeChatUser.chatID));
		}
	}, [active_userId]);

	const onClickUser = ({ uid, isMassletter, chatID, user }) => {
		isMassletter && dispatch(updateReadMassleter(uid));
		dispatch(activeUserId(uid, chatID));
		//!isMobile() && showUserProfileSideBar(user);
	};

	const handleLoadMoreChats = () => {
		setIsLoading(true);
		const CHATS_TO_LOAD = 30;

		setTimeout(async () => {
			switch (activeTab) {
				case chatTabs.Received:
					const dataReceived = await chatReceived.fetchChatReceived(
						loadChatsNumber + CHATS_TO_LOAD
					);

					dispatch(
						updateLoadMore({
							hasMoreChats: !(
								Object.keys(dataReceived || {})?.length - loadChatsNumber <
								CHATS_TO_LOAD
							),
							loadChatsNumber: loadChatsNumber + CHATS_TO_LOAD,
						})
					);

					Object.keys(dataReceived || {})
						.filter((uid) => !chatUsers[uid])
						.map((uid) => dispatch(addChatReceivedUser({ [uid]: dataReceived[uid] })));

					break;

				case chatTabs.Sent:
					const dataSent = await chatSent.fetchChatSent(loadChatsNumber + CHATS_TO_LOAD);

					dispatch(
						updateLoadMoreSent({
							hasMoreChats: !(
								Object.keys(dataSent || {})?.length - loadChatsNumber <
								CHATS_TO_LOAD
							),
							loadChatsNumber: loadChatsNumber + CHATS_TO_LOAD,
						})
					);

					Object.keys(dataSent || {})
						.filter((uid) => !chatUsersSent[uid])
						.map((uid) => dispatch(addChaSentUser({ [uid]: dataSent[uid] })));

					break;

				case chatTabs.ReceivedFiltered:
					const dataReceivedFiltered = await chatReceivedFiltered.fetchChatReceivedFiltered(
						loadChatsNumber + CHATS_TO_LOAD
					);

					dispatch(
						updateLoadMoreReceivedFiltered({
							hasMoreChats: !(
								Object.keys(dataReceivedFiltered || {})?.length - loadChatsNumber <
								CHATS_TO_LOAD
							),
							loadChatsNumber: loadChatsNumber + CHATS_TO_LOAD,
						})
					);

					Object.keys(dataReceivedFiltered || {})
						.filter((uid) => !chatUsersReceivedFiltered[uid])
						.map((uid) =>
							dispatch(addChatReceivedFilteredUser({ [uid]: dataReceivedFiltered[uid] }))
						);

					break;
			}

			setIsLoading(false);
		}, 0);
	};

	const handleClickBroadcastChat = () => {
		setCountBroadcastUnread(0);
		showBroadcastChanel();
	};
	const shlishLineChat = {
		// chatID: "-O0NYevL8dQnuPgC4gYR",
		// timeStamp: 1719472665233,
		// uid: "-Nmkwt_l7gATAJmnruQX",
	};

	useEffect(async () => {
		if (!myCurrentUser?.uid) return;
		await unreadNotificationService
			.getUserNotificationCountByType(myCurrentUser?.uid, notificationUnreadTypes.broadcast)
			.then((resCount) => !!resCount && setCountBroadcastUnread(resCount));
	}, [myCurrentUser?.uid]);

	return (
		<SimpleBar style={{ maxHeight: "100%" }} className="chat-message-list">
			<ul className="list-unstyled chat-list chat-user-list" id="chat-list">
				{activeTab === "Received" && (
					<ChatItem
						chat={shlishLineChat}
						onClickUser={handleClickBroadcastChat}
						countUnread={countBroadcastUnread}
						isAdminChat={true}
					/>
				)}
				{chatReceivedUsers.map((chat, key) => (
					<ChatItem
						key={key}
						chat={chat}
						onClickUser={(user) => onClickUser({ ...chat, user: user })}
						active_userId={active_userId}
						{...rest}
						activeTab={activeTab}
						countUnread={chatsUnread[chat?.uid]?.count || 0}
					/>
				))}
				<Box
					sx={{
						display: "flex",
						minHeight: "100px",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{!!chatReceivedUsers?.length && showHasMoreChatsBtn && (
						<>
							{isLoading ? (
								<CircularProgress size={30} className="text-primary" />
							) : (
								<Button
									className="loadMoreChatBtn"
									style={{
										fontWeight: 600,
										margin: "10px 0 calc(20px + env(safe-area-inset-bottom))",
										background: "#fff !important",
										borderColor: "#fff !important",
										color: "#2d5086",
										alignSelf: "start",
									}}
									onClick={handleLoadMoreChats}>
									{i18next.t("Chat.loadMore")}
								</Button>
							)}
						</>
					)}
				</Box>

				{/* {noChatReceived && (
					<div className="noMessages">
						<img src={ChatImage} />
						<div className="hiText">{i18next.t("Chat.Hi")}</div>
						<div className="noMessagesText">{i18next.t(`Chat.No Users_${gender}`)}</div>
					</div>
				)} */}
			</ul>
		</SimpleBar>
	);
};
