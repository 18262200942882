import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import usePopups from "hooks/usePopups";
import OneQuestionPage from "components/questions/OneQuestionPage";

export default () => {
	const { closePopup } = usePopups();

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog questionsUser">
			<ModalBody className="">
				<OneQuestionPage isPopup={true} />
			</ModalBody>
		</Modal>
	);
};
