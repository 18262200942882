import "assets/scss/blog/blogArticle.scss";
import BaseHelmet from "components/seo/BaseHelmet";
import blogService from "services/blogService";
import useNavigator from "hooks/useNavigator";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import i18next from "i18next";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import { getSearchUsers } from "redux/search/selectors";
import publicProfile from "services/publicProfile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import backDescPopup from "assets/images/backDescPopup.png";
import articlesStatus from "consts/articlesStatus";
import { getUserProfile } from "redux/profile/selectors";
import BlogBox from "./BlogBox";
import AppService from "services/app";
import { Share } from "@capacitor/share";
import AppPlatforms from "consts/AppPlatforms";
import BlogComments from "./BlogComments";
import BlogSurveys from "./BlogSurveys";
import { createYoutubeIframe } from "helpers/createYoutubeIframe";
import usePopups from "hooks/usePopups";

// import LandingFooter from "components/landing/LandingFooter";

export default function BlogArticle({}) {
	const userProfile = useSelector(getUserProfile) || {};
	const dispatch = useDispatch();
	const { navigateToPath } = useNavigator();
	const { urlName } = useParams();
	const { showContactFromFeedbackButtonPopup } = usePopups();

	const history = useHistory();
	const searchUsersSelectors = useSelector(getSearchUsers);

	const [articleData, setArticleData] = useState({});
	const [users, setUsers] = useState([]);
	const [publicUser, setPublicUser] = useState({});
	const [classIos, setClassIos] = useState(false);

	const closeBlog = () => {
		if (articleData?.categorySlug)
			return navigateToPath(`/blog/category/${articleData?.categorySlug}`);
		return navigateToPath(`/blog`);
	};

	const openTalentPopup = (uid) => {
		const currentUrl = history.location.pathname + history.location.search;
		history.push(currentUrl);
	};

	const searchByTag = (e, tagName) => {
		e.stopPropagation();
		navigateToPath(`/search?freeText=${tagName}&tagsKeys=&category=`);
	};

	const handleClickPrivacy = () => {
		return window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const handleClickContacts = () => {
		return showContactFromFeedbackButtonPopup();
	};

	const openLink = (link) => {
		if (link) {
			if (link?.includes(process.env.REACT_APP_DOMAIN)) {
				const path = link.replace(process.env.REACT_APP_DOMAIN, "");
				return navigateToPath(path);
			} else {
				return window.open(link, "_blank");
			}
		}
	};

	const shareArticle = async () => {
		if (!articleData?.slug) return;

		const affId = !!userProfile?.internalID ? `?affId=${userProfile?.internalID}` : "";
		const articleUrl = `${window.location.origin}/blog/${articleData?.slug}${affId}`;
		const platformApp = await AppService.getPlatform();
		const obj = {
			title: `מצאתי מאמר באתר "${process.env.REACT_APP_ENV_NAME}" שיכול לעניין אותך`,
			text: `${articleData?.title}`,
			url: articleUrl,
		};
		if (platformApp !== "web") {
			await Share.share(obj);
		} else {
			if (!!isMobile && navigator.share) {
				try {
					return await navigator.share(obj);
				} catch (error) {
					console.log(error);
				}
			} else {
				navigator.clipboard
					.writeText(articleUrl)
					.then(() => dispatch(openNotification(i18next.t("notificationActions.copyLink"))));
			}
		}
	};

	useEffect(async () => {
		if (!!articleData?.linkedAuthor) {
			publicProfile.fetchProfile(articleData?.linkedAuthor).then((pUser) => {
				if (!pUser || !pUser?.uid) return;
				setPublicUser(pUser);
			});
		}
	}, [articleData]);

	useEffect(async () => {
		if (!!searchUsersSelectors && !!searchUsersSelectors?.length) setUsers(searchUsersSelectors);
	}, [searchUsersSelectors]);

	useEffect(async () => {
		if (!urlName) return navigateToPath("/blog");

		await blogService.getArticleByUrlName(urlName).then((data) => {
			if (!data) return navigateToPath("/blog");
			const art = Object.values(data || {})[0];
			if (
				!art ||
				(window.location.search !== "?draft=t" && art?.status !== articlesStatus.published)
			)
				return navigateToPath("/blog");
			blogService.updateArticleViews(art.key);
			setArticleData(art);
		});
	}, []);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // Optional: smooth scrolling animation
		});
	}, []);

	useEffect(() => {
		AppService.getPlatform().then((platform) => {
			if (platform === AppPlatforms.IOS) {
				setClassIos(true);
			}
		});
	}, []);

	return (
		<>
			{!!articleData && (
				<>
					<BaseHelmet
						title={
							articleData?.title
								? `${process.env.REACT_APP_ENV_NAME} - ${articleData?.title}`
								: `${process.env.REACT_APP_ENV_NAME}`
						}
						description={articleData?.description ? articleData?.description : "מאמר"}></BaseHelmet>
					<Box
						className="wrapArticlePage"
						style={{ paddingBottom: !!isMobile && "84px", top: classIos ? "110px" : "80px" }}>
						<Box className="bigImg">
							{!!isMobile && articleData?.key && (
								<Box className="wrapShareAndBack">
									<Box className="wrapBackBlog">
										<img src={backDescPopup} onClick={closeBlog} />
									</Box>
									<Button onClick={shareArticle}>שיתוף</Button>
								</Box>
							)}

							{!articleData?.key ? (
								<Skeleton animation="pulse" className="skeleton imgSkeleton" />
							) : (
								<img
									src={
										articleData?.panoramicImage
											? articleData?.panoramicImage
											: articleData?.mainImage
									}
									alt="img"
								/>
							)}
						</Box>

						<Box className="articlePage">
							<Box className="articleWrapper">
								{!isMobile && (
									<Box className="wrapBackBlog">
										<img style={{ cursor: "pointer" }} src={backDescPopup} onClick={closeBlog} />
									</Box>
								)}
								<Box className="wrapHeaderContent">
									{!articleData?.key ? (
										<>
											<Skeleton animation="wave" className="skeleton titleSkeleton" />
											<Skeleton animation="wave" className="skeleton authorSkeleton" />
										</>
									) : (
										<>
											<h1 dangerouslySetInnerHTML={{ __html: articleData?.title }} />
											<h2
												className="descText"
												dangerouslySetInnerHTML={{ __html: articleData?.description }}
											/>
											<Box className="authorAndShare">
												<Box className="authorArticle">
													<span
														onClick={() => {
															if (!!publicUser?.uid) openTalentPopup(publicUser?.uid);
														}}
														className={`name ${
															!!publicUser?.uid && !!publicUser?.profileImage?.url ? "withImg" : ""
														}`}>
														<>
															{!!publicUser?.uid && !!publicUser?.profileImage?.url && (
																<Box className="wrapPublicUserImg">
																	<img src={publicUser?.profileImage?.url} />
																</Box>
															)}
														</>
														{articleData?.author}
													</span>

													<Box className="wrapViewNDate">
														<span>{moment(articleData?.publishDate).format("DD.MM.YYYY")}</span>
														{/* <span>{`${articleData?.views ? articleData?.views : 0} צפיות`}</span> */}
													</Box>
												</Box>
												{!isMobile && <Button onClick={shareArticle}>שיתוף</Button>}
											</Box>
										</>
									)}
								</Box>

								<Box className="wrapContentAndMoreArt">
									<Box className="wrapContentAndComments">
										<Box className="wrapContent">
											{!articleData?.key ? (
												<>
													<Skeleton
														animation="wave"
														className="skeleton mb-3"
														width={"100%"}
														height={"100px"}
													/>
													<Skeleton
														animation="wave"
														className="skeleton mb-3"
														width={"60%"}
														height={"100px"}
													/>
													<Skeleton
														animation="wave"
														className="skeleton mb-3"
														width={"100%"}
														height={"80px"}
													/>
													<Skeleton
														animation="wave"
														className="skeleton mb-3"
														width={"70%"}
														height={"200px"}
													/>
													<Skeleton
														animation="wave"
														className="skeleton mb-3"
														width={"80%"}
														height={"50px"}
													/>
												</>
											) : (
												<>
													{/* {!isMobile && <Box className="descText">{articleData?.description}</Box>} */}

													{!!articleData?.content && (
														<div
															className="contentText"
															dangerouslySetInnerHTML={{ __html: articleData?.content }}></div>
													)}
													{!!articleData?.navigateBtnLink && (
														<Box
															className="wrapNavigateBtn"
															style={{ background: articleData?.navigateBtnColor || "" }}>
															<button
																style={{ color: articleData?.navigateBtnColor || "" }}
																onClick={() => openLink(articleData?.navigateBtnLink)}>
																{articleData?.navigateBtnText || "למעבר לאתר"}
															</button>
														</Box>
													)}
													{!!articleData?.youtubeLink && (
														<Box
															className="wrapYtVideo"
															sx={{
																overflow: "hidden",
																borderRadius: "20px",
																height: "100%",
																width: "100%",
																maxHeight: "500px",
																minHeight: "500px",
																margin: "20px 0 0",
															}}
															dangerouslySetInnerHTML={{
																__html: createYoutubeIframe(articleData?.youtubeLink),
															}}
														/>
													)}
													{!!articleData?.tags && !!articleData?.tags?.length && (
														<Box className="wrapFooterTags">
															{articleData.tags.map((tag, index) => (
																<button
																	onClick={(e) => searchByTag(e, tag.name)}
																	className="tag"
																	key={`tag${index}`}>
																	{tag?.name}
																</button>
															))}
														</Box>
													)}
												</>
											)}
										</Box>
										{!!articleData?.key && <BlogSurveys articleData={articleData} />}
										{!!articleData?.key && !articleData?.withoutComments && (
											<BlogComments articleData={articleData} />
										)}
										{!!articleData?.key && (
											<span
												style={{
													fontSize: "12px",
													fontWeight: "400",
													padding: "0 30px",
													color: "#2D5086",
													textAlign: "center",
													position: "relative",
													top: "-20px",
													cursor: "pointer",
												}}>
												<span onClick={handleClickPrivacy}>{"לתקנון מדיניות פרטיות"}</span>
												<span onClick={handleClickContacts}>{" וליצירת קשר"}</span>
											</span>
										)}
									</Box>
									{!isMobile && (
										<Box className="moreArticle">
											<BlogBox
												withoutHeader={true}
												countBlogs={5}
												sortBy={articleData?.categoryKey || ""}
												blogKey={articleData?.key}
											/>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
						{/* <LandingFooter /> */}
					</Box>
				</>
			)}
		</>
	);
}
