import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { isNil } from "lodash";

import usePopups from "hooks/usePopups";
import { PopoverBody, Popover } from "reactstrap";
import SelectPlanBody from "./SelectPlanBody";
import { purchaseWithCardcom, purchaseWithGoogle } from "redux/subscription/actions";
import {
	getCardComUrl,
	getFetchCardcomApiStatus,
	getUserSubscriptionType,
} from "redux/subscription/selectors";
import IframeContainer from "./IframeContainer";
import { getUserProfile } from "redux/profile/selectors";
import { getGoogleApiStatus } from "redux/subscription/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { getPopups, isSale } from "redux/popups/selectors";
import tokenService from "services/token.service";
import subscriptionPlans from "services/subscriptionPlans";
import "assets/scss/popups/buyWithToken.scss";
import { Box } from "@mui/material";
import whiteLogo from "assets/images/newLogo.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import subscriptionTypesConst from "consts/subscription";

export default () => {
	const planKey = "307";
	const ref = useRef(false);

	const fetchCardcomApiStatus = useSelector(getFetchCardcomApiStatus);
	const cardComUrl = useSelector(getCardComUrl);
	const googleApiStatus = useSelector(getGoogleApiStatus);
	const subscriptionType = useSelector(getUserSubscriptionType);

	const [hasToken, setHasToken] = useState("");
	const [tokenData, setTokenData] = useState("");
	const [selectedPlan, setSelectedPlan] = useState();
	const [showIframe, setShowIframe] = useState(false);

	const loggedInUserProfile = useSelector(getUserProfile);
	const { cardcomClearing } = useSelector(getPopups);

	const dispatch = useDispatch();

	const { closePopup, showSubscriptionSuccessfullyPopup, showBuyWithTokenPopup } = usePopups();

	const onContinuePurchase = () => {
		if (hasToken) {
			closePopup();
			return showBuyWithTokenPopup({ tokenData, selectedPlan });
		}

		if (selectedPlan?.google_only) {
			dispatch(purchaseWithGoogle(selectedPlan));
		} else {
			dispatch(purchaseWithCardcom(selectedPlan, showSubscriptionSuccessfullyPopup));
			setShowIframe(true);
		}
	};
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const getHeaderText = () => {
		if (subscriptionType === subscriptionTypesConst.FREE) {
			return (
				<div style={{ fontSize: "22px", lineHeight: "1.3" }}>
					<b style={{ fontSize: "26px" }}>
						{`היי ${loggedInUserProfile.name}, איזה כיף ${
							loggedInUserProfile?.gender ? "שאת" : "שאתה"
						} איתנו 🥰`}
					</b>
					<br />
					{`מגיע לך מבצע מיוחד למצטרפים חדשים!`}
					<br />
					<b style={{ fontSize: "32px", marginTop: "30px", display: "block" }}>
						{`מנוי ל 4 חודשים`}
						<br />
						{`ב 49 ש"ח לחודש בלבד`}
					</b>
				</div>
			);
		} else if (subscriptionType === subscriptionTypesConst.PAID) {
			return (
				<div style={{ fontSize: "24px", lineHeight: "1.3" }}>
					<div style={{ fontSize: "26px" }}>
						{`היי ${loggedInUserProfile.name},  נראה שממש עוד רגע חברות הVIP שלך באתר ובאפליקציה עומדת להסתיים...`}
					</div>
					<b style={{ fontSize: "26px" }}>{`בדיוק בשביל זה אנחנו כאן - 😊`}</b>
					<br />
					{`מגיע לך הטבה אישית!`}
					<br />
					<b style={{ fontSize: "32px", marginTop: "30px", display: "block" }}>
						{`מנוי ל 4 חודשים`}
						<br />
						{`ב 49 ש"ח לחודש בלבד`}
					</b>
				</div>
			);
		} else {
			return <></>;
		}
	};
	const getBodyText = () => (
		<div className="termsPurch" style={{ fontSize: "17px", marginTop: "30px" }}>
			{`ההצעה בתוקף לזמן מוגבל בלבד ובכפוף`}
			<a className="underLine" onClick={openInNewTab}>
				{" לתקנון."}
			</a>
			<br />
			{`אל ${loggedInUserProfile.gender ? "תחכי, תני" : "תחכה, תן"} לזה פשוט לקרות ⬇️`}
			<br />
			{`אתר ההכרויות היחיד שמוכן להתחייב -`}
			<br />
			<b>{`מחתנים הכי הרבה יהודים בישראל!`}</b>
		</div>
	);

	useEffect(() => {
		if (!!cardcomClearing?.selectedPlan) {
			dispatch(
				purchaseWithCardcom(cardcomClearing.selectedPlan, showSubscriptionSuccessfullyPopup)
			);
			setShowIframe(true);
		}
	}, [cardcomClearing]);

	useEffect(async () => {
		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);

		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(async () => {
		const res = await subscriptionPlans.fetchSubscriptionPlanByKey(planKey);
		setSelectedPlan(res);
	}, []);

	// useEffect(() => {
	// 	if (!ref.current) {
	// 		ref.current = true;
	// 	} else {
	// 		if (googleApiStatus === ApiRequestStatus.SUCCESSFULLY) {
	// 			closePopup();
	// 		}
	// 	}
	// }, [googleApiStatus]);

	return (
		[subscriptionTypesConst.FREE, subscriptionTypesConst.PAID].includes(subscriptionType) && (
			<Modal
				isOpen={true}
				toggle={closePopup}
				className={showIframe ? "subscriptionDialog" : "simpleDialog buyWithToken"}>
				{!showIframe ? (
					<>
						{googleApiStatus === ApiRequestStatus.PENDING && <Loader />}
						<div className="user-chat-nav">
							<Button
								color="none"
								type="button"
								onClick={closePopup}
								className="nav-btn"
								id="user-profile-hide">
								<ArrowBackIosNewIcon style={{ color: "#fff", transform: "rotate(180deg)" }} />
							</Button>
						</div>
						<Box className="colorHeader dealColorHeader">
							<img src={whiteLogo} alt="" height={"50px"} />
						</Box>

						<ModalHeader style={{ marginTop: "20px" }}>{getHeaderText()}</ModalHeader>
						<ModalBody style={{ flex: "1", padding: "1.5rem" }}>{getBodyText()}</ModalBody>

						<ModalFooter style={{ display: "flex", flexDirection: "column", marginBottom: "56px" }}>
							<Button
								outline
								size="sm"
								color="primary"
								style={{ minWidth: "fit-content", padding: "8px 20px" }}
								onClick={onContinuePurchase}
								className="btn-rounded approvePurchesBtn"
								disabled={isNil(googleApiStatus !== ApiRequestStatus.PENDING)}>
								{`${loggedInUserProfile.gender ? "רכשי" : "רכוש"} עכשיו את המנוי במבצע!`}
							</Button>
						</ModalFooter>
					</>
				) : (
					<ModalBody>
						<IframeContainer
							apiStatus={fetchCardcomApiStatus}
							url={cardComUrl}
							onBack={() => setShowIframe(false)}
						/>
					</ModalBody>
				)}
			</Modal>
		)
	);
};
