import "assets/scss/questions/questionsBox.scss";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import arrowBlueQues from "assets/images/questions/arrowBlueQues.png";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { getPercentUser } from "redux/questions/selectors";
import StartEndPage from "./StartEndPage";
import useNavigator from "hooks/useNavigator";
import UserQuickQuestionPage from "./UserQuickQuestionPage";
import questionsMatchService from "services/questionsMatchService";
import { getUserProfile } from "redux/profile/selectors";
import { isEmpty } from "lodash";
import ClearIcon from "@mui/icons-material/Clear";

export default function OneQuestionPage({ isPopup = false }) {
	const { closePopup } = usePopups();
	const { navigateToAccountPage, navigateToAccountQuestions } = useNavigator();

	const percentQuestionsUser = useSelector(getPercentUser);
	const { uid } = useSelector(getUserProfile);
	const [page, setPage] = useState("loading");
	const [percent, setPercent] = useState(percentQuestionsUser ?? 0);
	const [isEnd, setIsEnd] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [userAnswers, setUserAnswers] = useState({});
	const [questions, setQuestions] = useState([]);

	const pages = {
		loading: "loading",
		startEnd: "startEnd",
		questions: "questions",
	};

	const handleClick = (toSkip = false) => {
		return isEnd ? navigateToAccountQuestions(toSkip) : setPage(pages.questions);
	};

	const renderPage = () => {
		switch (page) {
			case pages.loading:
				return <LoadingPage closePopup={isPopup ? closePopup : navigateToAccountPage} />;
			case pages.startEnd:
				return <StartEndPage handleClick={handleClick} isEnd={isEnd} userAnswers={userAnswers} />;
			case pages.questions:
				return (
					<UserQuickQuestionPage
						userAnswersList={userAnswers}
						questions={questions}
						closePopup={isPopup ? closePopup : navigateToAccountPage}
					/>
				);
		}
	};

	useEffect(() => {
		if (percent === 0) setPage(pages.startEnd);
		if (percent !== 0 && percent !== 100 && page !== pages.questions) setPage(pages.questions);
		if (percent === 100) {
			setPage(pages.startEnd);
			setIsEnd(true);
		}
	}, [percent]);

	useEffect(() => {
		if (!uid) return;

		const subscription = questionsMatchService.listenToUserQuestions$(uid).subscribe((res) => {
			if (!res) setIsChange(true);
			if (res) setUserAnswers(res);
		});

		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	useEffect(async () => {
		if (questions?.length) return;
		await questionsMatchService.getQuestions().then((res) => {
			if (res.data?.success) {
				const resQuestions = (res.data?.questions || [])
					.filter((ques) => {
						return !userAnswers[ques?.key];
					})
					.sort((a, b) => a?.order - b?.order);

				setQuestions(resQuestions);
			}
		});
	}, []);
	useEffect(async () => {
		if (!isChange && questions?.length && !isEmpty(userAnswers)) {
			setIsChange(true);
			const resQuestions = questions
				.filter((ques) => {
					return !userAnswers[ques?.key];
				})
				.sort((a, b) => a?.order - b?.order);

			setQuestions(resQuestions);
		}
	}, [questions, userAnswers]);

	useEffect(() => {
		if (percent !== percentQuestionsUser) setPercent(percentQuestionsUser);
	}, [percentQuestionsUser]);

	return <Box className="account-questions-box">{renderPage()}</Box>;
}

function LoadingPage({ closePopup }) {
	return (
		<Box className="loading">
			<Box className="arrowBlueQues" onClick={closePopup}>
				<ClearIcon alt="arrowBlueQues" style={{ color: "#2d5086" }} />
			</Box>

			<CircularProgress size={40} style={{ color: "#2d5086" }} />
		</Box>
	);
}
