import { Button } from "reactstrap";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";

import sendBl from "assets/images/icons/chatB.svg";
import usePopups from "hooks/usePopups";
import { getUserProfile } from "redux/profile/selectors";
import { addLocalChatSent, openChatSentFromMassletter } from "redux/chatSent/actions";
import useNavigator from "hooks/useNavigator";
import { activeUserId } from "redux/chat/actions";
import isMobile from "helpers/isMobile";
import PopupsTypes from "consts/popupsTypes";
import { getPopupUnderPopupType } from "redux/popups/selectors";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";
import { useCanUserSendMsgByAge } from "hooks/useCanUserSendMsgByAge";

export default ({ user }) => {
	const popupUnderPopupType = useSelector(getPopupUnderPopupType);
	const { gender, approved } = useSelector(getUserProfile);
	const userQuotaStatus = useSelector(getUserQuotaStatus);
	const {
		showSendMessagePopup,
		showNotApprovedMessagePopup,
		showUserProfileSideBar,
		_closePopupUnderPopup,
		showQuotaPopup,
		showCanSendMsgAgePopup,
	} = usePopups();
	const navigator = useNavigator();
	const dispatch = useDispatch();
	const canSendMessage = useCanUserSendMsgByAge(user);

	const onClickChat = async () => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();

		if (approved) {
			if (!canSendMessage) return showCanSendMsgAgePopup(user);

			if (!user.massletters_received_chatId) {
				showSendMessagePopup(user);
			} else {
				dispatch(addLocalChatSent(user.uid, user.massletters_received_chatId));
				dispatch(openChatSentFromMassletter(user.uid));
				dispatch(activeUserId(user.uid, user.massletters_received_chatId));
				!isMobile() && showUserProfileSideBar(user);
				popupUnderPopupType === PopupsTypes.UserProfileSideBar && _closePopupUnderPopup();
				setTimeout(() => {
					navigator.navigateToMassagesSent({ isFromMassletterSendMessage: true });
				}, 300);
			}
		} else {
			showNotApprovedMessagePopup(user);
		}
	};

	return (
		<Button onClick={onClickChat} outline className="btnText chatButton ">
			{i18next.t(`Search Card.Send_${gender}`)}
			<div>
				<img src={sendBl} />
			</div>
		</Button>
	);
};
