import "assets/scss/custom/pages/paySpeedDate.scss";
import { Box } from "@mui/system";
import FooterBtn from "components/speedDate/FooterBtn";
import CommonTemplate from "components/speedDate/CommonTemplate";
import credit from "assets/images/speedDate/credit.png";
import mail from "assets/images/speedDate/mail.png";
import useNavigator from "hooks/useNavigator";

export default function SuccessfullyPaySpeedDating() {
	const { navigateToAccountVideoDate } = useNavigator();

	const handlePaySuccessfullyBtn = async () => navigateToAccountVideoDate();

	const imagesText = [
		{
			img: mail,
			header: "",
			text: "קישור לכניסה עם אפשרות לעדכון ההגעה שלך יישלח אליך בדקות הקרובות במייל ואף יופיע לך באפליקציה בעמוד ההגדרות.",
		},
		{
			img: credit,
			header: "חשוב לשים לב!",
			text: "במידה ולא הגעת לאירוע (מבלי לעדכן ולבטל את ההשתתפות שלך בזמן שניתן לכך), תחוייב במלוא הסכום ולא תוכל לקבל החזר על כך.",
		},
	];
	return (
		<Box className={`registerSpeedDateWrapper successfullyPaySpeedDateWrapper`}>
			<CommonTemplate bluePage={true} wideImg={true} withBlueImg={true}>
				<Box className="speedDateContentWrapper">
					<h3 className="text">{"הרישום נקלט בהצלחה!"}</h3>

					<Box className="text">{"יאללה, כבר מחכים שיתחיל האירוע!"}</Box>
					<Box className="imagesTextBox">
						{imagesText.map((item, index) => (
							<Box className="imagesTextItem" key={index}>
								<img src={item?.img} alt="image" />

								{item?.header && (
									<Box className="text" style={{ fontWeight: "bold" }}>
										{item.header}
									</Box>
								)}
								{item?.text && <Box className="text">{item.text}</Box>}
							</Box>
						))}
					</Box>
					<FooterBtn handleClick={handlePaySuccessfullyBtn} text={"הבנתי"} />
				</Box>
			</CommonTemplate>
		</Box>
	);
}
