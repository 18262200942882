import * as types from "./actionTypes";
import httpsCallable from "../../services/httpsCallable";

const getPercentQuestion = async () => {
	const result = await httpsCallable.call("getPercentQuestion");
	if (result?.data?.success) return result.data?.percent;
	return 0;
};

export const getPercent = () => {
	return async (dispatch, getState) => {
		const percent = (await getPercentQuestion()) || 0;
		dispatch({
			type: types.QUESTIONS_PERCENT,
			percent,
		});
	};
};
