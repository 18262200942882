import "firebase/database";
import httpsCallable from "./httpsCallable";
import firebase from "firebase/app";
import { Observable } from "rxjs";

class UserProgressService {
	async checkUpdateUserProgressValues() {
		const result = await httpsCallable.call("checkUpdateUserProgressValues");
		return result;
	}
}

export default new UserProgressService();
