export default (user) => {
	if (!user) return 0;
	const { progressAccount = 0, progressImages = 0, progressQuestions = 0 } = user;

	const percentValue = {
		progressAccount: 20,
		progressImages: 28,
		progressQuestions: 22,
		hasCompleteRegistration: 30,
	};

	const getUserCompleteRegister = () => {
		if (!!user?.hasCompleteRegistration) return 30;
		else return 0;
	};

	const totalProgress = Math.round(
		(progressAccount / 100) * percentValue.progressAccount +
			(progressImages / 100) * percentValue.progressImages +
			(progressQuestions / 100) * percentValue.progressQuestions +
			getUserCompleteRegister()
	);

	return totalProgress;
};
