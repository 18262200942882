import { useState } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";

import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";

export default ({ apiStatus, url, onBack }) => {
	const [iframeLoaded, setIframeLoaded] = useState(false);
	if (apiStatus === ApiRequestStatus.INIT) return null;

	const handleIframeLoaded = () => {
		setIframeLoaded(true);
	};
	return (
		<>
			{apiStatus === ApiRequestStatus.FAILED && (
				<div className="subscriptionLoader">
					<div
						className="innerMsg"
						style={{
							position: "absolute",
							right: 0,
							textAlign: "center",
							width: "100%",
							top: "50px",
						}}>
						<b>{i18next.t(`Subscription.Fetch Cardcom URL.FAILED`)}</b>
					</div>
				</div>
			)}
			<Button
				className={`backBtn ri-arrow-right-s-line ${
					apiStatus === ApiRequestStatus.SUCCESSFULLY ? "backBtnWhite" : ""
				}`}
				style={{ top: "43px" }}
				onClick={onBack}
			/>
			{apiStatus !== ApiRequestStatus.FAILED && !iframeLoaded && (
				<div
					className="subscriptionLoader"
					style={{
						position: "absolute",
						right: 0,
						textAlign: "center",
						width: "100%",
						top: "50px",
					}}>
					<div className="innerMsg">
						<b>{i18next.t(`Subscription.Fetch Cardcom URL.PENDING`)}</b>
					</div>
					<Loader />
				</div>
			)}
			{apiStatus === ApiRequestStatus.SUCCESSFULLY && (
				<iframe
					allowpaymentrequest="true"
					allow="payments"
					onLoad={handleIframeLoaded}
					src={url}></iframe>
			)}
		</>
	);
};
