import "firebase/database";
import httpsCallable from "./httpsCallable";

class AiService {
	async getSharedContactFriends(uid) {
		const result = await httpsCallable.call("getSharedContactFriends", {
			uid,
		});
		return result;
	}
	async getAboutMeAi() {
		const result = await httpsCallable.call("getAboutMeAi");
		return result;
	}
	async getMatchBetweenUsersAi(uidPartner) {
		const result = await httpsCallable.call("getMatchBetweenUsersAi", { uidPartner });
		return result;
	}
}

export default new AiService();
