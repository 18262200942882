import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useEffect, useState } from "react";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import speedDatingService from "services/speedDatingService";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import tokenService from "services/token.service";
import usePopups from "hooks/usePopups";

export default function AddPayment({ speedData = {}, userData }) {
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(null);
	const { navigateToPayVideoPage } = useNavigator();

	const { gender } = useSelector(getUserProfile);
	const { showBuyWithTokenPopup } = usePopups();
	const [hasToken, setHasToken] = useState(false);
	const [tokenData, setTokenData] = useState(null);

	const getPayment = async () => {
		if (!speedData?.key) return;

		if (hasToken) {
			setLoading(false);
			return showBuyWithTokenPopup({
				tokenData,
				speedData: { ...speedData, price: userData?.price },
			});
		}

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) {
			navigateToPayVideoPage({ urlName: speedData.urlName, iframeUrlLink: res.data?.url });
		}
		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		getPayment();
	};

	useEffect(() => {
		console.log("gender", gender);

		//setPrice(1);
		if (gender === 1) {
			setType(speedData?.paymentTypeFemale);
		} else if (gender == 0) {
			setType(speedData?.paymentTypeMale);
		}
	}, [speedData, gender]);

	useEffect(async () => {
		window.scrollTo(0, 0);

		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);
		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);
	return (
		<>
			{type && type === "PAID" && (
				<>
					<Box>
						<h3 className="title">{`עוד רגע ואתם שם..`}</h3>
					</Box>

					<Box className="text">
						<Box>
							{`כדי להבטיח שכולם יגיעו ויקחו חלק פעיל, האירוע יהיה בתשלום בסך של ${
								userData?.price ?? ""
							} ש"ח 🤗`}
							<Box>
								<u>
									<b>{`כמובן, אם לא יימצאו לכם התאמות, לא תחויבו כלל.`}</b>
								</u>
							</Box>
						</Box>
					</Box>
					<Box className="footer">
						<FooterBtn
							handleClick={handleClick}
							text={i18next.t("למעבר לתשלום")}
							loading={loading}
						/>
					</Box>
				</>
			)}
			{type && type === "TOKEN" && (
				<>
					<Box>
						<h3 className="title">{`עוד רגע ואתם שם..`}</h3>
					</Box>

					<Box className="text">
						<Box>
							{`האירוע אומנם ללא תשלום 🤗 אך, על מנת להבטיח שכולם יגיעו ויקחו חלק פעיל, האירוע מתקיים עם דמי רצינות בהזנת פרטי אשראי, כך שבמקרה של אי הגעה תחויבו ב${
								userData?.price ?? ""
							}  ש"ח. `}
							<Box>
								<u>
									<b>{`אם תגיעו למפגשים, לא תחויבו כלל! `}</b>
								</u>
							</Box>
						</Box>
					</Box>
					<Box className="footer">
						<FooterBtn
							handleClick={handleClick}
							text={i18next.t("למעבר לתשלום")}
							loading={loading}
						/>
					</Box>
				</>
			)}
		</>
	);
}
