import usePopups from "hooks/usePopups";
import bell from "assets/images/newSubscription/bell.svg";

export default ({ userProfile, className = "", text = "", marginBottom = "" }) => {
	const { showSubscriptionPopup } = usePopups();

	return (
		<div
			className={`blue-box-subscription ${className}`}
			style={{ marginBottom: marginBottom ?? null }}
			onClick={showSubscriptionPopup}>
			<div className="right">
				<span className="text">{text}</span>
				<button onClick={showSubscriptionPopup} className="btn2">
					{userProfile?.gender ? "שדרגי" : "שדרג"} למנוי שליש+
				</button>
			</div>
			<img src={bell} alt="Notification" />
		</div>
	);
};
