import { getAgeByDob } from "./date";

function userPassLocationFilter(userLocation, locationsFilters, distance) {
	if (!userLocation?.lat || !userLocation?.lng) return false;
	const toRad = (value) => (value * Math.PI) / 180;

	const R = 6371; // Earth's radius in km
	const lat1 = toRad(userLocation.lat);
	const lon1 = toRad(userLocation.lng);
	const lat2 = toRad(locationsFilters.lat);
	const lon2 = toRad(locationsFilters.lng);

	const dLat = lat2 - lat1;
	const dLon = lon2 - lon1;

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distanceBetween = R * c;

	return distanceBetween <= parseFloat(distance); //if is bigger than do not allow to show
}

export default (user, filters, hasSubscription = false) => {
	if (!!filters && user) {
		const userAge = Number(user?.age || getAgeByDob(user?.dob));
		if (!!filters?.fromAge && userAge < Number(filters.fromAge)) return false;
		if (!!filters?.toAge && userAge > Number(filters.toAge)) return false;

		if (!!filters?.maritalStatus && !filters?.maritalStatus.includes(user?.maritalStatus))
			return false;
		if (!!filters?.stance && !filters?.stance.includes(user?.stance)) return false;
		if (!!filters?.gender && !filters?.gender.includes(user?.gender)) return false;
		if (!!filters?.manualRating && !filters?.manualRating.includes(user?.manualRating || 3))
			return false;
		if (!!filters?.hasChildren && !filters?.hasChildren.includes(user?.hasChildren)) return false;
		if (!!filters?.withSubscription && !filters?.withSubscription.includes(!!hasSubscription))
			return false;
		if (
			!!filters?.cityLoc?.lat &&
			!!filters?.cityLoc?.lng &&
			(!!filters?.distance || filters?.distance == "0") &&
			!userPassLocationFilter(user?.city_loc, filters.cityLoc, filters?.distance)
		)
			return false;
	}
	return true;
};
