import { Box } from "@mui/material";
import { useState, useEffect } from "react";

const TypingEffect = ({ text, speed = 100 }) => {
	const [displayedText, setDisplayedText] = useState("");
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (index < text.length) {
			const timeout = setTimeout(() => {
				setDisplayedText(text.slice(0, index + 1));
				setIndex(index + 1);
			}, speed);
			return () => clearTimeout(timeout);
		}
	}, [index, text, speed]);

	return <>{displayedText}</>;
	// return <Box dangerouslySetInnerHTML={{ __html: displayedText }} />;
};

export default TypingEffect;
