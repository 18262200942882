import "assets/scss/questions/questionsBox.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import anonymousCall from "services/anonymousCall";
import questionsMatchService from "services/questionsMatchService";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import heartQuestion from "assets/images/questions/heartQuestion.svg";
import editImg from "assets/images/questions/edit.png";
import arrowDown from "assets/images/questions/arrowDown.png";
import QuestionDrawer from "./questions/QuestionDrawer";
import getQuestionFilterByUser from "helpers/getQuestionFilterByUser";

export default function UserMatchQuestions({ colleagueId, colleague }) {
	const userProfile = useSelector(getUserProfile);
	const [questions, setQuestions] = useState([]);
	const [userAnswers, setUserAnswers] = useState({});
	const [countQuestions, setCountQuestions] = useState(15);
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [drawerData, setDrawerData] = useState(null);

	const getPlaceholderImg = (gender) => (gender ? placeholderWomanRound : placeholderManRound);
	const myPictureUri =
		userProfile.pictures?.[userProfile.mainPictureName]?.url ||
		getPlaceholderImg(!!userProfile?.gender);
	const colleaguePictureUri =
		colleague.pictures?.[colleague.mainPictureName]?.url || getPlaceholderImg(!!colleague?.gender);

	const handleClickQuestion = (question, answerKey = null) => {
		setDrawerData({ answerKey, question, myPictureUri });
		setDrawerOpen(true);
	};

	const getCanShowAns = (filters = null) => {
		if (!filters) return true;
		return getQuestionFilterByUser(userProfile, filters);
	};

	const toggleDrawer = (event, open) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	const getMyAnswer = (question = "") => {
		if (!userAnswers[question?.key] || userAnswers[question?.key]?.isSkip) {
			return (
				<button className="ansBtn" onClick={() => handleClickQuestion(question)}>{`${
					userProfile?.gender ? "עני " : "ענה"
				} על השאלה`}</button>
			);
		}

		return (
			<div className="editSpan">
				<span>{question.answers[userAnswers[question?.key]?.answerKey]?.text}</span>
				<img
					onClick={() => handleClickQuestion(question, userAnswers[question?.key]?.answerKey)}
					src={editImg}
					alt="edit"
					className="edit"
				/>
			</div>
		);
	};

	useEffect(async () => {
		if (!colleagueId) return;
		const res = await anonymousCall.getAnonymousUserQuestion(colleagueId);
		if (res?.success) {
			setQuestions(res?.questions || []);
		}
	}, []);

	useEffect(() => {
		if (!userProfile?.uid) return;

		const subscription = questionsMatchService
			.listenToUserQuestions$(userProfile?.uid)
			.subscribe((res) => {
				if (res) setUserAnswers(res);
			});

		return () => {
			subscription?.unsubscribe();
		};
	}, [userProfile]);

	return (
		<>
			{!!questions?.length && (
				<div className="userMatchQuestions">
					<h4 className="text-left text-primary font-size-16 ">{"שאלון התאמה"}</h4>
					<div className="wrapQuestions">
						{questions.slice(0, countQuestions).map((question, index) => (
							<div key={index} className="questionCard">
								<h5 className="questionText">{question?.question}</h5>
								<div className="answers">
									<div className="answer">
										<img src={colleaguePictureUri} alt="user" className="avatar" />
										<span>{question.answers[question.answerKey]?.text}</span>
									</div>
									{question?.answerKey &&
										question?.answerKey === userAnswers[question?.key]?.answerKey && (
											<img src={heartQuestion} alt="heartQuestion" className="heartQuestion" />
										)}
									{getCanShowAns(question?.filters) &&
										userProfile?.uid &&
										userProfile?.uid !== colleagueId && (
											<div className="answer">
												<img src={myPictureUri} alt="colleague" className="avatar" />
												{getMyAnswer(question)}
											</div>
										)}
								</div>
							</div>
						))}

						{questions?.length > 15 && countQuestions < questions?.length && (
							<div
								className="showMoreQuestion"
								onClick={() => {
									setCountQuestions(questions?.length);
								}}>
								<img src={arrowDown} alt="arrowDown" />
								<span>{"הצג עוד שאלות"}</span>
							</div>
						)}

						<QuestionDrawer
							isOpen={isDrawerOpen}
							toggleDrawer={toggleDrawer}
							userProfile={userProfile}
							myPictureUri={drawerData?.myPictureUri}
							question={drawerData?.question}
							answerKey={drawerData?.answerKey}
						/>
					</div>
				</div>
			)}
		</>
	);
}
