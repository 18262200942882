import firebase from "firebase/app";
import "firebase/database";
import { orderBy, toPairs } from "lodash";
import { Observable } from "rxjs";

class ChatMessagesService {
	BASE_REF = "chat_messages";

	buildMessage(id, message) {
		return { id, ...message, sent: true };
	}

	async fetchChatMessages(chatID) {
		if (!chatID) return this.buildMessages({});
		const messages =
			(await firebase.database().ref(this.BASE_REF).child(chatID).once("value")).val() || {};
		const sortedMessages = this.buildMessages(messages);
		return sortedMessages;
	}

	listenToChatMessages(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(chatID);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => {
					return subscriber.next(this.buildMessage(snapshot.key, snapshot.val()));
				},
				(error) => {
					return subscriber.error(error);
				}
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenNewMessages(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(chatID).endAt(null).limitToLast(1);
			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next(this.buildMessage(snapshot.key, snapshot.val())),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenNewMessagesReceived(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref("chat_received").child(chatID);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next(this.buildMessage(snapshot.key, snapshot.val())),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChatConversation(colleagueUid) {
		const uid = firebase.auth().currentUser?.uid;
		return new Observable((subscriber) => {
			const ref = firebase.database().ref("chat_received").child(uid).child(colleagueUid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next(this.buildMessage(snapshot.key, snapshot.val())),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	buildMessages(messages) {
		return orderBy(
			toPairs(messages).map(([key, message]) => this.buildMessage(key, message)),
			"timeStamp"
		);
	}

	sendMessage(chatID, message) {
		return firebase.database().ref(this.BASE_REF).child(chatID).push(message);
	}

	generateNewChatId() {
		return firebase.database().ref(this.BASE_REF).push().key;
	}
}

export default new ChatMessagesService();
