import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";
import { getAgeByDob } from "helpers/date";
import useShowJoinNowBtn from "./useShowJoinNowBtn";

export const useCanUserSendMsgByAge = (user) => {
	const { gender, dob } = useSelector(getUserProfile);
	const myAge = getAgeByDob(dob);
	const colleagueAge = user?.age;

	const isUserWithoutSubscription = useShowJoinNowBtn();
	if (gender || !myAge || !colleagueAge || !isUserWithoutSubscription) return true;

	const ageOption1 = Math.ceil(myAge * 0.65);
	const ageOption2 = myAge - 17;

	const maxAge = Math.max(ageOption1, ageOption2) + 1;
	const canSendMessage = colleagueAge >= maxAge;

	return canSendMessage;
};
