import "assets/scss/strip/strip.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import _, { set } from "lodash";
import ArrowUpImage from "assets/images/purchase_account_arrow_up.svg";
import usePopups from "hooks/usePopups";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import useShowJoinNowWithFinishSoon from "hooks/useShowJoinNowWithFinishSoon";
import { getIsPlatformIOS, getIsPlatformWeb } from "redux/init/selectors";
import { getIsTester, getUserProfile } from "redux/profile/selectors";
import {
	getSale,
	getSubscriptionData,
	getSubscriptionsIsSoonExpired,
} from "redux/subscription/selectors";
import { Button } from "reactstrap";
import { getAgeByDob } from "helpers/date";
import speedDateStatus from "consts/speedDateStatus";
import speedDatingService from "services/speedDatingService";
import useNavigator from "hooks/useNavigator";
import moment from "moment";
import promotion from "services/promotion";
import { getUserGeneral } from "redux/general/selectors";
import systemService from "services/system";
import { SwipeableDrawer, Box, Typography } from "@mui/material";
import DealDrawer from "./SubscriptionModal/dealDrawer";
import bell from "assets/images/newSubscription/bell.svg";
import winter25 from "assets/images/newSubscription/winter25.png";
import pesahStrip from "assets/images/newSubscription/pesahStrip.png";
import arrow from "assets/images/arrow-deal.png";
import promoteBnr from "assets/images/promoteBnr.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import LocalStorageService from "services/localStorage";
import userSpeedDateStatus from "consts/userSpeedDateStatus";
import roundBlue from "assets/images/speedDate/strip/roundBlue.svg";
import roundAzure from "assets/images/speedDate/strip/roundAzure.svg";
import rectangleAzure from "assets/images/speedDate/strip/rectangleAzure.svg";
import videoDatingWhite from "assets/images/speedDate/strip/videoDatingWhite.svg";
import videoDatingBlue from "assets/images/speedDate/strip/videoDatingBlue.svg";
import CloseIcon from "@mui/icons-material/Close";

export default () => {
	const navigate = useNavigator();
	const { pathname } = useLocation();
	const isPlatformWeb = useSelector(getIsPlatformWeb);
	const subscription = useSelector(getSubscriptionData);
	const subscriptionsIsSoonExpired = useSelector(getSubscriptionsIsSoonExpired);

	const currentUser = useSelector(getUserProfile) || {};
	const showJoinNowBtn = useShowJoinNowBtn();
	const showJoinNowWithFinishSoon = useShowJoinNowWithFinishSoon();
	const {
		showSubscriptionPopup,
		showSubscriptionDealPopup,
		showSaleSubscriptionPopup,
		showPromotionsSalePopup,
	} = usePopups();
	const sale = useSelector(getSale);
	/* const sale = {
		plans: {
			204: {
				active: false,
				app_store_id: "3month198",
				availableTo: "users",
				boId: "eAB7SyTaamRht6oTd77mwgRjk222",
				days_amount: "93",
				description_en: "Shlish Smart monthly subscription",
				description_he: "מבצע - 3 חודשים",
				google_play_id: "3month198",
				isSale: true,
				label_en: "monthly subscription",
				label_he: "מנוי שלושה חודשים לשליש גן עדן- Smart",
				long_description: {
					title: {
						en: "Continues every month for NIS 66 without a time limit, you can stop the route at any moment",
						he: 'ממשיך מידי חודש ב-66 ש"ח ללא הגבלת זמן ניתן להפסיק את המסלול בכל רגע',
					},
				},
				max_num_of_payments: 3,
				min_num_of_payments: 3,
				monthly_price: "66",
				name: "threeMonth",
				price: "198",
				renewable: true,
				renewal_days: "31",
				renewal_price: "66",
				sub_long: 3,
				sub_time: "month",
				suspends_amount: 1,
				withBit: false,
			},
		},
		label: "⬇ מבצע חורף לזמן מוגבל ⚡⬇",
		content: {
			subTitle1: "לקרוא את כל ההודעות שקבלת - לאפשר לאחרים לקרוא את ההודעות שלך! ",
			subTitle2:
				'מנוי מתמשך בחיוב חודשי בסך 66 ש"ח מידי חודש בלבד, מחיר בהתחייבות למינימום 3 חודשים, בתום תקופת ההתחייבות ממשיך מידי חודש ב66 ש"ח ללא הגבלת זמן, ניתן לבטל את החידוש בכל רגע בהגדרות החשבון שלך.',
			title: "היי {userName}, מבצע חורף לזמן מוגבל!",
		},
		switch: true,
		popup: true,
		versionPopup: 4,
	}; */
	const animatedName = "animate__bounceInDown";
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const userGeneral = useSelector(getUserGeneral);
	const [userTestPermission, setUserTestPermission] = useState(false);
	const [showDealPopup, setShowDealPopup] = useState(true);
	const isTester = useSelector(getIsTester);

	const [anchor, setAnchor] = useState(false);
	const [options, setOptions] = useState([]);

	const getUrl = () => {
		if (process.env.REACT_APP_ENV === "shlish") {
			if (isPlatformIOS) return "https://apple.co/48O1JQ6";
			return "https://bit.ly/42joSrq";
		} else {
			return "https://jsmart.app/invite/Ewq2";
		}
	};
	const goToTheStore = () => window.open(getUrl(), "_blank");

	const navigateToAccountVideoDate = () => {
		navigate.navigateToAccountVideoDate();
	};

	const navigateToSpeedDate = (url) => {
		navigate.navigateToPath(`/speedDating/${url}`);
	};

	const handleSaleSubscriptionPopup = () => {
		showSaleSubscriptionPopup(undefined, undefined, true, null);
	};

	const [current, setCurrent] = useState();
	const [speedDating, setSpeedDating] = useState();
	const [userSpeedDating, setUserSpeedDating] = useState();

	useEffect(() => {
		const fetchSpeedDate = async () => {
			if (_.isNil(currentUser.uid)) return;

			const data = await speedDatingService.getNextSpeedDate();
			if (!data) return;

			const sData = Object.values(data).find((ev) => {
				if (
					ev?.status !== speedDateStatus.start ||
					(currentUser?.gender && !ev?.bottomStripFemale) ||
					(!currentUser?.gender && !ev?.bottomStripMale) ||
					!ev?.endUsersRegister ||
					moment().isAfter(moment(ev?.endUsersRegister)) ||
					!speedDatingService.checkIfUserCanRegisterToEventByFilters(ev, currentUser)
				) {
					return false;
				}
				return true;
			});

			if (sData?.key) {
				const userInSpeedDate = await speedDatingService.getUserInSpeedDate(
					sData.key,
					currentUser.uid
				);
				if (userInSpeedDate) setUserSpeedDating(userInSpeedDate);
				setSpeedDating(sData);
			}
		};

		fetchSpeedDate();
	}, [currentUser.uid]);

	useEffect(() => {
		if (showJoinNowBtn && sale && sale.popup && sale.timeoutPopup) {
			const versionDealPopup = LocalStorageService.get("versionDealPopup");
			if (sale.versionPopup != versionDealPopup) {
				LocalStorageService.set("versionDealPopup", sale.versionPopup);

				setShowDealPopup(true);
				setTimeout(() => {
					if (sale.typePopup == 1) {
						handleSaleSubscriptionPopup();
					} else setAnchor(true);
				}, sale.timeoutPopup);
			}
		}
	}, [sale, showJoinNowBtn]);

	const getTime = (min) => {
		if (!min || min < 0) return false;
		if (min > 1) return `${min} דקות`;
		if (min == 1) return `דקה`;
	};

	function isWithin7DaysOfSubscription(subscription) {
		if (!subscription?.created_date) return true;

		const subscriptionDay = moment(subscription.created_date).get("date");
		const currentDay = moment().get("date");
		const daysInMonth = moment().daysInMonth();
		const maxAllowedDay = subscriptionDay + 7;

		return (
			(currentDay >= subscriptionDay && currentDay < maxAllowedDay) ||
			(maxAllowedDay > daysInMonth && currentDay <= maxAllowedDay % daysInMonth)
		);
	}

	useEffect(() => {
		let isMounted = true;

		const updateCurrent = async () => {
			if (_.isNil(currentUser?.uid)) return;

			let newOptions = [];

			// if (currentUser?.lastSpeedDateTime) {
			// 	const now = moment();
			// 	const lastSpeedDateTime = moment(currentUser.lastSpeedDateTime);
			// 	const thirtyMinutesBeforeSpeedDateTime = lastSpeedDateTime.clone().subtract(30, "minutes");

			// 	if (now.isBetween(thirtyMinutesBeforeSpeedDateTime, lastSpeedDateTime)) {
			// 		const minutesDifference = lastSpeedDateTime.diff(now, "minutes");
			// 		const minutesText = getTime(minutesDifference);
			// 		const speedData = await speedDatingService.getNextSpeedDate();

			// 		const { urlName = null } = Object.values(speedData || {})[0] || {};
			// 		if (!!minutesText && urlName) {
			// 			newOptions.push({
			// 				type: "speedDating",
			// 				header: `עוד ${minutesText} לפגישה הקרובה`,
			// 				func: () => navigateToSpeedDate(speedData?.urlName),
			// 				color: "secondary",
			// 				className: "videoDate-bg",
			// 			});
			// 		}
			// 	}
			// }

			if (
				speedDating?.bottomStripCompleteRegister &&
				userSpeedDating &&
				userSpeedDating?.status !== userSpeedDateStatus.confirmed
			) {
				newOptions.push({
					type: "speedDating",
					isComplete: true,
					header: "",
					func: navigateToAccountVideoDate,
					className: "isCompleteVideoDate",
				});
			} else if (speedDating) {
				const lastClosedTime = LocalStorageService.get("dontShowVideoForOneDay");

				if (
					speedDating?.stripType === "rectangleBigBlue" ||
					!lastClosedTime ||
					moment(parseInt(lastClosedTime)).isBefore(moment().subtract(1, "day"))
				) {
					newOptions.push({
						type: "speedDating",
						header: "חדש! וידאו דייט 🤍 להרשמה",
						func: navigateToAccountVideoDate,
						className: "",
					});
				}
			}

			if (!_.isNil(showJoinNowWithFinishSoon) && showJoinNowWithFinishSoon) {
				if (subscriptionsIsSoonExpired) {
					newOptions.push({
						type: "joinNow",
						header: `${currentUser.name}, מבצע במיוחד בשבילך!`,
						func: showSubscriptionDealPopup,
						color: "success",
						className: "red-bg",
					});
				}
			}
			if (showJoinNowBtn) {
				if (!!sale?.label) {
					newOptions.push({
						type: "sale",
						header: sale.label,
						func: handleSaleSubscriptionPopup,
						color: "success",
						className: "red-bg",
					});
					if (pathname == "/messages")
						newOptions.push({
							type: "saleBtn",
							header: sale.label,
							func: handleSaleSubscriptionPopup,
							color: "success",
							className: "red-bg",
						});
				} else {
					newOptions.push({
						type: "joinNowNew",
						header: i18next.t(`Strip.Join Now_${currentUser.gender}`),
						func: showSubscriptionPopup,
						color: "success",
						className: "",
					});
				}
			}

			if (
				!showJoinNowBtn &&
				(pathname == "/matches" || pathname == "/search") &&
				isWithin7DaysOfSubscription(subscription)
			) {
				const promotionResult = await promotion.getActivePromotionsSalePlan();
				if (!userGeneral?.isPromoteSale && !!promotionResult) {
					const title = `${currentUser.name}, ${currentUser.gender ? "קדמי" : "קדם"} את הכרטיס שלך`;
					newOptions.push({
						type: "promote",
						header: title,
						func: () => showPromotionsSalePopup(promotionResult),
						color: "success",
						className: "",
					});
				}
			}

			if (isMounted && newOptions.length > 0) {
				setOptions(newOptions);
				const randomChoice = newOptions[Math.floor(Math.random() * newOptions.length)];
				// if (!current) speed date dont work
				setCurrent(randomChoice);
			}
		};

		updateCurrent();

		return () => {
			isMounted = false;
		};
	}, [showJoinNowWithFinishSoon, isPlatformWeb, subscription, currentUser?.uid, speedDating]);

	useEffect(() => {
		const fetchTestSiteStatus = async () => {
			const testSite = await systemService.fetchSiteInTest();

			let havePermission = false;
			if ((testSite && isTester) || !testSite) {
				havePermission = true;
			}
			setUserTestPermission(havePermission);
		};

		fetchTestSiteStatus();
	}, []);

	return (
		<>
			{current && pathname !== "/account" && (
				<>
					{current.type === "joinNow" && (
						<Button
							className={`bottomStrip ${current?.className}`}
							onClick={current.func}
							color={current.color}>
							<img src={ArrowUpImage} />
							<div
								className={`animate__animated ${animatedName}`}
								style={{ minWidth: "fit-content" }}>
								{current.header}
							</div>
							<img src={ArrowUpImage} />
						</Button>
					)}

					{current.type === "sale" && (
						// <div className="sale-button-wrap" style={{ alignItems: "center" }}>
						// 	<div className="sale-button" onClick={current.func}>
						// 		{current.header}
						// 		<img className="" src={arrow} alt=" sale" />
						// 	</div>
						// </div>
						<img
							className="strip sale-strip"
							src={pesahStrip}
							alt="pesahStrip sale"
							onClick={current.func}
						/>
					)}
					{current.type === "saleBtn" && (
						<div className="sale-new-button-wrap" style={{ alignItems: "center" }}>
							<div className="sale-button" onClick={current.func}>
								{current.header}
								<img className="" src={arrow} alt=" sale" />
							</div>
						</div>
					)}
					{current.type === "promote" && (
						<div className="sale-button-wrap" style={{ alignItems: "center" }}>
							<img
								onClick={current.func}
								style={{ width: "50%" }}
								src={promoteBnr}
								alt=" promote"
							/>
						</div>
					)}

					{current.type === "joinNowNew" && (
						<div className="blue-box-subscription strip" onClick={current.func}>
							<div className="right">
								<span className="text">
									רוצה להגדיל את כמות הפניות {currentUser?.gender ? "שאת מקבלת" : "שאתה מקבל"} ?
								</span>
								<button onClick={current.func} className="btn2">
									{current.header}
								</button>
							</div>
							<img src={bell} alt="Notification" />
						</div>
					)}

					{current.type === "speedDating" && speedDating && (
						<>
							{current?.isComplete ? (
								<VideoDatingBanner
									speedDating={speedDating}
									handleClick={current.func}
									isComplete={true}
									addClassName={current.className}
									gender={currentUser?.gender}
								/>
							) : (
								<SpeedDatingStrip current={current} speedDating={speedDating} />
							)}
						</>
					)}
				</>
			)}

			{false && (
				<SwipeableDrawer
					classes={{
						paper: "drawer",
					}}
					transitionDuration={{ enter: 1000, exit: 300 }}
					anchor={"bottom"}
					open={anchor}
					onClose={() => setAnchor(false)}
					onOpen={() => setAnchor(true)}>
					<DealDrawer />
				</SwipeableDrawer>
			)}
		</>
	);
};

function SpeedDatingStrip({ speedDating = {}, current = {} }) {
	const [isHidden, setIsHidden] = useState(false);

	const dontShowKey = "dontShowVideoForOneDay";
	const stripTypes = {
		roundBlue: "roundBlue",
		roundAzure: "roundAzure",
		rectangleSmallBlue: "rectangleSmallBlue",
		rectangleBigBlue: "rectangleBigBlue",
	};
	const images = {
		roundBlue: roundBlue,
		roundAzure: roundAzure,
		rectangleSmallBlue: rectangleAzure,
		rectangleBigBlue: "",
	};

	useEffect(() => {
		const lastClosedTime = LocalStorageService.get(dontShowKey);

		if (lastClosedTime && moment(parseInt(lastClosedTime)).isAfter(moment().subtract(1, "day"))) {
			setIsHidden(true);
		}
	}, []);

	const handleClickClose = () => {
		LocalStorageService.set(dontShowKey, moment().valueOf());
		setIsHidden(true);
	};

	const renderImage = (stripType, bottom) => (
		<div className="videoDate-strip" style={{ bottom }}>
			<CloseIcon className={`closeIcon ${stripType}`} onClick={handleClickClose} />
			<img
				onClick={current.func}
				src={images[stripType]} // Use dynamic image based on stripType
				alt={stripType}
			/>
		</div>
	);

	const getStrip = () => {
		if (isHidden) return null;

		switch (speedDating?.stripType) {
			case stripTypes.roundBlue:
				return renderImage(stripTypes.roundBlue, "calc(75px + env(safe-area-inset-bottom))");
			case stripTypes.roundAzure:
				return renderImage(stripTypes.roundAzure, "calc(75px + env(safe-area-inset-bottom))");
			case stripTypes.rectangleSmallBlue:
				return renderImage(
					stripTypes.rectangleSmallBlue,
					"calc(85px + env(safe-area-inset-bottom))"
				);
			case stripTypes.rectangleBigBlue:
				return <VideoDatingBanner speedDating={speedDating} handleClick={current.func} />;
			default:
				return null;
		}
	};

	return <>{getStrip()}</>;
}

const VideoDatingBanner = ({
	speedDating,
	isComplete = false,
	handleClick = () => {},
	addClassName = "",
	gender = false,
}) => {
	const [timeLeft, setTimeLeft] = useState({});
	const calcTime = () => {
		const now = moment();
		const eventTime = moment(speedDating?.eventDate);
		const duration = moment.duration(eventTime.diff(now));

		setTimeLeft({
			minutes: duration.minutes(),
			hours: duration.hours(),
			days: duration.days(),
		});
	};

	useEffect(() => {
		calcTime();
		const interval = setInterval(() => {
			calcTime();
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box className={`video-dating-banner-strip ${addClassName ?? ""}`} onClick={handleClick}>
			<Box className="image-text-box">
				<Box className="text-box">
					{!isComplete ? (
						<>
							<Typography className="title" variant="h6">
								{"6 דייטים"}
							</Typography>
							<Typography className="title" variant="h6">
								{"בערב אחד"}
							</Typography>
							<Button className="online-button" variant="contained">
								אונליין!
							</Button>
						</>
					) : (
						<>
							<Typography className="title" variant="h6">
								{`${gender ? "לחצי" : "לחץ"} להשלמת הרכישה >>`}
							</Typography>
							<Button className="online-button" variant="contained">
								הזדמנות אחרונה
							</Button>
						</>
					)}
				</Box>
				<Box className="img-box">
					<img src={!isComplete ? videoDatingWhite : videoDatingBlue} alt="video dating" />
				</Box>
			</Box>
			<Box className="time-boxes">
				<Box className="time-box">
					<Typography variant="h6">{timeLeft.minutes}</Typography>
					<Typography variant="body2">דקות</Typography>
				</Box>
				<Box className="time-box">
					<Typography variant="h6">{timeLeft.hours}</Typography>
					<Typography variant="body2">שעות</Typography>
				</Box>
				<Box className="time-box">
					<Typography variant="h6">{timeLeft.days}</Typography>
					<Typography variant="body2">ימים</Typography>
				</Box>
			</Box>
		</Box>
	);
};
