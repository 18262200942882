import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import usePopups from "hooks/usePopups";
import LikesTabs from "consts/likesTabs";
import ViewsTabs from "consts/viewsTabs";
import isMobile from "helpers/isMobile";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default ({
	to,
	currentPath,
	className = "",
	children,
	openMyProfile = false,
	openQuestions = false,
	isLogout = false,
}) => {
	const {
		_closePopupUnderPopup,
		showUserCardPopup,
		showUserProfileSideBar,
		showLogoutPopup,
		showUserQuestionsPopup,
	} = usePopups();
	const currentUser = useSelector(getUserProfile) || {};
	const _to =
		to === `/${LikesTabs.LikedMeTab}` ? "like" : to === `/${ViewsTabs.ViewedMeTab}` ? "view" : to;

	const handleOpenMyProfile = () => {
		!isMobile() ? showUserCardPopup(currentUser) : showUserProfileSideBar(currentUser);
	};

	const handleClick = (e) => {
		if (isLogout) {
			e.preventDefault();
			return showLogoutPopup();
		}

		// if (openQuestions) return showUserQuestionsPopup();
		if (openMyProfile) return handleOpenMyProfile();
		return _closePopupUnderPopup();
	};
	return (
		<NavItem active={currentPath.indexOf(_to) !== -1} className={className}>
			<NavLink tag={Link} to={to} onClick={handleClick}>
				{children}
			</NavLink>
		</NavItem>
	);
};
