import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";

class ChatMetaService {
	BASE_REF = "chat_meta";

	async fetchChatMeta(uid) {
		return (await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val() || {};
	}

	listenChatMeta(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChatMetaAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);
			// .orderByChild("timeStamp")
			// .startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenChatMetaRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}

	async updateRead(colleagueUid) {
		const result = await httpsCallable.call("resetChatRecievedUnread", {
			colleagueUid,
		});
		return result;
	}

	clearTypingOnDisconect(colleagueUid, chatUid) {
		const ref = firebase
			.database()
			.ref(this.BASE_REF)
			.child(colleagueUid)
			.child(chatUid)
			.child("typing");
		ref.onDisconnect().set(null);
	}

	updateTyping(colleagueUid, chatUid, typing) {
		firebase.database().ref(this.BASE_REF).child(colleagueUid).child(chatUid).update({
			typing: typing,
		});
	}

	clearUnviewedNewMessages(uid) {
		if (uid) {
			return firebase.database().ref(this.BASE_REF).child(uid).update({
				newMessages: null,
			});
		}
	}

	listenChatReceivedUnread() {
		const uid = firebase.auth().currentUser.uid;
		return new Observable((subscriber) => {
			const ref = firebase.database().ref("chat_received_unread").child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	listenChatReceivedFilteredUnread() {
		const uid = firebase.auth().currentUser.uid;
		return new Observable((subscriber) => {
			const ref = firebase.database().ref("chat_received_filterd_unread").child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
}

export default new ChatMetaService();
