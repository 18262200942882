import produce from "immer";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";

const initialState = {
	percent: 0,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.QUESTIONS_PERCENT:
			return { percent: action.percent };

		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
