import { Box } from "@mui/material";
import VideoRegister from "./VideoRegister";
import EmptySpeedDate from "./EmptySpeedDate";
import ConfirmParticipation from "./ConfirmParticipation";
import userSpeedDateStatus from "consts/userSpeedDateStatus";
import NotFoundMatch from "./NotFoundMatch";
import AddPayment from "./AddPayment";
import moment from "moment";
import ResultsVideo from "./ResultsVideo";
import UpdateResults from "./UpdateResults";
import speedDateStatus from "consts/speedDateStatus";

export default function AccountVideoByStatus({
	userData = {},
	speedData = {},
	noSpeedData = false,
}) {
	const registrationEndMoment = moment(speedData?.endUsersRegister);
	const isAfterRegistrationEnd =
		speedData?.endUsersRegister && moment().isAfter(registrationEndMoment);
	const isBeforeRegistrationEnd =
		speedData?.endUsersRegister && !moment().isAfter(registrationEndMoment);

	const isUserBeforeEvent =
		userData?.status !== userSpeedDateStatus.confirmed &&
		userData?.status !== userSpeedDateStatus.start &&
		isBeforeRegistrationEnd;
	const isUserAwaitingPayment =
		userData?.status === userSpeedDateStatus.start && !!userData?.price && isAfterRegistrationEnd;

	const isUserNotRegistered = !userData?.uid;
	const isUserCanceled = userData?.status === userSpeedDateStatus.canceled;
	const isUserNotMatched = userData?.status === userSpeedDateStatus.notMatchDate;
	const isSpeedDateCompleted = speedData?.status === speedDateStatus.completed && !!userData?.uid;
	const isPaymentRequired =
		userData?.uid && userData?.status === userSpeedDateStatus.start && !!userData?.price;
	const isUpdateResultsAvailable =
		speedData?.status === speedDateStatus.matched &&
		moment().isAfter(moment(speedData?.eventDate).add(2, "hour"));

	const getComponent = () => {
		if (noSpeedData) return <EmptySpeedDate />;
		if (isUserNotRegistered || isUserCanceled || isUserBeforeEvent || isUserAwaitingPayment)
			return <VideoRegister speedData={speedData} userData={userData} />;
		if (isUserNotMatched) return <NotFoundMatch speedData={speedData} userData={userData} />;
		if (isSpeedDateCompleted) return <ResultsVideo speedData={speedData} userData={userData} />;
		if (isPaymentRequired) return <AddPayment speedData={speedData} userData={userData} />;
		if (isUpdateResultsAvailable)
			return <UpdateResults speedData={speedData} userData={userData} />;
		return <ConfirmParticipation speedData={speedData} userData={userData} />;
	};

	return <Box className="wrapInnerVideoBox">{getComponent()}</Box>;
}
